import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import c3 from 'c3'
import 'c3/c3.css'
import {useTranslation} from 'react-i18next'
import {format} from 'd3'

const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px 32px;
  border: 1px solid #d3d3d3;

  @media (max-width: 1023px) {
    border: none;
  }

  @media (max-width: 767px) {
    padding: 16px 24px;
  }
`

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: default;
`

const LegendGender = styled.p`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0;

  &:first-child {
    margin-right: 15px;
  }
`

const LegendMale = styled(LegendGender)`
  @media (max-width: 767px) {
    color: #41719C;
  }
`

const LegendFemale = styled(LegendGender)`
  @media (max-width: 767px) {
    color: #FF5050;
  }
`

const LegendPoint = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;

  @media (max-width: 767px) {
    display: none;
  }
`

const LegendPointMale = styled(LegendPoint)`
  background-color: #41719C;
`

const LegendPointFemale = styled(LegendPoint)`
  background-color: #FF5050;
`

const ChartsWrapper = styled.div`
  display: flex;
  margin: 50px 0;

  @media (max-width: 1023px) {
    margin: 40px 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
  }
`

type Props = {
    data: (string | number)[][],
    children: React.ReactNode,
    permission: boolean
}

enum chartName {
    '#chartMale' = '#41719C',
    '#chartFemale' = '#FF5050',
}

const chartValueFormat = (value: any, permission: boolean) => {
    if (!value) return ''
    if (permission) {
        return format('.1f')(value)
    }
    return value
}

const formatter = (t: any) => {
    const atr = {
        women: 'female',
        men: 'male'
    }
    return (value: 'women' | 'men') => t(`charts.${atr[value]}`)
}

const parserDataToChart = (data: (string | number)[], bindTo: keyof typeof chartName, size: number, permission: boolean, formatTool: any) => {
    if (data[1] === undefined) return null
    return c3.generate({
        bindto: bindTo,
        data: {
            columns: [
                data as any
            ],
            type: 'gauge'
        },
        size: {
            width: size
        },
        color: {
            pattern: [chartName[bindTo]]
        },
        gauge: {
            label: {
                format: (value: number) => {
                    return chartValueFormat(value, permission)
                },
                show: false
            }
        },
        tooltip: {
            format: {
                name: formatTool,
                value: (value: any) => chartValueFormat(value, permission),
            },
            contents: (data, defaultTitleFormat, defaultValueFormat, color) => {
                const value = defaultValueFormat(data[0].value)
                const title = formatTool(data[0].id as ('women' | 'men'))
                const colorSquare = color(data[0].id)
                const squareStyles = {
                    width: '9px',
                    height: '9px',
                    border: '2px solid white',
                    backgroundColor: colorSquare
                }
                const tooltipChart = document.createElement('div')
                const squareElement = document.createElement('div')
                Object.entries(squareStyles).forEach(([first, second]) => {
                    // @ts-ignore
                    squareElement.style[first] = second
                })

                const valueElement = document.createElement('div')
                valueElement.innerText = `${title}: ${value}`
                tooltipChart.classList.add('tooltip-chart2')
                tooltipChart.insertAdjacentElement('afterbegin', squareElement)
                tooltipChart.insertAdjacentElement('beforeend', valueElement)

                return tooltipChart.outerHTML
            }
        },
        legend: {
            show: false
        }
    })
}

export function PieChart2({data, permission, children}: Props) {
    const [, setMale] = useState<any>()
    const [, setFemale] = useState<any>()
    const {t} = useTranslation()
    const scrollRef = useRef() as MutableRefObject<any>
    const formatTool = formatter(t)

    useEffect(() => {
        setFemale((item: any) => {
            if (!data[0][1]) {
                return null
            }
            if (item) {
                item.load({
                    columns: [data[0]],
                })
                return item
            }
            return parserDataToChart(data[0], '#chartFemale', 300, permission, formatTool)
        })
        setMale((item: any) => {
            if (!data[1][1]) {
                return null
            }
            if (item) {
                item.load({
                    columns: [data[1]],
                })
                return item
            }
            return parserDataToChart(data[1], '#chartMale', 300, permission, formatTool)

        })
    }, [data, permission])

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        }
    }, [scrollRef])

    return (
        <Wrapper>
            {
                <Legend>
                    {
                        data[0][1] ?
                            <LegendFemale>
                                <LegendPointFemale/> {t('charts.female')}
                            </LegendFemale> : null
                    }
                    {
                        data[1][1] ?
                            <LegendMale>
                                <LegendPointMale/> {t('charts.male')}
                            </LegendMale> : null
                    }
                </Legend>
            }

            <ChartsWrapper>
                {data[0][1] ? <div id="chartFemale"/> : null}
                {data[1][1] ? <div id="chartMale"/> : null}
            </ChartsWrapper>
            {
                React.Children.map(children, (childElement, index) => {
                    if (index === 0) { // @ts-ignore
                        return React.cloneElement(childElement, {ref: scrollRef})
                    }
                    return childElement
                })
            }
        </Wrapper>
    )
}
