import React, {useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ErrorWrapper} from '../common'
import {RadioButton, Button} from '../controls'
import {withToastService, withApiService} from '../wrapper'
import {ApiService, ToastService} from '../../services'
import {PublishedSurveyAnswerModel, PublishedSurveyModel, PublishedSurveyOptionModel} from '../../models/Survey.model'

const StyledRadio = styled(RadioButton)`
  margin: 22px 0 !important;
  @media (max-width: 767px) {
    margin: 16px 0 !important;
  }
`
const ButtonWrapper = styled.div`
  padding: 9px 0;
  margin-right: 24px;

  @media (max-width: 767px) {
    margin-right: 16px;
  }`

const Icon = styled.div`
  margin-right: 8px;
  background-image: url("../../SVGs/warning.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const StyledTextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  font-family: Sero Pro, serif;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  padding: 18px 24px;

  &:focus {
    border: 2px solid #EE7B5D;
    border-radius: 4px;
    outline: none;
  }

  &::-webkit-resizer {
    border-width: 16px;
    border-style: solid;
    border-radius: 0 0 4px 0;
    border-color: transparent #D7D7E4 #D7D7E4 transparent;
  }
`

const WrapErrorWithButton = styled.div`
  display: flex;
`

export type FormAnswer = {
    choice: string,
    ownAnswer: string
}

type FormPollProps = {
    data: PublishedSurveyModel,
    apiService: ApiService,
    toastService: ToastService,
    onAnswer: (answer: PublishedSurveyModel) => void
}

function FormPollComponent({data, apiService, toastService, onAnswer}: FormPollProps) {
    const {register, handleSubmit, errors, reset, getValues} = useForm()
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [isCustomActive, setIsCustomActive] = useState(false)

    const isOwnValid = (ownAnswer: string) => {
        const choice = getValues('choice')
        if (!choice) {
            return true
        }
        const isOwnAnswer = data.questions[0].options.find(e => e.optionId === +choice)?.isCustomAnswer
        if (!isOwnAnswer) {
            return true
        }
        return ownAnswer.length > 0
    }

    const onSubmit = (formData: FormAnswer) => {
        setLoading(true)
        setError(false)
        const {choice, ownAnswer} = formData
        const sendObj: PublishedSurveyAnswerModel = {
            surveyId: data.surveyId,
            questionId: data.questions[0]?.questionId,
            answer: [+choice]
        }
        const isOwnAnswer = data.questions[0].options.find(e => e.optionId === +choice)?.isCustomAnswer

        if (isOwnAnswer) {
            sendObj.answerText = ownAnswer
        }

        return apiService.postPoll(sendObj, i18n.language)
            .then((res) => {
                toastService.showDark(t('poll.result'))
                onAnswer(res.data)
                reset(formData)
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {data.questions[0]?.options.map((option: PublishedSurveyOptionModel) => (
                <React.Fragment key={option.optionId}>
                    <div onClick={() => {
                        const choice = getValues('choice')
                        if (!choice) {
                            setIsCustomActive(false)
                            return
                        }
                        const isOwnAnswer = data.questions[0].options.find(e => e.optionId === +choice)?.isCustomAnswer
                        setIsCustomActive(!!isOwnAnswer)
                    }}>
                        <StyledRadio
                            name={'choice'}
                            value={option.optionId}
                            label={(option as any)?.optionText?.[language] || ''}
                            register={register({required: true})}
                        />
                    </div>
                    {
                        option?.isCustomAnswer &&
                        <>
                            <StyledTextArea
                                name={'ownAnswer'}
                                placeholder={t('poll.customAnswer')}
                                readOnly={!isCustomActive}
                                ref={register({
                                    validate: isOwnValid
                                })}
                            />
                            {
                                errors.ownAnswer && <ErrorWrapper>{t('poll.errorInput')}</ErrorWrapper>
                            }
                        </>
                    }
                </React.Fragment>
            ))}
            <WrapErrorWithButton>
                <ButtonWrapper>
                    <Button label={t('poll.reply')} showSpinner={loading} disabled={loading}/>
                </ButtonWrapper>
                {errors.choice && <ErrorWrapper><Icon/>{t('poll.error')}</ErrorWrapper>}
                {error && <ErrorWrapper><Icon/>{t('poll.errorSave')}</ErrorWrapper>}
            </WrapErrorWithButton>
        </form>
    )
}

export const FormPoll = withToastService(withApiService(FormPollComponent))
