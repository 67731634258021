import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {ReactSVG} from 'react-svg'

import {LanguageSelect, LinksBar, LinksBarBurgerMenu} from './'
import {useComponentVisible} from '../../hooks'
import SearchLine from '../content/SearchLine'
import {changeIsImpaired} from '../../store/actions/actions'
import {StateModel} from '../../models'

const StyledHeader = styled.header`
  width: 100%;
  background-color: #427B89;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    display: none;
  }
`

const TopPart = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px 0;
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const LogosWrapper = styled.div`
  display: flex;
`

const GenderStatLogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;

  &:hover {
    color: black;
  }
`

const GenderStatLogo = styled.div`
  background: center / contain no-repeat url("../../SVGs/logo.svg");
  width: 46px;
  height: 46px;
`

const GenderStatLogoText = styled.div`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-left: 12px;
  padding-right: 24px;
  
  ${(props: { isImpaired: boolean }) =>
          (props.isImpaired &&
          css`width: 200px;
    `) || css`width: 100px;`};

  @media (max-width: 1023px) {
    color: #292C45;
    font-size: 14px;
    line-height: 15px;
    width: fit-content;
  }

  @media (max-width: 767px) {
    padding-right: 0;
  }
`

const BelstatLogoWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #FFFFFF;
  margin-left: 14px;
  outline: none;

  &:hover {
    color: black;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const BelstatLogo = styled.div`
  background: center / contain no-repeat url("../../SVGs/BelstatLogo.svg");
  width: 45px;
  height: 48px;
`

const GenderStatLogoBW = styled.img`
  width: 46px;
  height: 46px;
`

const BelstatLogoBW = styled.img`
  width: 45px;
  height: 48px;
`

const BelstatLogoText = styled.div`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-left: 12px;

  ${(props: { isImpaired: boolean }) =>
          (props.isImpaired &&
                  css`width: 510px;
    `) || css`width: 340px;`};

  @media (max-width: 1023px) {
    color: #292C45;
    font-size: 14px;
    line-height: 15px;
    width: fit-content;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  color: #FFFFFF;
`

const Impaired = styled.a`
  font-size: 16px;
  margin-right: 36px;
  line-height: 20px;
  cursor: pointer;
  color: #FFFFFF;
  text-decoration-line: none;

  &:hover {
    color: black;
  }
`

const BottomPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
`

const BottomPartContent = styled.div`
  width: 100%;
  max-width: 1160px;
  padding: 12px 0;
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const Header768px = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    padding: 12px 76px;
    background: #FFFFFF;
    z-index: 1000;
    width: calc(100% - 152px);
  }

  @media (max-width: 767px) {
    padding: 6px 32px 6px 26px;
    width: calc(100% - 58px);
  }
`

const Search = styled.div`
  padding: 0 35px;
  outline: none;
  cursor: pointer;
`

const Menu =
    styled.div`
      padding: 0;
      outline: none;
      cursor: pointer;
    `

const Icon = styled(ReactSVG)`
  width: 24px;
  height: 24px;

  &:hover path {
    fill: black;
  }
`

const BurgerMenuIcon = styled(ReactSVG)`
  width: 22px;
  height: 14px;

  &:hover path {
    fill: black;
  }
`

const WrapRightSide = styled.div`
  display: flex;
  align-items: center`

const MenuBlock = styled.div`
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(41, 44, 69, 0.12);
  width: 384px;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 100;
  @media (max-width: 767px) {
    width: 100vw;
    right: 0;
    left: 0;
  }
`

const HeaderBurgerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 31px 24px 24px;

`

const Lang = styled.div`
  display: flex;
`

const OptionLang = styled.div`
  cursor: pointer;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 4px 8px;

  &:hover {
    background: #F3F3F7;
    border-radius: 2px;
  }

  //background: #D7D7E4;
  //opacity: 0.3;
  //border-radius: 2px;
`

const IconClose = styled.div`
  margin-left: 7.33px;
  background-image: url("../../SVGs/close.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 14px;
  height: 14px;
  align-self: center;
`

const Background = styled.div`
  position: absolute;
  height: 100vh;
  width: 98vw;
  background: #292C45;
  left: 0;
  opacity: 0.5;
  top: 0
`

const HeaderWrapper = styled.div`
  @media (max-width: 1023px) {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
  }
`

function HeaderComponent() {
    const {t, i18n} = useTranslation()
    const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false)
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const dispatch = useDispatch()
    const languages = [
        {
            code: 'ru',
            name: 'РУС'
        },
        {
            code: 'be',
            name: 'БЕЛ'
        },
        {
            code: 'en',
            name: 'ENG'
        }
    ]

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language)
    }

    const changeLanguageMobile = (event: string) => {
        i18n.changeLanguage(event)
        setIsShowBurgerMenu(!isShowBurgerMenu)
    }

    const ComponentVisible = () => setIsComponentVisible(!isComponentVisible)

    const {isComponentVisible, setIsComponentVisible} = useComponentVisible(false)

    const handleClick = () => {
        dispatch(changeIsImpaired(true))
        localStorage.setItem('version', 'impaired')
        const avtor = document.getElementsByClassName('avtor') as any
        const elements = document.querySelector('.special-quit > button')  as any
        const audio = document.getElementsByClassName('special-audio') as any
        avtor[0].style.display = 'none'
        audio[0].style.display = 'none'
        elements.addEventListener('click', () => {
            dispatch(changeIsImpaired(false))
            localStorage.removeItem('version')
        })
    }

    return (
        <HeaderWrapper>
            <StyledHeader>
                <TopPart>
                    <LogosWrapper>
                        <GenderStatLogoWrapper to={'/home'}>
                            {!isImpaired && <GenderStatLogo/>}
                            {isImpaired && <GenderStatLogoBW src="../../SVGs/logoBW.svg" alt='gender statistics'/>}
                            <GenderStatLogoText isImpaired={isImpaired}>{t('header.app_name')}</GenderStatLogoText>
                        </GenderStatLogoWrapper>
                        <BelstatLogoWrapper href="https://www.belstat.gov.by/" target="_blank" rel="noreferrer">
                            {!isImpaired && <BelstatLogo/>}
                            {isImpaired && <BelstatLogoBW src="../../SVGs/belstatBW.svg"/>}
                            <BelstatLogoText isImpaired={isImpaired}>{t('header.belstat')}</BelstatLogoText>
                        </BelstatLogoWrapper>
                    </LogosWrapper>
                    <ActionsWrapper>
                        <Impaired  id="specialButton" onClick={handleClick}>{t('header.impaired')}</Impaired>
                        <LanguageSelect selected={i18n.language} options={languages} onSelected={changeLanguage}/>
                    </ActionsWrapper>
                </TopPart>
                <BottomPart>
                    <BottomPartContent>
                        <LinksBar isComponentVisible={isComponentVisible} setIsComponentVisible={ComponentVisible}/>
                    </BottomPartContent>
                </BottomPart>
            </StyledHeader>

            <Header768px>
                <LogosWrapper>
                    <GenderStatLogoWrapper to={'/home'}>
                        <GenderStatLogo/>
                        <GenderStatLogoText isImpaired={isImpaired}>{t('header.app_name')}</GenderStatLogoText>
                    </GenderStatLogoWrapper>
                    <BelstatLogoWrapper href="https://www.belstat.gov.by/" target="_blank" rel="noreferrer">
                        <BelstatLogo/>
                        <BelstatLogoText isImpaired={isImpaired}>{t('header.belstat')}</BelstatLogoText>
                    </BelstatLogoWrapper>
                </LogosWrapper>
                <WrapRightSide>
                    <Search onClick={() => setIsComponentVisible(!isComponentVisible)}>
                        {!isComponentVisible && <Icon src="../../SVGs/search.svg"/>}
                        {isComponentVisible && <IconClose/>}
                    </Search>
                    <Menu onClick={() => setIsShowBurgerMenu(!isShowBurgerMenu)}>
                        <BurgerMenuIcon src="../../SVGs/burgerMenu.svg"/>
                    </Menu>
                </WrapRightSide>
                {isShowBurgerMenu && <MenuBlock>
                    <HeaderBurgerMenu>
                        <Lang>
                            <OptionLang style={i18n.language === 'ru' ? {
                                'background': '#F3F3F7',
                                'borderRadius': '2px'
                            } : {}} onClick={() => changeLanguageMobile('ru')}>РУС</OptionLang>
                            <OptionLang style={i18n.language === 'be' ? {
                                'background': '#F3F3F7',
                                'borderRadius': '2px'
                            } : {}} onClick={() => changeLanguageMobile('be')}>БЕЛ</OptionLang>
                            <OptionLang style={i18n.language === 'en' ? {
                                'background': '#F3F3F7',
                                'borderRadius': '2px'
                            } : {}} onClick={() => changeLanguageMobile('en')}>ENG</OptionLang>

                        </Lang>
                        <IconClose onClick={() => setIsShowBurgerMenu(!isShowBurgerMenu)}/>
                    </HeaderBurgerMenu>
                    <LinksBarBurgerMenu closeMenu={() => setIsShowBurgerMenu(!isShowBurgerMenu)}/>
                </MenuBlock>}
                {isShowBurgerMenu && <Background onClick={(event) => {
                    event.preventDefault()
                    setIsShowBurgerMenu(!isShowBurgerMenu)
                }}/>}
                {isComponentVisible && <SearchLine closeSearch={ComponentVisible}/>}
            </Header768px>
        </HeaderWrapper>)
}

export const Header = withTranslation()(HeaderComponent)
