import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {useReactToPrint} from 'react-to-print'

import {
    TitleSubcategory,
    BlockDownland,
    LinksOptionsBar,
    Spinner,
    withApiService,
    SelectedOptions,
    BlockAnalysis,
    BlockDownland767px,
    PieChart1Wrapper,
    PieChart2Wrapper,
    MapWrapper, ChartWrapper, BarChart
} from '../components'
import PassportContainer from './passport.container'
import BlockDisaggregationContainer from './blockDisaggregation.container'
import {useData, useQuery} from '../hooks'
import {ApiService} from '../services'
import BlockDisaggregation767px from './blockDisaggregation767px'
import {ConclusionModel, IndicatorDataModel, StateModel} from '../models'
import {addIndicatorInitialData} from '../store/actions/actions'
import {TableContainer} from './table.container'
import LineChart from '../components/charts/lineChart/LineChart'
import AreaChart from '../components/charts/areaChart/AreaChart'

const WrapWithBackground = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 72px;
  padding-top: 5px;

  @media (max-width: 1023px) {
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    padding-top: 0;
  }
`
const Background = styled.div`
  background: #427B89;
  position: absolute;
  top: 0;
  height: 65px;
  width: 100%;
  z-index: -1;

  @media (max-width: 1023px) {
    display: none;
  }
`


const WrapMainPart = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  display: flex;

  @media (max-width: 1239px) {
    max-width: 980px;
  }

`


const BlockDownlandWidth320 = styled.div`
  display: none;

  @media (max-width: 767px) {
    background: #F6F6F9;
    display: block;
    padding: 32px 24px;
    margin-bottom: 64px;
  }
`

const WrapBlockDisaggregation = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const Button = styled.div`
  background: #FFFFFF;
  border: 2px solid rgba(215, 215, 228, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 12px 0;
  &:hover {
    background: rgba(215,215,228,0.3);
    opacity: 0.5;
  }
`

const IconDownload = styled.div`
  display: inline-block;
  height: 21px;
  width: 21px;
  background-image: url("../../SVGs/download.svg");
  background-position: center center;
  vertical-align: bottom;
  margin-right: 10px;
`

const ButtonDisaggregation = styled.button`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #3A3084;
    border-radius: 6px;
    font-family: Sero Pro, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 12px 36px;
    align-self: center;
    border: none;
    cursor: pointer;
    z-index: 20;

    &:hover {
      background: #312970;
    }

    &:focus {
      background: #3A3084;
      border: 2px solid #EE7B5D;
      box-sizing: border-box;
      border-radius: 6px;
      outline: none;
      padding: 10px 34px;
    }

    &:active {
      background: #29225E;
    }

    &:disabled {
      background: #D7D7E4;
      cursor: auto;
    }
  }
`

const BackgroundGray = styled.div`
  background: #292C45;
  opacity: 0.5;
  width: 98vw;
  height: 100vh;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
`

const Block767pxAnalysis = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const NoDataContainer = styled.div`
  max-width: 1160px;
  margin: 0 auto;

  @media (max-width: 1239px) {
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    margin: 0 64px;
  }

  @media (max-width: 767px) {
    margin: 0 24px;
  }
`

const NoLoadedRecalledTitle = styled.p`
  color: #EE7B5D;
`

const BackLink = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #427B89;
  opacity: 0.7;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }

`

type typeProps = {
    apiService: ApiService
}

function IndicatorContainer({apiService}: typeProps) {
    const {t, i18n} = useTranslation()
    const dispatch = useDispatch()
    const [isShowBurgerMenu, setIsShowBurgerMenu] = useState(false)
    const [isShowDisaggregation, setIsShowDisaggregation] = useState(false)
    const [isShowMap, setIsShowMap] = useState(false)
    const [conclusions, setConclusions] = useState(null)
    const history = useHistory().location.pathname.split('/')
    const indicatorId = history[2]

    const language = `lang_${i18n.language}`

    const showOrHideDisaggregation = () => setIsShowDisaggregation(!isShowDisaggregation)

    const showOrHideMenu = () => setIsShowBurgerMenu(!isShowBurgerMenu)

    const getIndicatorData =
        useCallback(
            () => apiService.getIndicatorData(indicatorId, i18n.language),
            [apiService, i18n.language, indicatorId]
        )


    const state3 = useData(getIndicatorData)
    const query = useQuery()
    const typeView = useSelector((state: StateModel) => state.typeOfView)

    const [nameCategory, setNameCategory] = useState('')

    useEffect(() => {
        setNameCategory(history[1])
    }, [history])

    const componentRef = useRef()
    const objectRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => (componentRef as any).current
    })

    const handlePdf = () => (objectRef as RefObject<any>)


    useEffect(() => {
        if (state3.data && (state3.data! as IndicatorDataModel)?.status === 'EXIST') {
            setIsShowMap(!!(state3?.data as any)?.structure?.dimensions?.find((item: any) => item.code === 'REGION'))
            dispatch(addIndicatorInitialData(state3.data))
        }
    }, [state3.data, dispatch])

    useEffect(() => {
        if (state3.data && (state3.data! as IndicatorDataModel)?.status === 'EXIST') {
            const processedConclusion = (state3.data as any)?.conclusions.reduce((obj: any, {
                    conclusionType,
                    conclusionText,
                    variables
                }: ConclusionModel) => {
                    obj[conclusionType] = (conclusionText as any)[language]
                        .split(/(\{\d+\})/)
                        .filter((e: string) => e)
                        .map((e: string) => {
                            if (/\{\d+\}/.test(e)) {
                                return {
                                    conclusionType,
                                    type: (variables as any)[e.slice(1, -1)]?.variableType || '',
                                    value: (variables as any)[e.slice(1, -1)]?.variableValue?.[language] || ''
                                }
                            } else {
                                return {
                                    type: 'TEXT',
                                    value: e
                                }
                            }
                        })
                    return obj
                }
                , {})
            setConclusions(processedConclusion as any)
        }
    }, [state3.data, language])



    const ChangeContainer =
        useCallback(
            () => {
                switch (typeView) {
                    case 'map':
                        return (<MapWrapper/>)
                    case 'lineChart':
                        return (
                            <ChartWrapper
                                drawChart={
                                    (dataJson: any) =>
                                        <LineChart dataJson={dataJson}/>
                                }
                            />
                        )
                    case 'plotWithAreas':
                        return (
                            <ChartWrapper
                                drawChart={
                                    (dataJson: any) =>
                                        <AreaChart dataJson={dataJson}/>
                                }
                            />
                        )
                    case 'pieChart1':
                        return (<PieChart1Wrapper/>)
                    case 'pieChart2':
                        return (<PieChart2Wrapper/>)
                    case 'columnChart':
                        return (
                            <ChartWrapper
                                yearAmount={5}
                                drawChart={
                                    (dataJson: any) =>
                                        <BarChart dataJson={dataJson}/>
                                }
                            />
                        )
                    case 'passport':
                        return (
                            <PassportContainer data={(state3.data! as IndicatorDataModel).attrs!}
                                               indicatorName={(state3.data as any).name[language]}/>
                        )
                    case 'table':
                        return (<TableContainer/>)
                    default:
                        return (
                            <ChartWrapper
                                drawChart={
                                    (dataJson: any) =>
                                        <LineChart dataJson={dataJson}/>
                                }
                            />
                        )
                }
            },
            [typeView]
        )

    const renderData = () => {
        switch ((state3.data! as IndicatorDataModel).status) {
            case 'MISSING': {
                return (
                    <NoDataContainer>
                        <BackLink to={`/${nameCategory}`}>
                            &#8592;{t(`${nameCategory}.title`)}</BackLink>
                        <NoLoadedRecalledTitle>{t('indicators.dataNoLoaded')}</NoLoadedRecalledTitle>
                        <p>{(state3.data! as IndicatorDataModel)?.attrs?.noDatasetText ? ((state3.data! as IndicatorDataModel)?.attrs?.noDatasetText as any)[language] : ''}</p>
                    </NoDataContainer>
                )
            }
            case 'RECALLED': {
                return (
                    <NoDataContainer>
                        <NoLoadedRecalledTitle>{t('indicators.dataRecalled')}</NoLoadedRecalledTitle>
                        <p>{((state3.data! as IndicatorDataModel)?.attrs?.noDatasetText as any)[language] || ''}</p>
                    </NoDataContainer>
                )
            }
            case 'EXIST': {
                return showIndicatorData()
            }
            default: {
                return ''
            }
        }
    }

    const showIndicatorData = () => {
        return (
            <div>
                <div ref={componentRef as any}>
                    <TitleSubcategory
                        data={state3.data}
                        units={
                            (state3.data! as IndicatorDataModel).attrs?.calcUnits ?
                                ((state3.data! as IndicatorDataModel).attrs?.calcUnits as any)[language] : ''
                        }
                    />
                    <LinksOptionsBar isShowMap={isShowMap}/>
                    <WrapWithBackground>
                        <Background/>
                        <WrapMainPart>
                            <WrapBlockDisaggregation>
                                <BlockDisaggregationContainer disabled={query.get('type') === 'passport'}/>
                                {query.get('type') !== 'passport' && conclusions &&
                                <BlockAnalysis conclusions={conclusions}/>}
                            </WrapBlockDisaggregation>
                            <div style={{width: '100%'}} ref={objectRef as any}>
                                <ChangeContainer/>
                                {query.get('type') !== 'passport' &&
                                <div style={{'marginTop': '16px'}}>
                                    <SelectedOptions/>
                                </div>}
                            </div>
                        </WrapMainPart>
                    </WrapWithBackground>
                </div>
                {query.get('type') !== 'passport' && conclusions &&
                <Block767pxAnalysis><BlockAnalysis conclusions={conclusions}/></Block767pxAnalysis>}
                {objectRef && <BlockDownland indicatorId={indicatorId} onPrint={handlePrint} handlePdf={handlePdf}
                                             fileName={query.get('type')} size={{
                    height: (objectRef! as RefObject<any>)?.current?.clientHeight,
                    width: (objectRef! as RefObject<any>)?.current?.clientWidth
                }}/>}
                <BlockDownlandWidth320><Button
                    onClick={() => showOrHideMenu()}><IconDownload/>{t('downloadDataBlock.downloadData')}
                </Button></BlockDownlandWidth320>
                <ButtonDisaggregation disabled={query.get('type') === 'passport'}
                                      onClick={() => showOrHideDisaggregation()}>
                    {t('disaggregationIndicators.showAllDisaggregation')}
                </ButtonDisaggregation>

                <BlockDisaggregation767px isShowDisaggregation={isShowDisaggregation}
                                          hideBlock={() => showOrHideDisaggregation()}
                />
                {isShowBurgerMenu && objectRef &&
                <BlockDownland767px handlePdf={handlePdf} indicatorId={indicatorId} hideMenu={() => showOrHideMenu()}
                                    fileName={query.get('type')}/>}
            </div>
        )
    }

    return (<div>
        {state3.loading && <Spinner/>}
        {state3.error && <p>{state3.error}</p>}
        {state3.data && renderData()}
        {isShowDisaggregation &&
        <BackgroundGray onClick={(event) => {
            event.preventDefault()
            showOrHideDisaggregation()
        }}/>}
    </div>)
}

export default withTranslation()(withApiService(IndicatorContainer))
