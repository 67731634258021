import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {StateModel} from '../models'
import {IndicatorTable, withChartService} from '../components'
import {ChartService} from '../services'

type Title = {
    key: string,
    name: string
}

type Props = {
    chartService: ChartService
}

function TableContainerComponent({chartService}: Props) {

    const [yearData, setYearData] = useState(null)
    const [titleDim, setTitleDim] = useState<Title[] | null>(null)

    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`

    const data = useSelector((state: StateModel) => state.indicatorActiveData)

    useEffect(() => {
        if (data) {
            setYearData(chartService.normalizeData(data))
            setTitleDim(chartService.getTitleForTable(data, language))
        }
    }, [data, language, chartService])

    return (
        <div style={{'width': '100%'}}>
            {yearData && titleDim && <IndicatorTable data={{yearData, titleDim}}/>}
        </div>
    )
}

export const TableContainer = withChartService(TableContainerComponent)
