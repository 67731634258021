import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'

import {LinkModel} from './'
import {useComponentVisible, useQuery} from '../../hooks'
import {changeTypeIndicator} from '../../store/actions/actions'
import {useDispatch} from 'react-redux'
import {ControlledRadioButton} from '../controls'

const LinkWrapper = styled.div`
  position: relative;
`

const LinkTitle = styled.div`
  background: #FFFFFF;
  border-radius: 23px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  flex: none;
  padding: 8px 0 8px 16px;
  margin: 0 12px 0 0;
  text-decoration: none;
  align-items: center;
  padding-right: calc(1.8em + 8px);
  background-image: url("../../SVGs/down-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: .7em auto, 100%;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px #D4D5DA solid;

  &:hover {
    border: 2px #A9ABB5 solid;
  }
`

const MoreLinks = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(95, 102, 160, 0.16);
  border-radius: 8px;
  width: 231px;
  padding: 18px;
`

const HeaderLink = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  display: block;
  text-decoration: none;
  cursor: pointer;
`

const Input = styled(ControlledRadioButton)`
  height: 20px;
  float: left;
`
const Label = styled.label`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  position: relative;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`

type LinkSelectProps = {
    title: string,
    links: LinkModel[],
    style: any,
    showMap?: boolean
}

function LinkSelectIndicatorComponent(props: LinkSelectProps) {
    const query = useQuery()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)

    const goTo = (path: string) => {
        setIsComponentVisible(false)
            dispatch(changeTypeIndicator(path))
    }

    const handleOpenChanged = () => setIsComponentVisible(!isComponentVisible)

    const {title, links, showMap} = props

    return (
        <LinkWrapper ref={ref}>
            <LinkTitle onClick={handleOpenChanged} style={props.style}>{title}</LinkTitle>
            {isComponentVisible && (
                <MoreLinks>
                    {links.map((child: LinkModel, index: number) => (
                        <React.Fragment  key={index} >
                            {showMap &&
                            <HeaderLink onClick={() => goTo(child.key!)}>
                                <Label>
                                    <Input key={child.key} name={child.key} value={child.key} onSelected={()=>{}}
                                           checked={child.key === query.get('type')}/>
                                    {t(`dataViewMenu.${child.key}`)}
                                </Label>
                            </HeaderLink>
                            }
                            {!showMap &&
                            <HeaderLink onClick={() => goTo(child.key!)}
                                        style={child.key !== 'map' ? {display: 'block'} : {display: 'none'}}>
                                <Label>
                                    <Input key={child.key} name={child.key} value={child.key} onSelected={()=>{}}
                                                           checked={child.key === query.get('type')}/>
                                    {t(`dataViewMenu.${child.key}`)}</Label>
                            </HeaderLink>}
                        </React.Fragment>))}
                </MoreLinks>

            )}
        </LinkWrapper>
    )
}

export const LinkSelectIndicator = withTranslation()(LinkSelectIndicatorComponent)
