import {useEffect, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'

const initialState = {
  data: null,
  loading: true,
  error: ''
}

export const useData = (request: any) => {
  const [dataState, setDataState] = useState(initialState)
  useEffect(() => {
    let cancelled = false
    setDataState(initialState)
    request()
      .then((data: AxiosResponse) => !cancelled ? setDataState({
        data: data.data,
        loading: false,
        error: ''
      }) : null)
      .catch((e: AxiosError) => !cancelled ? setDataState({
        data: null,
        loading: false,
        error: e.message
      }): null)

    return () => {cancelled = true}
  }, [request])

  return dataState
}
