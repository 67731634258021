import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

import {ApiService} from '../services'
import {FooterLine, SubmitComment, Spinner, withApiService} from '../components'
import SingleNews from '../components/content/SingleNews'
import HeaderSingleNews from '../components/content/HeaderSingleNews'
import {useData} from '../hooks'

type typeProps = {
    apiService: ApiService
}

const WrapMainPart = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  display: flex;
  @media (max-width: 1239px) {
    max-width: 980px;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

function SingleNewsContainer({apiService}: typeProps) {
    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const history = useHistory().location.pathname.split('/')
    const id = Number(history[2])

    const query = useCallback(() => apiService.getSingleNews(id, language), [id, language, apiService])
    const state = useData(query)

    const getDate = (date: string | Date) => {
        const momentDate = moment(date)
        if (momentDate.isValid()) {
            return momentDate.format('DD.MM.YYYY')
        }
        return ''
    }

    return (
        <div>
            {state.loading && <Spinner/>}
            {state.error && <p>{state.error}</p>}
            {state.data &&
            <Wrap>
                <HeaderSingleNews title={(state.data! as any).title[language]}
                                  date={getDate((state.data! as any).publicationDate)}/>
                <WrapMainPart>
                    <SingleNews data={state.data} language={language}/>
                </WrapMainPart>
                <FooterLine style={{'height': '3px'}}/>
                <SubmitComment/>
            </Wrap>}
        </div>)
}

export default withApiService(SingleNewsContainer)

