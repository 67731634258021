import React from 'react'
import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
`

const Hourglass = styled.div`
  display: inline-block;
  position: relative;
  left: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  
  &:after{
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 8px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${rotate} 1.2s infinite;
  }
`

export function SpinnerForButton(){
  return <Hourglass />
}
