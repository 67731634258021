import React, {ChangeEvent, useEffect, useMemo} from 'react'
import {useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import ChartMap from './MapChart'
import {YearsSelect} from '../common'
import {ChartSlider} from './ChartSlider'
import {IndicatorDataItemModel, StateModel} from '../../models'
import {withChartService} from '../wrapper'
import {ChartService} from '../../services'
import {MapChartDataItemModel, MapChartDataModel} from '../../models'
import {changeDatePosition} from '../../store/actions/actions'

const ChartSliderWrapper = styled.div`
  width: fit-content;
  @media (max-width: 767px) {
    display: none;
  }
`
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Chart = styled.div`
  min-height: 500px;
  @media (max-width: 1023px) {
    display: none;
  }
`
const Chart767px = styled.div`
  min-height: fit-content;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const YearSelectWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 24px;

  @media (min-width: 768px) {
    display: none;
  }`

const Line = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    height: 32px;
    width: 100%;
    background-color: #427B89;
  }
`

const Scrollable = styled.div`
  padding: 0 5px;
  width: 100%;
  
  @media (max-width: 1239px) {
    max-width: 600px
  }

  @media (max-width: 1023px) {
    max-width: none;
  }
  overflow-x: auto;
  overflow-y: hidden;

  //& {
  //  scrollbar-width: thin;
  //  scrollbar-color: #A8A8A8 #F1F1F1;
  //}

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }
`

type Props = {
    chartService: ChartService
}

function MapWrapperComponent({chartService}: Props) {

    const {t} = useTranslation()

    const gender = useMemo(() => ({
        male: {name: t('charts.male'), color: '#41719C'},
        female: {name: t('charts.female'), color: '#FF5050'},
        both: {name: t('charts.bothGender'), color: '#FFB650'}
    }), [t])


    const width = 474
    const height = 413

    const dispatch = useDispatch()
    const datePosition = useSelector((state: StateModel) => state.datePosition)
    const activeData = useSelector((state: StateModel) => state.indicatorActiveData)
    const [data, setData] = useState<MapChartDataModel | null>(null)

    const [dataForMapChart, setDataForMapChart] = useState<MapChartDataItemModel[] | null>(null)
    const [currentYearValue, setCurrentYearValue] = useState<number | null>(null)
    const [year, changeDate] = useState(0)
    const [selectedGender, setSelectedGender] = useState(gender.male)

    const [years, setYears] = useState<string[] | null>(null)
    const [dims, setDims] = useState<IndicatorDataItemModel[] | null>(null)

    useEffect(() => {
        if (activeData) {
            const newData = chartService.getChartDataForMap(activeData) as MapChartDataModel
            const years = Object.keys(newData)
            let curYear
            if (datePosition === -1) {
                curYear = (years as any)[years.length - 1]
                changeDate(curYear)
                dispatch(changeDatePosition(years.length - 1))
            } else {
                curYear = (years as any)[datePosition]
                changeDate(datePosition)
            }
            setData(newData)
            setYears(years as any)
            setCurrentYearValue(curYear)
            setDataForMapChart(newData[curYear])

            const label = Object.keys(activeData.dataset)[0] ? Object.keys(activeData.dataset)[0].replace(/^\d{4}:/, '') : ''

            if (/^F/.test(label)) {
                setSelectedGender(gender.female)
            }
            if (/^M/.test(label)) {
                setSelectedGender(gender.male)
            }
            if (/^T/.test(label)) {
                setSelectedGender(gender.both)
            }

            const regionDim = activeData.structure.dimensions.find(dim => dim.code === 'REGION')
            setDims(regionDim?.items || null)
        }
    }, [activeData, chartService, gender])

    const handleSelectedYear = (year: number) => {
        dispatch(changeDatePosition(years!.indexOf(year.toString())))
        setDataForMapChart(data![year])
        setCurrentYearValue(year)
        changeDate(years!.indexOf(year.toString()))
    }

    const onChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
        const index = +(event.target.value)
        dispatch(changeDatePosition(index))
        changeDate(index)
        // @ts-ignore
        setDataForMapChart(data![years![index]])
        // @ts-ignore
        setCurrentYearValue(years![index])
    }

    return (
        <Wrap>
            {
                dataForMapChart && years && dims && <>
                    <Chart>
                        <ChartMap num={false} data={dataForMapChart} gender={selectedGender.name}
                                  color={selectedGender.color} height={height} width={width}
                                  dims={dims}
                        >
                            <Scrollable>
                                <ChartSliderWrapper>
                                    <ChartSlider years={years} onChange={onChangeYear} currentYear={year}/>
                                </ChartSliderWrapper>
                            </Scrollable>
                            <YearSelectWrapper>
                                <YearsSelect years={years} selectedYear={currentYearValue!}
                                             onSelected={handleSelectedYear}/>
                            </YearSelectWrapper>
                        </ChartMap>
                    </Chart>
                    <Chart767px>
                        <ChartMap num={true} data={dataForMapChart} gender={selectedGender.name}
                                  color={selectedGender.color} height={height} width={width}
                                  dims={dims}
                        >
                            <Scrollable>
                                <ChartSliderWrapper>
                                    <ChartSlider years={years} onChange={onChangeYear} currentYear={year}/>
                                </ChartSliderWrapper>
                            </Scrollable>
                            <YearSelectWrapper>
                                <YearsSelect years={years} selectedYear={currentYearValue!}
                                             onSelected={handleSelectedYear}/>
                            </YearSelectWrapper>
                        </ChartMap>
                    </Chart767px>
                    <Line/>
                </>
            }
        </Wrap>
    )
}

export const MapWrapper = withChartService(MapWrapperComponent)
