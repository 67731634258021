import React, {useCallback} from 'react'
import styled, {css} from 'styled-components'
import {useTranslation} from 'react-i18next'

import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {useData} from '../../hooks'
import {ErrorWrapper, Spinner} from '../common'
import {PublishedLinkModel} from '../../models'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  display: flex;
  @media (max-width: 1239px) {
    max-width: 980px;
  }
`

const NameBlockStyle = css`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #427c8a;
  margin-top: 32px;

  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
    margin-top: 24px;
  }
`

const NameBlock = styled.a`
  ${NameBlockStyle};
  text-decoration-line: none;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`

const NameBlockNoLink = styled.div`
  ${NameBlockStyle};
  cursor: default;
`

const Block = styled.a`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  max-width: 774px;
  margin-top: 18px;
  text-decoration-line: none;
  cursor: pointer;

  ${NameBlock} + & {
    margin-top: 24px;
  }

  @media (max-width: 1023px) {
    max-width: 1160px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 18px;

    ${NameBlock} + & {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 18px;
    }
    @media(max-width: 1239px){
      max-width: 980px;
    }
  }

  @media (max-width: 767px) {
    max-width: 1160px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;

    ${NameBlock} + & {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 32px;
    }
  }

  &:hover {
    text-decoration-line: underline;
  }
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1239px) {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 64px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 24px;
  }
`

type Props = {
    id: string,
    apiService: ApiService
}

function LinksContent({id, apiService}: Props) {
    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`

    const query = useCallback(
        () => apiService.getLinkById(id, i18n.language),
        [id, i18n.language, apiService]
    )
    const state = useData(query)

    return (
        <Wrapper>
            {state.loading && <Spinner/>}
            {
                state.error && <ErrorWrapper>{state.error}</ErrorWrapper>
            }
            {
                state.data &&
                <LeftColumn>
                    {
                        (state.data! as any).children?.map((link: PublishedLinkModel) => (
                            <React.Fragment key={link.linkId}>
                                {
                                    link?.url ? (
                                        <NameBlock href={link?.url} target="_blank">
                                            {(link?.name as any)?.[language] || ''}
                                        </NameBlock>
                                    ) : (
                                        <NameBlockNoLink>{(link?.name as any)?.[language] || ''}</NameBlockNoLink>
                                    )
                                }
                                {
                                    link?.children?.length > 0 &&
                                    link.children.map((subLink: PublishedLinkModel) => (
                                        <Block href={subLink?.url} target="_blank" key={subLink.linkId}>
                                            {(subLink?.name as any)?.[language] || ''}
                                        </Block>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </LeftColumn>
            }
        </Wrapper>
    )
}

export default withApiService(LinksContent)
