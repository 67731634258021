import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {ShareSocialNetwork, ShareSocialNetwork767px} from '../common'
import {Link, useHistory} from 'react-router-dom'
import config from '../../enviroment'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'

const Title = styled.h1`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  padding: 0;
  margin: 0 0 4px;
  max-width: 744px;
  word-break: break-word;

  @media (max-width: 1023px) {
    font-size: 32px;
    line-height: 42px;
    margin: 0 0 12px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 34px;
    margin: 0 0 12px;
  }
`

const Header = styled.div`
  background: #427B89;
  padding: 64px 0 36px;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 56px 76px 36px;
    width: calc(100% - 152px);
  }

  @media (max-width: 767px) {
    padding: 56px 32px 32px;
    width: calc(100% - 64px);
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;

  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const RightSide = styled.div<{ isImpaired: boolean }>`
  display: ${(props: { isImpaired: boolean }) => props.isImpaired ? 'none' : 'flex'};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 300px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
`

const BackLink = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.7;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }
`

const WrapTitleAndLinks = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  @media (max-width: 767px) {
    padding-top: 16px;
  }
`

const DateInfo = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  width: 50%;
`

const TitleWithDate = styled.div`
  display: flex;
  flex-direction: column`

const WrapperFull = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const ActionWrapper1239 = styled.div`
  display: none;
  font-family: Sero Pro, serif;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.7;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

type Props = {
    title: string,
    date: string | Date
}

function HeaderSingleNews({title, date}: Props) {
    const history = useHistory()
    const mainUrl = config.PROD_URL
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const {t} = useTranslation()
    return (
        <Header>
            <Wrapper>
                <WrapperFull>
                    <BackLink to={'/news'}>&#8592;{t('news.backToAllNews')}</BackLink>
                </WrapperFull>
                <ActionWrapper1239>
                    <BackLink to={'/news'}>&#8592;{t('news.backToAllNews')}</BackLink>
                    <ShareSocialNetwork name={'share'} title={title}
                                        urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                </ActionWrapper1239>
                <ShareSocialNetwork767px linkTo={'news'} linkTitle={'news.backToAllNews'} title={title}
                                         urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                <WrapTitleAndLinks>
                    <TitleWithDate>
                        <Title>{title}</Title>
                        <DateInfo>{date}</DateInfo>
                    </TitleWithDate>
                    <RightSide isImpaired={isImpaired}>
                        <WrapperFull>
                            <ShareSocialNetwork name={'shareNews'} title={title}
                                                urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                        </WrapperFull>
                    </RightSide>
                </WrapTitleAndLinks>
            </Wrapper>
        </Header>
    )
}

export default withTranslation()(HeaderSingleNews)
