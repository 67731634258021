import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {ShareSocialNetwork} from './ShareSocialNetwork'
import {Link} from 'react-router-dom'


const IconClose = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 17px;
  cursor: pointer;
  background: center / contain no-repeat url("../../SVGs/close.svg");
  align-self: center;
`

const WrapTitleAndLinks = styled.div`

  display: none;

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
`

const SocialIcons = styled.div`
  background-image: url("../../SVGs/share.svg");
  background-position: center center;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
`

const BackLink = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.7;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }
`


type Props = {
    linkTo: string,
    linkTitle: string,
    urlIndicator: string,
    title: string
}

function ShareSocialNetworkComponent({linkTo, linkTitle, urlIndicator, title}: Props) {
    const {t} = useTranslation()

    const [showShare, setShowShare] = useState(false)

    return (
        <WrapTitleAndLinks style={showShare ? {justifyContent: 'flex-end'} : {}}>
            {!showShare && <React.Fragment><BackLink to={`/${linkTo}`}>
                &#8592;{t(`${linkTitle}`)}</BackLink>
                <SocialIcons onClick={() => setShowShare(!showShare)}/></React.Fragment>}
            {showShare && <ShareSocialNetwork title={title}
                                              urlIndicator={urlIndicator}
                                              name={'share'}/>}
            {showShare && <IconClose onClick={() => setShowShare(!showShare)}/>}

        </WrapTitleAndLinks>
    )
}

export const ShareSocialNetwork767px = withTranslation()(ShareSocialNetworkComponent)
