const en = {
    translation: {
        header: {
            Main: 'Main',
            app_name: 'Gender Statistics',
            Stat: 'Gender Statistics',
            belstat: 'National Statistical Committee of the Republic of Belarus',
            Demography: 'Demography',
            Health: 'Health Care',
            Education: 'Education',
            Economics: 'Employment and the Economy',
            Welfare: 'Welfare',
            Social: 'Public life and decision making',
            Communication: 'Communication means',
            Violence: 'Crime and violence',
            News: 'News',
            About: 'About portal',
            Contacts: 'Contacts',
            impaired: 'Version for the visually impaired',
            UsefulLinks: 'Useful links',
            informationResources: 'Information resources of the Republic of Belarus',
            internationalOrganizations: 'International organizations',
            genderStatistics: 'Gender statistics of other countries',
            statisticalPublications: 'Statistical publications',
            Search: 'Search'
        },
        greeting: {
            title: 'Gender Statistics',
            text: 'The main tool for monitoring progress in ensuring gender equality is gender statistics.',
            details: 'More details →',
        },
        navigationBar: {
            demography: 'Demography',
            health: 'Health Care',
            education: 'Education',
            economics: {
                firstLine: 'Employment and',
                secondLine: 'the Economy',
            },
            welfare: 'Welfare',
            social: {
                firstLine: 'Public life and',
                secondLine: 'decision making',
            },
            communication: {
                firstLine: 'Communication',
                secondLine: 'means',
            },
            violence: {
                firstLine: 'Crime',
                secondLine: 'and violence',
            },
        },
        news: {
            news: 'News',
            all: 'All →',
            allNews: 'All news →',
            backToAllNews: 'All news',
            source: 'Source',
            submitComment: 'Submit Comment',
            successfullySubmit: 'Comment submit successfully',
            submit: 'Send',
            errorInput: 'This field is required',
            error: 'Error',
            inputPlaceholder: 'What\'s your name',
            textareaPlaceholder: 'Your comment *'
        },
        poll: {
            poll: 'Survey',
            reply: 'Answer',
            error: 'Choose option',
            errorInput: 'Fill in the field',
            result: 'Thank you for taking the survey',
            customAnswer: 'Your own answer',
            errorSave: 'Error',
        },
        partners: {
            partners: 'Project partners',
            bla_bla_bla: 'Gender Statistics web-portal was developed by Belstat with the support of the United Nations Entity for Gender Equality and the Empowerment of Women (UN-Women) and United Nations Children’s Fund (UNICEF)'
        },
        footer: {
            belstat: '© 2020 National Statistical Committee of the Republic of Belarus',
            address: '12 Partizansky avenue, Minsk, 220070',
            socialInfo: 'Share about Gender Statistics web-portal',
            report: 'Report a bug'
        },
        demography: {
            title: 'Demography',
            addInfo: 'Population dynamics, its quantitative and qualitative characteristics are important information that is used to analyze the situation in the economy, labor market, education, health care and other aspects of society.',
        },
        health: {
            title: 'Health Care',
            addInfo: 'Gender equality and equity regarding the health throughout the lifespan is achieved by ensuring equal access to health services.'
        },
        education: {
            title: 'Education',
            addInfo: 'Education plays an exceptional role in the formation of personality and socialization of women and men.',

        },
        economics: {
            title: 'Employment and the Economy',
            addInfo: 'One of the main conditions for the economic security and well-being of women and men is to ensure equal opportunities in the realization of their right to work.',
        },
        welfare: {
            title: 'Welfare',
            addInfo: 'The level of welfare is an important indicator of a human economic and social opportunities.',
        },
        social: {
            title: 'Public life and decision making',
            addInfo: 'The balance of rights and opportunities for women and men at all levels of political, economic and social life is one of the indicators of the development of society.',

        },
        communication: {
            title: 'Communication means',
            addInfo: 'Information and communication technology is essential for women and men effectively for effective functioning in modern digital and virtual world.',
        },
        violence: {
            title: 'Crime and violence',
            addInfo: 'Human rights are the inalienable part of all human beings, regardless of gender, social origin or any other characteristic.',

        },
        category: 'Category',
        shareIndicator: 'Share indicator',
        share: 'Share',
        shareNews: 'Share news',
        downloadDataBlock: {
            downloadData: 'Download data',
            completeDataset: 'Complete dataset',
            APIsetupInstructions: 'API setup instructions',
            chartTablePDF: 'Chart / table / passport in PDF',
            tableXLSX: 'table in XLSX',
            print: 'Print'
        },
        dataViewMenu: {
            lineChart: 'Line chart',
            columnChart: 'Column chart',
            plotWithAreas: 'Plot with areas',
            pieChart1: 'Pie chart (1)',
            pieChart2: 'Pie chart (2)',
            map: 'Map',
            table: 'Table',
            passport: 'Passport',
            diagrams: 'Diagrams'
        },
        disaggregationIndicators: {
            title: 'Disaggregation indicators',
            bothGenders: 'Both genders',
            femaleGender: 'Female gender',
            maleGender: 'Male gender',
            showAllDisaggregation: 'Show all disaggregations',
            collapse: 'Collapse',
            apply: 'Apply'
        },
        about: {
            title: 'About portal',
            firstBlock: 'The Republic of Belarus has ratified and signed a number of international commitments in ensuring gender equality and combating gender-based discrimination. This fact confirms the commitment of our country to the goal of equality of women and men in all spheres of life.',
            secondBlock: 'The main tool for monitoring progress in ensuring gender equality is gender statistics.',
            genderEquality: 'Gender equality',
            firstBlockGenderEquality: 'This is not only a fundamental human right, but an essential basis for peace, prosperity and sustainable development.',
            secondBlockGenderEquality: 'It is equality of rights, responsibilities and opportunities for women and men, girls and boys.',
            GenderStatistics: 'Gender statistics',
            firstBlockGenderStatistics: 'It is statistics about the status of women and men in different spheres of life: in education and health services, in participation in paid and unpaid work, in government and decision-making process, in sharing family responsibilities and raising children, and in other spheres of society.',
            secondBlockGenderStatistics: 'Gender statistics help to assess the actual situation regarding the participation of women and men in social, economic and political processes and are necessary for effective monitoring, successful planning and evaluation of the impact of development programmes aimed at eliminating gender imbalances and stereotypes.',
            WebPortal: 'Gender Statistics web-portal',
            firstBlockWebPortal: 'It is a multipurpose tool for promoting and disseminating information about the progress towards gender equality in the Republic of Belarus. The portal provides up-to-date official statistical information and administrative data on gender-related issues and is an open information resource for all categories of users concerned with gender equality issues.',
            secondBlockWebPortal: 'This portal is available in three language versions: Russian, Belarusian and English. Indicators of gender statistics are grouped into eight thematic sections and are presented in the dynamics since 2000. The «passport» of the indicator contains basic methodological explanations. Users can choose how the information is presented in the form of tables, graphs and charts.',
        },
        report: {
            report: 'Report a bug',
            problem: 'Error description *',
            submit: 'Send'
        },
        notFound: {
            notFound: 'Page not found',
            description: 'Sorry, this page is not available. Perhaps you followed an invalid link or the page was deleted.',
            link: 'Home →'
        },
        contacts: {
            contacts: 'Contacts',
            name: 'National Statistical Committee of the Republic of Belarus',
            address: '12 Partizansky avenue, Minsk, 220070',
            fax: 'Fax',
            mode: 'Opening hours',
            mondayFriday: 'Monday to Friday from 9.00 to 18.00',
            lunchBreak: 'Lunch break from 13.00 to 14.00',
            dayOff: 'Closed: Saturday, Sunday',
            technicalSupport: 'Technical support',
            informationSupport: 'Information support',
            phone: '8 801 100-49-49 (free call in the Republic of Belarus from landline phones)',
            writeToUs: 'Contact us',
            addedNotes: 'You can use this form to contact the staff of the National Statistical Committee of the Republic of Belarus',
            askQuestion: 'Ask a question',
            suggestImprovement: 'Suggest Improvement',
            askYourQuestion: 'Ask your question',
            submit: 'Send',
            yourName: 'Last name, first name *',
            proposal: 'Tell us about your proposal for improving the portal',
            success: 'Your message has been successfully sent',
            fail: 'Failed to send message',
            errorEmail: 'Invalid email',
            namePerson: 'Last name, first name *',
            errorInput: 'Fill in the field',
        },
        search: {
            search: 'Search',
            found: 'Results found: ',
            notFound: 'По Вашему запросу ничего не найдено'
        },
        charts: {
            showValue: 'Display values',
            male: 'Men',
            female: 'Women',
            bothGender: 'Both sexes'
        },
        passport: {
            indicatorName: 'Indicator name',
            dataSource: 'Data sources',
            calcUnits: 'Units',
            calcMethod: 'Calculation methodology',
            dateOpen: 'The depth of the dynamic series',
            dateClose: 'Date Close',
            providingTerm: 'Periodicity',
            disaggregationLevels: 'Disaggregation levels',
            publicationDate: 'Date of last update',
            contact: 'Contact Information',
            comments: 'Comments'
        },
        indicators: {
            year: 'Year',
            error: 'Error',
            dataNoLoaded: 'Dataset not loaded',
            dataRecalled: 'Dataset recalled'
        },
        links: {
            statistics: 'Gender statistics of other countries',
            Georgia: 'Georgia, National Statistical Service',
            Moldova: 'Republic of Moldova, National Bureau of Statistics',
            Sweden: 'Sweden, Statistical Office',
            informationResources: 'Information resources of the Republic of Belarus',
            GenderPolicy: 'National Council for Gender Policy under the Council of Ministers of the Republic of Belarus',
            GenderEquality: 'National Action Plan for Gender Equality',
            for: 'for',
            years: 'years',
            MinistryOfLabor: 'Ministry of Labor and Social Protection of the Republic of Belarus',
            IndicatorReportingPlatform: 'National Sustainable Development Goal Indicator Reporting Platform',
            statisticsOnChildren: 'Universal Child Statistics Web Portal',
            InformationAnalyticalSystem: 'Information and analytical system for the dissemination of official statistical information',
            InternationalOrganizations: 'International organizations',
            UNWomen: 'United Nations Entity for Gender Equality and the Empowerment of Women (UN-Women)',
            UNICEF: 'United Nations Children\'s Fund (UNICEF)',
            UNECE: 'UNECE Statistical Database',
            WorldGenderStatistics:'The World Bank\'s Gender Statistics Portal',
            ILOSTAT: 'Database of the International Labor Organization "ILOSTAT"',
            StatisticalPublications: 'Statistical publications',
            WomenAndMen: 'Women and Men of the Republic of Belarus (collection)',
            year: 'year',
            ClusterSurvey: 'Multiple Indicator Cluster Survey to Assess the Situation of Children and Women',
            WomenEntrepreneurs: 'Women Entrepreneurs of Belarus, 2018 (brochure)',
            HouseholdDecision: 'ПриняDecision Making in the Household, 2019 (presentation)',
            healthyLifestyle: 'ОтношAttitudes of the population towards a healthy lifestyle, 2020 (presentation)'
        }
    }
}

export default en
