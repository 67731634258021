import React from 'react'
import styled from 'styled-components'


const ValueContainer = styled.div<{ padding: number }>`
  position: relative;
  z-index: 1;
  margin-top: ${(props: { padding: number }) => `${props.padding}px`};
  width: 78px;
  
  &::after {
    content: '';
    position: absolute;
    right: -2px;
    top: 8px;
    border-right: 1px solid #d7d7e4;
    height: 90.5%;
  }
`

type Props = {
    axisValue: string[]
    padding: number
}

export default function ChartYaxisComponent({padding ,axisValue}: Props) {
    return (
        <ValueContainer padding={padding}>
            <div style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                top: '0',
                left: '0',
                color: '#292c45',
                fontSize: '14px',
                lineHeight: '18px',
            }}>
                <span>{''}</span>
                <span style={{color: '#d7d7e4'}}>-</span>
            </div>
            {axisValue.map((item: any, index: number) => {
                return (
                    <div key={index + 'valcart'} style={{
                        position: 'absolute',
                        top: `${item.translate[1]}px`,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        right: 0,
                        color: '#292c45',
                        fontSize: '14px',
                        lineHeight: '18px',
                    }}>
                        <span>{item.value}</span>
                        <span style={{color: '#d7d7e4', marginLeft: '3px'}}>-</span>
                    </div>
                )
            })}
        </ValueContainer>
    )
}
