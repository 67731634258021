import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  font-family: Sero Pro,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FA8C0E;
  display: flex;
  align-items: center;
`
