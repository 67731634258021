import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {ChartService} from '../../services'
import {StateModel} from '../../models'
import {ChartDataItemModel} from '../../models/Chart.model'
import {withChartService} from '../wrapper'

const StyledChartWrapper = styled.div`
  position: relative;
`

const Line = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    height: 32px;
    width: 100%;
    background-color: #427B89;
  }
`

type Props = {
    chartService: ChartService,
    yearAmount: number,
    drawChart: (dataJson: any) => void
}

function ChartWrapperComponent({chartService, drawChart, yearAmount = 10}: Props) {

    const activeData = useSelector((state: StateModel) => state.indicatorActiveData)
    const [data, setData] = useState<ChartDataItemModel[] | null>(null)

    useEffect(() => {
        if (activeData) {
            const newData = chartService.getChartDataWithPreviousYears(activeData).slice(-yearAmount)
            const chartData = newData.map((e, i) => ({...e, ind: i}))
            setData(chartData)
        }
    }, [activeData, chartService, yearAmount])

    return (
        <StyledChartWrapper>
            {
                data && drawChart(data)
            }
            <Line/>
        </StyledChartWrapper>
    )
}

export const ChartWrapper = withChartService(ChartWrapperComponent)
