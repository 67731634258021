import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {Link, useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'

import {ShareSocialNetwork} from './ShareSocialNetwork'
import {ShareSocialNetwork767px} from './ShareSocialNetwork767px'
import {StateModel} from '../../models'
import '../../App.css'
import config from '../../enviroment'
import {IDictionary} from '../../models/Dictionary'

const Title = styled.h1`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  font-weight: 700;
  padding: 0;
  margin: 8px 15px 8px 0;

  //@media (max-width: 1023px) {
  //  font-size: 48px;
  //  line-height: 62px;
  //}

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 34px;
    //word-break: break-all;
  }
`

const SubTitle = styled.p`
  font-family: Sero Pro, serif;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.7;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Header = styled.div`
  background: #427B89;
  padding: 64px 0 36px;

  @media (max-width: 1023px) {
    padding: 64px 76px 36px;
  }

  @media (max-width: 767px) {
    padding: 56px 32px 36px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    width: 100%
  }
`

const RightSide = styled.div<{ isImpaired: boolean }>`
  display: ${(props: { isImpaired: boolean }) => props.isImpaired ? 'none' : 'flex'};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 300px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;

  @media (max-width: 1023px) {
    display: none;
  }
`

const BackLink = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.7;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }

`

const WrapTitleAndLinks = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  //gap: 10px;

  &:first-child {
    display: none;
  }
  
  


  @media (min-width: 1023px) {
    > div:first-child {
      margin-right: 40px;
    }

    > div:last-child {
      margin-left: 40px;
    }
  }

  @media (max-width: 1023px) {
    justify-content: space-between;
    padding: 8px 0;
    &:first-child {
      display: flex;
    }
    &:last-child {
      display: flex;
    }
  }

  @media (max-width: 767px) {
    &:first-child {
      display: none;
    }
  }
`

type Props = {
    data: any,
    units: string
}

function TitleSubcategoryComponent({data, units}: Props) {
    const {t, i18n} = useTranslation()
    const history = useHistory()
    const language = `lang_${i18n.language}`
    const [nameCategory, setNameCategory] = useState('')
    const indicatorInitialData = useSelector((state: StateModel) => state.indicatorInitialData)
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const mainUrl = config.PROD_URL

    useEffect(() => {
        const pathname = history.location.pathname.split('/')
        if (pathname != null) {
            setNameCategory(pathname[1])
        }
    }, [history.location.pathname, data, units])


    return (
        <Header>
            <Wrapper>

                <WrapTitleAndLinks style={{padding: '0'}}>
                    <BackLink to={`/${nameCategory}`}>
                        &#8592;{t(`${nameCategory}.title`)}</BackLink>
                    <ShareSocialNetwork
                        name={'share'} title={indicatorInitialData?.name ?
                        (indicatorInitialData.name as IDictionary)[language] : t('about.WebPortal')}
                        urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                </WrapTitleAndLinks>
                <BackLink to={`/${nameCategory}`} className='link-back'>
                    &#8592;{t(`${nameCategory}.title`)}</BackLink>
                <ShareSocialNetwork767px linkTitle={`${nameCategory}.title`} linkTo={nameCategory}
                                         title={indicatorInitialData?.name ?
                                             (indicatorInitialData.name as IDictionary)[language] : t('about.WebPortal')}
                                         urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                <WrapTitleAndLinks>
                    <div>
                        <Title>{indicatorInitialData?.name ?
                            (indicatorInitialData.name as IDictionary)[language] : ''}
                        </Title>
                        <SubTitle>
                            ({
                            indicatorInitialData?.attrs?.calcUnits ?
                                (indicatorInitialData.attrs.calcUnits as any)[language] || '' : ''
                        })
                        </SubTitle>
                    </div>
                    <RightSide isImpaired={isImpaired}>
                        <ShareSocialNetwork name={'shareIndicator'}
                                            title={indicatorInitialData?.name ?
                                                (indicatorInitialData.name as any)[language] : t('about.WebPortal')}
                                            urlIndicator={mainUrl + history.location.pathname + history.location.search}/>
                    </RightSide>
                </WrapTitleAndLinks>
            </Wrapper>
        </Header>

    )
}

export const TitleSubcategory = withTranslation()(TitleSubcategoryComponent)
