import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {ControlledCheckbox, ControlledRadioButton} from '../components'
import {changeRegularExpression} from '../store/actions/actions'
import {useDispatch, useSelector} from 'react-redux'
import {StateModel} from '../models'
import {useQuery} from '../hooks'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 80vh;

  //overflow-x: auto;
  //overflow-y: hidden;

  & {
    scrollbar-width: thin;
    scrollbar-color: #A8A8A8 #F1F1F1;
  }

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }

`

const Title = styled.div`
  font-family: Sero Pro, serif;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #292C45;
  max-width: 180px;
  float: left;
`

const NameIndicator = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  font-weight: 600;
  margin-bottom: 8px;
`

const StyledButton = styled.button`
  display: block;
  width: 100%;
  background: #3A3084;
  border-radius: 6px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 12px 36px;
  align-self: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #312970;
  }

  &:focus {
    background: #3A3084;
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    padding: 10px 34px;
  }

  &:active {
    background: #29225E;
  }

  &:disabled {
    background: #D7D7E4;
    cursor: auto;
  }
`

const Block = styled.div`
  padding: 32px;
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(41, 44, 69, 0.12);
  width: 384px;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1000;
  @media (max-width: 767px) {
    width: 100vw;
    right: 0;
    left: 0;
  }
`

const IconClose = styled.div`
  background-image: url("../../SVGs/close.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 14px;
  height: 14px;
  float: right;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: block;
  @media (max-width: 767px) {
    width: calc(100% - 70px);
  }
`

const ButtonWrap = styled.div`
  padding: 24px 32px;
  background: #F6F6F9;
  box-shadow: 0px -2px 8px rgba(41, 44, 69, 0.08);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 384px;
  @media (max-width: 767px) {
    width: calc(100% - 64px);
  }
`

const WrapControl = styled.div`
  //display: flex;
  //flex-direction: column
  display: block;
`


type Props = {
    hideBlock?: any,
    isShowDisaggregation: any
}

function BlockDisaggregation767px({hideBlock, isShowDisaggregation}: Props) {
    const {i18n} = useTranslation()
    const data = useSelector((state: StateModel) =>
        state.indicatorInitialData)

    const reg = useSelector((state: StateModel) =>
        state.indicatorRegularExpression)

    const graph = useSelector((state: StateModel) => state.indicatorGraph)

    const [disArray, setDisArray] = useState([])
    const [gender, setGender] = useState(null)
    const [others, setOthers] = useState(null)
    const [disabledItems, setDisabledItems] = useState<any>(null)
    const [lastSelectedItem, setLastSelectedItem] = useState<any>(null)
    const [lastSelectedDimCode, setLastSelectedDimCode] = useState<any>(null)

    const query = useQuery()
    const queryType = query.get('type')
    const {t} = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (reg) {
            setDisArray(reg.replace(/\|/g, '/').split(':') as any)
        }
    }, [reg])

    useEffect(() => {
        if (data && reg) {
            const neededData = data?.structure.dimensions
                .filter((item: any) => item.code !== 'PERIOD')
            const gender = (neededData as any).find((item: any) => item.code === 'SEX')
            setGender(gender as any)
            const others = (neededData as any).filter((item: any) => item.code !== 'SEX')
            setOthers(others)
        }
    }, [data, reg])

    useEffect(() => {
        setDisabledItems(null)
        setLastSelectedItem(null)
        setLastSelectedDimCode(null)
    }, [queryType])

    const showDisabled = (event: any, dimCode: string) => {
        const reg = new RegExp(`^${dimCode}-`)
        const items = Object.keys(graph[`${dimCode}-${event.target.value}`]).reduce((acc: any, key: string) => {
            acc[key] = graph[`${dimCode}-${event.target.value}`][key] || reg.test(key)
            return acc
        }, {})
        const newLastSelected = {
            ...lastSelectedItem, [dimCode]: {
                id: event.target.value,
                items: items
            }
        }
        setLastSelectedItem(newLastSelected)
        const disabledIt = Object.keys(newLastSelected).reduce((obj: any, dimCodeKey: string) => {
            if (dimCodeKey === Object.keys(newLastSelected)[0]) {
                return newLastSelected[dimCodeKey].items
            }
            return Object.keys(obj).reduce((diffRes: any, diffItem: string) => {
                diffRes[diffItem] = obj[diffItem] && newLastSelected[dimCodeKey].items[diffItem]
                return diffRes
            }, {})
        }, {})
        setDisabledItems(disabledIt)
        setLastSelectedDimCode(dimCode)
    }

    const handleSelect = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const newRegArray = [...disArray] as any
        newRegArray[id] = `(${event.target.value})`
        setDisArray(newRegArray)
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
        showDisabled(event, id === 0 ? 'SEX' : (others as any)[id - 1].code)
        return event.target.value
    }

    const changeCheckbox = (val: boolean, name: string, index: number) => {
        const rightName = name.split('_')[0]
        const newRegArray = [...disArray] as any
        const oldValue = newRegArray[index]
        if (val) {
            newRegArray[index] = `${oldValue.slice(0, oldValue.length - 1)}/${rightName})`
        } else {
            const array = oldValue.slice(1, oldValue.length - 1).split('/')
            newRegArray[index] = `(${array.filter((item: string) => item !== rightName).join('/')})`
        }
        setDisArray(newRegArray)
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
    }
    return (
        isShowDisaggregation && (<Block>
            <form>
                <Wrap>
                    <TitleWrapper>
                        <Title>{t('disaggregationIndicators.title')}</Title>
                        <IconClose onClick={hideBlock}/>
                    </TitleWrapper>
                    {gender &&
                    <React.Fragment><NameIndicator>{(gender as any).name[`lang_${i18n.language}`]}</NameIndicator>

                        <WrapControl>
                            {(gender as any).items.map((item: any) =>
                                <div key={(item as any).id} style={{'marginBottom': '6px'}}>
                                    {query.get('type') !== 'map' &&
                                    <ControlledCheckbox label={(item as any).name[`lang_${i18n.language}`]}
                                                        isDisable={(
                                                            (
                                                                query.get('type') === 'pieChart1' ||
                                                                query.get('type') === 'pieChart2'
                                                            )
                                                            && (gender! as any).code === 'SEX' && item.id === 'T')
                                                        }
                                                        value={(disArray as any)[0].includes((item as any).id)}
                                                        name={`${(item as any).id}_${item.code}`}
                                                        onChange={(event) => changeCheckbox(event, (item as any).id, 0)}/>}
                                    {query.get('type') === 'map' &&
                                    <ControlledRadioButton name={item.code} label={item.name[`lang_${i18n.language}`]}
                                                           value={item.id}
                                                           checked={(disArray as any)[0].replace('(', '').replace(')', '') === (item as any).id}
                                                           onSelected={(event) => handleSelect(event, 0)}/>}
                                </div>
                            )}
                        </WrapControl>
                    </React.Fragment>
                    }
                    {others &&
                    (others as any).map((block: any, i: number) =>
                        <React.Fragment key={block.code}> <NameIndicator style={{'marginTop': '16px'}}
                        >{block.name[`lang_${i18n.language}`]}</NameIndicator>
                            {block.items.map((item: any) =>
                                <div key={(item as any).id} style={{'marginBottom': '6px'}}>
                                    {((query.get('type') === 'map' && block.code !== 'REGION') || (query.get('type') !== 'map')) &&
                                    <ControlledRadioButton name={block.code} label={item.name[`lang_${i18n.language}`]}
                                                           value={item.id}
                                                           disabled={
                                                               disabledItems &&
                                                               lastSelectedDimCode !== block.code &&
                                                               !disabledItems[`${block.code}-${item.id}`]
                                                           }
                                                           checked={(disArray as any)[i + 1].replace('(', '').replace(')', '') === (item as any).id}
                                                           onSelected={(event) => handleSelect(event, i + 1)}/>}
                                    {query.get('type') === 'map' && block.code === 'REGION' &&
                                    <ControlledCheckbox label={(item as any).name[`lang_${i18n.language}`]}
                                                        value={(disArray as any)[i + 1].includes((item as any).id)}
                                                        name={`${(item as any).id}_${block.code}`}
                                                        onChange={(event) => changeCheckbox(event, (item as any).id, i + 1)}/>}
                                </div>
                            )}
                        </React.Fragment>)
                    }

                </Wrap>
            </form>
            <ButtonWrap>
                <StyledButton
                    onClick={hideBlock}>{t('disaggregationIndicators.apply')} </StyledButton>
            </ButtonWrap>
        </Block>))
}


export default withTranslation()(BlockDisaggregation767px)
