const ru = {
    translation: {
        header: {
            Main: 'Главная',
            app_name: 'Гендерная статистика',
            Stat: 'Гендерная статистика',
            belstat: 'Национальный статистический комитет Республики Беларусь',
            Demography: 'Демография',
            Health: 'Здравоохранение',
            Education: 'Образование',
            Economics: 'Занятость и экономика',
            Welfare: 'Благосостояние',
            Social: 'Общественная жизнь и принятие решений',
            Communication: 'Средства коммуникации',
            Violence: 'Правонарушения и насилие',
            News: 'Новости',
            About: 'О портале',
            Contacts: 'Контакты',
            impaired: 'Версия для слабовидящих',
            UsefulLinks: 'Полезные ссылки',
            informationResources: 'Информационные ресурсы Республики Беларусь',
            internationalOrganizations: 'Международные организации',
            genderStatistics: 'Гендерная статистика других стран',
            statisticalPublications: 'Статистические публикации',
            Search: 'Поиск'
        },
        greeting: {
            title: 'Гендерная статистика',
            text: 'Основным инструментом для мониторинга прогресса в обеспечении гендерного равенства является гендерная статистика',
            details: 'Подробнее →',
        },
        navigationBar: {
            demography: 'Демография',
            health: 'Здравоохранение',
            education: 'Образование',
            economics: {
                firstLine: 'Занятость',
                secondLine: 'и экономика',
            },
            welfare: 'Благосостояние',
            social: {
                firstLine: 'Общественная жизнь',
                secondLine: 'и принятие решений',
            },
            communication: {
                firstLine: 'Средства',
                secondLine: 'коммуникации',
            },
            violence: {
                firstLine: 'Правонарушения',
                secondLine: 'и насилие',
            },
        },
        news: {
            all: 'Все →',
            news: 'Новости',
            allNews: 'Все новости →',
            backToAllNews: 'Все новости',
            source: 'Источник',
            submitComment: 'Отправить комментарий',
            successfullySubmit: 'Комментарий успешно отправлен',
            submit: 'Отправить',
            errorInput: 'Обязательно к заполнению',
            error: 'Ошибка',
            inputPlaceholder: 'Как Вас зовут',
            textareaPlaceholder: 'Ваш комментарий *'
        },
        poll: {
            poll: 'Опрос',
            reply: 'Ответить',
            error: 'Выберите вариант ответа',
            errorInput: 'Заполните поле',
            result: 'Спасибо за участие в опросе',
            customAnswer: 'Свой вариант ответа',
            errorSave: 'Ошибка',
        },
        partners: {
            partners: 'Партнеры проекта',
            bla_bla_bla: 'Веб-портал «Гендерная статистика» разработан Белстатом при поддержке Структуры Организации Объединённых Наций по вопросам гендерного равенства и расширения прав и возможностей женщин (ООН-женщины) и Детского Фонда ООН (ЮНИСЕФ)'
        },
        footer: {
            belstat: '© 2020 Национальный статистический комитет Республики Беларусь',
            address: '220070, Минск, Партизанский проспект, 12',
            socialInfo: 'Расскажите о веб-портале «Гендерная статистика»',
            report: 'Сообщить об ошибке'
        },
        demography: {
            title: 'Демография',
            addInfo: 'Динамика народонаселения, его количественные и качественные характеристики являются важной информацией, с учетом которой осуществляется анализ положения дел в экономике, на рынке труда, в образовании, здравоохранении и в других сферах жизни общества.',
        },
        health: {
            title: 'Здравоохранение',
            addInfo: 'Равенство и справедливость в отношении здоровья женщин и мужчин на протяжении всей жизни достигается за счет обеспечения их равного доступа к услугам здравоохранения.',
        },
        education: {
            title: 'Образование',
            addInfo: 'Образованию принадлежит исключительная роль в становлении личности, социализации женщин и мужчин.',

        },
        economics: {
            title: 'Занятость и экономика',
            addInfo: 'Одним из главных условий экономической состоятельности и благополучия женщин и мужчин является обеспечение равных возможностей в реализации их права на труд.',

        },
        welfare: {
            title: 'Благосостояние',
            addInfo: 'Уровень благосостояния – важная характеристика экономических и социальных возможностей человека.'
        },
        social: {
            title: 'Общественная жизнь и принятие решений',
            addInfo: 'Баланс прав и возможностей женщин и мужчин на всех уровнях политической, экономической и социальной жизни является одним из индикаторов развития общества.',

        },
        communication: {
            title: 'Средства коммуникации',
            addInfo: 'Информационно-коммуникационные технологии необходимы женщинам и мужчинам для эффективного функционирования в современном цифровом и виртуальном мире.',
        },
        violence: {
            title: 'Правонарушения и насилие',
            addInfo: 'Права человека являются неотъемлемым достоянием всех людей, в независимости от пола, социального происхождения или любых других признаков.',

        },
        category: 'Категория',
        shareIndicator: 'Поделиться показателем',
        share: 'Поделиться',
        shareNews: 'Поделиться новостью',
        downloadDataBlock: {
            downloadData: 'Скачать данные',
            completeDataset: 'Полный набор данных',
            APIsetupInstructions: 'Инструкция по настройке API',
            chartTablePDF: 'Диаграмма/таблица/паспорт в PDF',
            tableXLSX: 'Таблица в XLSX',
            print: 'Печать'
        },
        dataViewMenu: {
            lineChart: 'Линейный график',
            columnChart: 'Столбчатый график',
            plotWithAreas: 'График с областями',
            pieChart1: 'Круговой график (1)',
            pieChart2: 'Круговой график (2)',
            map: 'Карта',
            table: 'Таблица',
            passport: 'Паспорт',
            diagrams: 'Диаграммы'
        },
        disaggregationIndicators: {
            title: 'Дезагрегация показателей',
            bothGenders: 'Оба пола',
            femaleGender: 'Женский пол',
            maleGender: 'Мужской пол',
            showAllDisaggregation: 'Показать все дезагрегации',
            collapse: 'Свернуть',
            apply: 'Применить'
        },
        about: {
            title: 'О портале',
            firstBlock: 'Республика Беларусь ратифицировала и подписала ряд международных обязательств в обеспечении гендерного равенства и преодоления дискриминации по признаку пола. Это подтверждает приверженность нашей страны целям равенства женщин и мужчин во всех сферах жизни.',
            secondBlock: 'Основным инструментом для мониторинга прогресса в обеспечении гендерного равенства является гендерная статистика.',
            genderEquality: 'Гендерное равенство',
            firstBlockGenderEquality: 'Это не только одно из основных прав человека, но и необходимая основа для достижения мира, процветания и устойчивого развития.',
            secondBlockGenderEquality: 'Это равенство прав, обязанностей и возможностей для женщин и мужчин, девочек и мальчиков.',
            GenderStatistics: 'Гендерная статистика',
            firstBlockGenderStatistics: 'Это статистика о положении женщин и мужчин в различных сферах жизни: в получении образования и услуг здравоохранения, в участии в оплачиваемом и неоплачиваемом труде, в управлении и принятии решений, в распределении семейных обязанностей и воспитании детей, других сферах жизнедеятельности общества.',
            secondBlockGenderStatistics: 'Гендерная статистика помогает оценить фактическую ситуацию в отношении участия женщин и мужчин в социальных, экономических и политических процесса и необходима для эффективного мониторинга, успешного планирования и оценки воздействия программ развития, направленных на устранение гендерных диспропорций и стереотипов.',
            WebPortal: 'Веб-портал «Гендерная статистика»',
            firstBlockWebPortal: 'Это универсальный инструмент популяризации и распространения информации о прогрессе в достижении гендерного равенства в Республике Беларусь. Портал содержит актуальную официальную статистическую информацию и административные данные гендерной тематики и является открытым информационным ресурсом для всех категорий пользователей, занимающихся вопросами гендерного равенства.',
            secondBlockWebPortal: 'Портал представлен в трех языковых версиях – русской, белорусской и английской. Показатели гендерной статистики сгруппированы по восьми тематическим разделам и приведены в динамике с 2000 года. В «паспорте» показателя содержатся основные методологические пояснения. Пользователи имеют возможность выбрать способ представления информации в виде таблиц, графиков и картограмм.',
        },
        report: {
            report: 'Сообщить об ошибке',
            problem: 'Описание ошибки *',
            submit: 'Отправить',
            successfullySubmit: 'Комментарий успешно отправлен',
            errorInput: 'Обязательно к заполнению',
            error: 'Ошибка',
        },
        notFound: {
            notFound: 'Страница не найдена',
            description: 'К сожалению, эта страница недоступна. Возможно, Вы воспользовались недействительной ссылкой или страница была удалена.',
            link: 'На главную →'
        },
        contacts: {
            contacts: 'Контакты',
            name: 'Национальный статистический комитет Республики Беларусь',
            address: '220070, Минск, Партизанский проспект, 12',
            fax: 'Факс',
            mode: 'Режим работы',
            mondayFriday: 'понедельник – пятница с 9.00 до 18.00',
            lunchBreak: 'обеденный перерыв с 13.00 до 14.00',
            dayOff: 'выходные: суббота, воскресенье',
            technicalSupport: 'Техническая поддержка',
            informationSupport: 'Информационная поддержка',
            phone: '8 801 100-49-49 (бесплатно по Республике Беларусь со стационарных телефонов)',
            writeToUs: 'Написать нам',
            addedNotes: 'С помощью данной формы Вы можете связаться с сотрудниками Национального статистического комитета Республики Беларусь',
            askQuestion: 'Задать вопрос',
            suggestImprovement: 'Предложить улучшение',
            namePerson: 'Фамилия, имя, отчество *',
            askYourQuestion: 'Задайте Ваш вопрос',
            submit: 'Отправить',
            yourName: 'Как вас зовут *',
            proposal: 'Расскажите о вашем предложении по улучшению портала',
            success: 'Ваше сообщение успешно отправлено',
            fail: 'Не удалось отправить сообщение',
            errorEmail: 'E-mail некорректен',
            errorInput: 'Заполните поле',
        },
        search: {
            search: 'Поиск',
            found: 'Найдено результатов: ',
            notFound: 'По Вашему запросу ничего не найдено'
        },
        charts: {
            showValue: 'Отобразить значения',
            male: 'Мужчины',
            female: 'Женщины',
            bothGender: 'Оба пола'
        },
        passport: {
            indicatorName: 'Наименование показателя',
            dataSource: 'Источники данных',
            calcUnits: 'Единицы измерения',
            calcMethod: 'Методология расчета',
            dateOpen: 'Глубина динамического ряда',
            dateClose: 'Дата отмены',
            providingTerm: 'Периодичность',
            disaggregationLevels: 'Уровни дезагрегации',
            publicationDate: 'Дата последнего обновления',
            contact: 'Контактная информация',
            comments: 'Комментарии'
        },
        indicators: {
            year: 'Год',
            error: 'Ошибка',
            dataNoLoaded: 'Набор не загружен',
            dataRecalled: 'Набор отозван'
        },
        links: {
            statistics: 'Гендерная статистика других стран',
            Georgia: 'Грузия, Национальная статистическая служба',
            Moldova: 'Республика Молдова, Национальное бюро статистики',
            Sweden: 'Швеция, Статистическое управление',
            informationResources: 'Информационные ресурсы Республики Беларусь',
            GenderPolicy: 'Национальный совет по гендерной политике при Совете Министров Республики Беларусь',
            GenderEquality: 'Национальный план действий по обеспечению гендерного равенства',
            for: 'на',
            years: 'годы',
            MinistryOfLabor: 'Министерство труда и социальной защиты Республики Беларусь',
            IndicatorReportingPlatform: 'Национальная платформа представления отчетности по показателям Целей устойчивого развития',
            statisticsOnChildren: 'Универсальный Веб-портал статистических данных о детях',
            InformationAnalyticalSystem: 'Информационно-аналитическая система распространения официальной статистической информации',
            InternationalOrganizations: 'Международные организации',
            UNWomen: 'Структура Организации Объединенных Наций по вопросам гендерного равенства и расширения прав и возможностей женщин («ООН-женщины»)',
            UNICEF: 'Детский фонд Организации Объединенных Наций (ЮНИСЕФ)',
            UNECE: 'Статистическая база данных ЕЭК ООН',
            WorldGenderStatistics:'Статистический портал по вопросам гендерного равенства Всемирного банка',
            ILOSTAT: 'База данных Международной организации труда «ILOSTAT»',
            StatisticalPublications: 'Статистические публикации',
            WomenAndMen: 'Женщины и мужчины Республики Беларусь (сборник)',
            year: 'год',
            ClusterSurvey: 'Многоиндикаторное кластерное обследование для оценки положения детей и женщин',
            WomenEntrepreneurs: 'Женщины-предприниматели Беларуси, 2018 (брошюра)',
            HouseholdDecision: 'Принятие решений в домашнем хозяйстве, 2019 (презентация)',
            healthyLifestyle: 'Отношение населения к здоровому образу жизни, 2020 (презентация)'
        }
    },
}

export default ru
