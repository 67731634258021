import React from 'react'
import styled from 'styled-components'

import {SpinnerForButton} from '../common'

const StyledButton = styled.button`
  background: #3A3084;
  border-radius: 6px;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 12px 18px;
  border: none;
  cursor: pointer;
  position: relative;
  font-weight: 600;

  &:hover {
    background: #312970;
  }

  &:focus {
    background: #3A3084;
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    padding: 10px 34px;
  }

  &:active {
    background: #29225E;
  }

  &:disabled {
    background: #D7D7E4;
    cursor: auto;
  }
`

const Title = styled.p<{ showSpinner: boolean }>`
  margin: 0;
  min-width: 20px;
  visibility: ${(props: { showSpinner: boolean }) => props.showSpinner ? 'hidden' : 'visible'};

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
`
const SpinnerWrapper = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
`

type ButtonProps = {
    label: string,
    showSpinner: boolean,
    disabled: boolean,
    width?: string,
    background?: string
}

export function Button({label, showSpinner, disabled, width, background}: ButtonProps) {
    return (
        <StyledButton style={{width: width, background: background}} type={'submit'} disabled={disabled}>
            <Title showSpinner={showSpinner}>{label}</Title>
            <SpinnerWrapper>
                {showSpinner && <SpinnerForButton/>}
            </SpinnerWrapper>
        </StyledButton>
    )
}
