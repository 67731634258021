import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {ControlledCheckbox, ControlledRadioButton} from '../components'

import {useDispatch, useSelector} from 'react-redux'
import {StateModel} from '../models'
import {useHistory} from 'react-router-dom'
import {useQuery} from '../hooks'
import {changeRegularExpression} from '../store/actions/actions'


const ScrollBar = styled.div`
  overflow: auto;

  //& {
  //  scrollbar-width: thin;
  //  scrollbar-color: #A8A8A8 #F1F1F1;
  //}

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }
`

const Wrap = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  width: 284px;
  height: fit-content;
  margin-right: 24px;
  padding: 13px 0 16px 16px;
  display: flex;
  flex-direction: column;
  max-height: 550px;
`

const Title = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  font-weight: 600;
  margin-bottom: 15px;
`

const NameIndicator = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  font-weight: 600;
  margin-bottom: 8px;
`
const ButtonShowAll = styled.button<{ isImpaired: boolean }>`
  border: ${(props: { isImpaired: boolean }) => props.isImpaired ? '1px solid black' : 'none'};
  background: #3A3084;
  border-radius: 6px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 12px 36px;
  margin-top: 32px;
  width: calc(100% - 16px);
  align-self: center;
  cursor: pointer;

  &:hover {
    background: #312970;
  }

  &:focus {
    background: #3A3084;
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
    padding: 10px 34px;
  }

  &:active {
    background: #29225E;
  }

  &:disabled {
    background: #D7D7E4;
    cursor: auto;
  }
`

const StyledButton = styled.button`
  margin-top: 36px;
  font-size: 14px;
  padding: 10px 15px;
  line-height: 20px;
  border: 2px solid rgba(215, 215, 228, .5);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: inherit;
  cursor: pointer;
  font-weight: 600;
  width: calc(100% - 16px);

  &:hover {
    background: rgba(215, 215, 228, 0.3);
    opacity: 0.5;
  }

  &:active {
    background: rgba(215, 215, 228, 0.5);
    opacity: 0.5;
  }

  &:focus {
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    border-radius: 6px;
    outline: none;
  }
`

const WrapControl = styled.div`
  display: flex;
  flex-direction: column`

type Props = {
    disabled?: boolean
}

function BlockDisaggregationContainer({disabled}: Props) {
    const {i18n} = useTranslation()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    const data = useSelector((state: StateModel) =>
        state.indicatorInitialData)

    const graph = useSelector((state: StateModel) => state.indicatorGraph)

    const reg = useSelector((state: StateModel) =>
        state.indicatorRegularExpression)

    const typeView = useSelector((state: StateModel) => state.typeOfView)

    const [disArray, setDisArray] = useState([])
    const [gender, setGender] = useState(null)
    const [others, setOthers] = useState(null)
    const [disabledItems, setDisabledItems] = useState<any>(null)
    const [lastSelectedItem, setLastSelectedItem] = useState<any>(null)
    const [lastSelectedDimCode, setLastSelectedDimCode] = useState<any>(null)
    const [showAllOptions, setShowAllOptions] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)

    const {t} = useTranslation()
    const dispatch = useDispatch()

    const history = useHistory()
    const query = useQuery()
    const queryType = query.get('type')

    useEffect(() => {
        if (reg) {
            setDisArray(reg.replace(/\|/g, '/').split(':') as any)
            history.replace(`?type=${typeView}&reg=${reg.replace(/\|/g, '/')}`)
        }
    }, [reg, typeView])

    useEffect(() => {
        if (queryType === 'passport') {
            setShowAllOptions(false)
        }

        setDisabledItems(null)
        setLastSelectedItem(null)
        setLastSelectedDimCode(null)
    }, [queryType])

    useEffect(() => {
        if (data && reg) {
            if (data?.structure.dimensions.length <= 2) {
                setDisabledButton(true)
            }
            const neededData = data?.structure.dimensions
                .filter((item: any) => item.code !== 'PERIOD')
            const gender = (neededData as any).find((item: any) => item.code === 'SEX')
            setGender(gender as any)
            const others = (neededData as any).filter((item: any) => item.code !== 'SEX')
            setOthers(others as any)
        }
    }, [data, reg])

    const showDisabled = (event: any, dimCode: string) => {
        // const dimCode = (others as any)[id - 1].code
        const reg = new RegExp(`^${dimCode}-`)
        const items = Object.keys(graph[`${dimCode}-${event.target.value}`]).reduce((acc: any, key: string) => {
            acc[key] = graph[`${dimCode}-${event.target.value}`][key] || reg.test(key)
            // acc[key] = graph[`${dimCode}-${event.target.value}`][key]
            return acc
        }, {})
        const newLastSelected = {
            ...lastSelectedItem, [dimCode]: {
                id: event.target.value,
                items: items
            }
        }
        setLastSelectedItem(newLastSelected)
        const disabledIt = Object.keys(newLastSelected).reduce((obj: any, dimCodeKey: string) => {
            if (dimCodeKey === Object.keys(newLastSelected)[0]) {
                return newLastSelected[dimCodeKey].items
            }
            return Object.keys(obj).reduce((diffRes: any, diffItem: string) => {
                diffRes[diffItem] = obj[diffItem] && newLastSelected[dimCodeKey].items[diffItem]
                return diffRes
            }, {})
        }, {})
        setDisabledItems(disabledIt)
        setLastSelectedDimCode(dimCode)
    }

    const handleSelect = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const newRegArray = [...disArray] as any
        newRegArray[id] = `(${event.target.value})`
        setDisArray(newRegArray)
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
        showDisabled(event, id === 0 ? 'SEX' : (others as any)[id - 1].code)
        return event.target.value
    }

    const changeCheckbox = (val: boolean, name: string, index: number) => {
        const rightName = name.split('_')[0]
        const newRegArray = [...disArray] as any
        const oldValue = newRegArray[index]
        if (val) {
            newRegArray[index] = `${oldValue.slice(0, oldValue.length - 1)}/${rightName})`
        } else {
            const array = newRegArray[index].slice(1, oldValue.length - 1).split('/')
            newRegArray[index] = `(${array.filter((item: string) => item !== rightName).join('/')})`
        }
        setDisArray(newRegArray)
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
    }

    return (
        <form>
            <Wrap>
                <ScrollBar>
                    <Title>{t('disaggregationIndicators.title')}</Title>
                    {gender &&
                    <React.Fragment>
                        <NameIndicator>{(gender as any).name[`lang_${i18n.language}`]}</NameIndicator>
                        <WrapControl>
                            {(gender as any).items.map((item: any) =>
                                <div key={(item as any).id} style={{'marginBottom': '6px'}}>
                                    {query.get('type') !== 'map' &&
                                    <ControlledCheckbox label={(item as any).name[`lang_${i18n.language}`]}
                                                        isDisable={disabled ||
                                                        (
                                                            (
                                                                query.get('type') === 'pieChart1' ||
                                                                query.get('type') === 'pieChart2'
                                                            )
                                                            && (gender! as any).code === 'SEX' && item.id === 'T')
                                                        }
                                                        value={(disArray as any)[0].includes((item as any).id)}
                                                        name={`${(item as any).id}_${item.code}`}
                                                        onChange={(event) => changeCheckbox(event, (item as any).id, 0)}/>}
                                    {query.get('type') === 'map' &&
                                    <ControlledRadioButton name={item.code} label={item.name[`lang_${i18n.language}`]}
                                                           value={item.id}
                                                           checked={(disArray as any)[0].replace('(', '').replace(')', '') === (item as any).id}
                                                           onSelected={(event) => handleSelect(event, 0)}/>}
                                </div>
                            )}
                        </WrapControl>
                    </React.Fragment>
                    }

                    {!showAllOptions && <ButtonShowAll isImpaired={isImpaired}
                                                       disabled={disabledButton}
                                                       onClick={() => setShowAllOptions(!showAllOptions)}>
                        {t('disaggregationIndicators.showAllDisaggregation')}</ButtonShowAll>}
                    {showAllOptions &&
                    (others as any).map((block: any, i: number) =>
                        <React.Fragment key={block.code}> <NameIndicator style={{'marginTop': '16px'}}
                        >{block.name[`lang_${i18n.language}`]}</NameIndicator>
                            {block.items.map((item: any) =>
                                <div key={(item as any).id} style={{'marginBottom': '6px', 'position': 'relative' }}>
                                    {query.get('type') === 'map' && block.code === 'REGION' &&
                                    <ControlledCheckbox label={(item as any).name[`lang_${i18n.language}`]}
                                                        isDisable={disabled || (disabledItems &&
                                                            // (item.id !== lastSelectedItem?.[block.code]?.id) &&
                                                            !disabledItems[`${block.code}-${item.id}`])}
                                                        value={(disArray as any)[i + 1].includes((item as any).id)}
                                                        name={`${(item as any).id}_${block.code}`}
                                                        onChange={(event) => changeCheckbox(event, (item as any).id, i + 1)}/>}
                                    {((query.get('type') === 'map' && block.code !== 'REGION') || (query.get('type') !== 'map')) &&
                                    <ControlledRadioButton name={block.code} label={item.name[`lang_${i18n.language}`]}
                                                           value={item.id}
                                                           disabled={
                                                               disabledItems &&
                                                               // (item.id !== lastSelectedItem?.[block.code]?.id) &&
                                                               lastSelectedDimCode !== block.code &&
                                                               !disabledItems[`${block.code}-${item.id}`]
                                                           }
                                                           checked={(disArray as any)[i + 1].replace('(', '').replace(')', '') === (item as any).id}
                                                           onSelected={(event) => handleSelect(event, i + 1)}/>}

                                </div>
                            )}
                        </React.Fragment>)
                    }
                    {showAllOptions && <StyledButton disabled={disabled}
                                                     onClick={() => setShowAllOptions(!showAllOptions)}>{t('disaggregationIndicators.collapse')} </StyledButton>}
                </ScrollBar>
            </Wrap>

        </form>)
}

export default withTranslation()(BlockDisaggregationContainer)
