import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'

import {PieChart2} from './PieChart2'
import {ChartSlider} from './ChartSlider'
import {YearsSelect} from '../common'
import {StateModel} from '../../models'
import {ChartService} from '../../services'
import {withChartService} from '../wrapper'
import {decimalPieChar2PermissionFormat} from './formatHelper'
import {changeDatePosition} from '../../store/actions/actions'

const ChartWrapper = styled.div`
  position: relative;
`

const ChartSliderWrapper = styled.div`
  width: fit-content;
  @media (max-width: 767px) {
    display: none;
  }
`

const YearSelectWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`

const Line = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    height: 32px;
    width: 100%;
    background-color: #427B89;
  }
`

const Scrollable = styled.div`

  padding: 0 5px;

  @media (max-width: 1239px) {
    max-width: 600px
  }

  @media (max-width: 1023px) {
    max-width: none;
  }
  overflow-x: auto;
  overflow-y: hidden;

  //& {
  //  scrollbar-width: thin;
  //  scrollbar-color: #A8A8A8 #F1F1F1;
  //}

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }
`

type Props = {
    chartService: ChartService
}

export function PieChart2WrapperComponent({chartService}: Props) {

    const dispatch = useDispatch()
    const activeData = useSelector((state: StateModel) => state.indicatorActiveData)
    const datePosition = useSelector((state: StateModel) => state.datePosition)

    const [data, setData] = useState<any>(null)

    const [permission, setPermission] = useState<boolean>(false)
    const [dataForPieChart, setDataForPieChart] = useState<any>(null)
    const [currentYear, setCurrentYear] = useState<number>(0)
    const [currentYearValue, setCurrentYearValue] = useState<string | number | null>(null)
    const [years, setYears] = useState<string[] | null>(null)

    useEffect(() => {
        if (activeData) {
            const newData = chartService.getChartDataForPie(activeData)
            const years = newData[0].slice(1) as string[]
            let startYear = datePosition
            if (datePosition === -1) {
                startYear = years.length - 1
                dispatch(changeDatePosition(startYear))
            }
            setData(newData)
            setPermission(decimalPieChar2PermissionFormat(newData))
            setDataForPieChart([
                [newData[1][0], newData[1][startYear + 1]],
                [newData[2][0], newData[2][startYear + 1]]
            ])

            setCurrentYear(startYear)
            setCurrentYearValue(newData[0][startYear + 1])
            setYears(years)
        }
    }, [activeData, chartService])

    const onChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
        const index = +(event.target.value)
        dispatch(changeDatePosition(index))
        const newChartData = [
            [data[1][0], data[1][index + 1]],
            [data[2][0], data[2][index + 1]]
        ]
        setDataForPieChart(newChartData)
        setCurrentYear(index)
        setCurrentYearValue(data[0][index + 1])
    }

    const handleSelectedYear = (year: string | number) => {
        const index = data[0].indexOf(year.toString())
        const newChartData = [
            [data[1][0], data[1][index]],
            [data[2][0], data[2][index]]
        ]
        setDataForPieChart(newChartData)
        setCurrentYear(index - 1)
        dispatch(changeDatePosition(index - 1))
        setCurrentYearValue(year)
    }

    return (
        <ChartWrapper>
            {
                data && years &&
                (
                    <PieChart2 data={dataForPieChart} permission={permission}>
                        <Scrollable>
                            <ChartSliderWrapper>
                                <ChartSlider years={years!} onChange={onChangeYear} currentYear={currentYear}/>
                            </ChartSliderWrapper>
                        </Scrollable>
                        <YearSelectWrapper>
                            <YearsSelect years={years!} selectedYear={currentYearValue!}
                                         onSelected={handleSelectedYear}/>
                        </YearSelectWrapper>
                    </PieChart2>
                )
            }
            <Line/>
        </ChartWrapper>
    )
}

export const PieChart2Wrapper = withChartService(PieChart2WrapperComponent)
