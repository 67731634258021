import React, {ChangeEvent} from 'react'
import styled from 'styled-components'

const Label = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 30px;
  
  &.active {
    text-decoration: underline;
  }

  &:hover {
    color: black;
    text-decoration: underline;
  }
`

const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const CheckMark = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #3A3084;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3A3084;

    ${Radio}:checked ~ & {
      display: block;
    }

    ${Radio}:disabled ~ & {
      background-color: lightgrey;
      display: block;
    }
  }
`

type RadioButtonProps = {
    name?: string,
    value?: number | string,
    label?: string,
    className?: string,
    checked?: boolean,
    onSelected: (event: ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean
}

export function ControlledRadioButton({name, value, label, checked, className, onSelected, disabled}: RadioButtonProps) {

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSelected(event)
    }

    return (
        <Label className={checked ? className + ' active': className}>
            <Radio type="radio"
                   disabled={disabled}
                   name={name} value={value} checked={checked}
                   onChange={handleOnChange}
            />
            <CheckMark/>
            {label}
        </Label>
    )
}
