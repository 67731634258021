import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import {useTranslation, withTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {usePromiseTracker} from 'react-promise-tracker'
import {useSelector} from 'react-redux'

import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {Button} from '../controls'
import '../../App.css'
import {StateModel} from '../../models'
import {FeedbackPost} from '../../models'

const ErrorWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 18px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled.section<{ isImpaired: boolean }>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: ${(props: { isImpaired: boolean }) => props.isImpaired ? 'static' : 'absolute'};
  border: ${(props: { isImpaired: boolean }) => props.isImpaired ? '1px solid black' : 'none'};
  background: #FFFFFF;
  padding: 32px;
  top: -130px;
  right: 0;
  width: fit-content;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.02), 0 6px 48px rgba(41, 44, 69, 0.08);
  border-radius: 16px;

  @media (max-width: 1023px) {
    position: static;
  }

  @media (max-width: 767px) {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 24px 24px 64px;
    width: calc(100% - 48px);
  }
`

const Title = styled.h2`
  padding: 0;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  margin: 0 0 12px;


  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Textarea = styled.textarea`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  background: #F6F6F9;
  border-radius: 4px;
  padding: 18px 24px 10px;
  width: 452px;
  border: none;
  height: 180px;
  outline: none;

  @media (max-width: 767px) {
    width: calc(100% - 36px);
    font-size: 14px;
    line-height: 20px;
    padding: 20px 16px 6px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Icon = styled.div`
  margin-right: 8px;
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const AddInfo = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  max-width: 500px;
  margin-bottom: 42px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const Input = styled.input`
  width: 452px;
  padding: 18px 24px 10px;
  margin-bottom: 12px;
  background: #F6F6F9;
  border-radius: 4px;
  border: none;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  outline: none;

  @media (max-width: 767px) {
    width: calc(100% - 36px);
    font-size: 14px;
    line-height: 20px;
    padding: 15px 16px 15px;
  }
`

const Label = styled.label`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #292C45;
  opacity: 0.5;
  position: absolute;
  top: 10px;
  left: 24px;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 14px;
    top: 4px;
    left: 16px
  }

`

const TabButtons = styled.div`
  border-bottom: 1px #D4D5DA solid;
  display: flex;
  margin-bottom: 24px;
`

const Tab = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #292C45;
  opacity: 0.5;
  padding-bottom: 11px;
  width: fit-content;
  margin-right: 24px;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    width: min-content;
    padding-bottom: 8px;
  }
`

const WrapInput = styled.div`
  position: relative;
`

const Error = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;
  margin-left: 24px;
  align-self: center;
  display: flex;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

const Success = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #36B27E;
  margin-left: 24px;
  align-self: center;
  display: flex;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

const WrapButton = styled.div`
  display: flex;
`

type NewsProps = {
    apiService: ApiService,
}

type FormData = {
    email: string,
    name: string,
    question?: string
    proposal?: string
}

function FormWriteUsComponent({apiService}: NewsProps) {
    const [captchaValue, setCaptcha] = useState<null | string>(null)
    const [captchaKey, setCaptchaKey] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<null | string>(null)
    const [success, setSuccess] = useState<null | string>(null)
    const [labelStatus, setLabelStatus] = useState({name: false, email: false, question: false, proposal: false})
    const {register, handleSubmit, errors, reset, getValues} = useForm()
    const {t, i18n} = useTranslation()
    const {promiseInProgress} = usePromiseTracker()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const [statusTabButton, setStatusTabButton] = useState({
        askQuestion: true,
        suggestImprovement: false
    })

    const onSubmit = (formObj: FormData) => {
        if (captchaValue) {
            setLoading(true)
            setError('')
            setSuccess('')
            const sendObj: FeedbackPost = {
                newsId: null,
                userName: formObj.name,
                userEmail: formObj.email,
                type: statusTabButton.askQuestion ? 'QUESTION' : 'SUGGESTIONS',
                content: statusTabButton.askQuestion ? formObj.question || '' : formObj.proposal || '',
                failureUrl: null
            }
            return apiService.postFrontendFeedback(sendObj, i18n.language).then(() => {
                reset({})
                setLabelStatus({name: false, email: false, question: false, proposal: false})
                setSuccess(t('contacts.success'))
            }).catch(() => {
                setError(t('contacts.fail'))
            }).finally(() => setLoading(false))
        }
    }

    const BorderBottomStyle = {borderBottom: '4px solid #EE7B5D', color: '#292C45', opacity: '1'}

    const ChangeActive = () => {
        setStatusTabButton((prevState => ({
            askQuestion: !prevState.askQuestion,
            suggestImprovement: !prevState.suggestImprovement
        })))
        setError('')
        setSuccess('')
    }

    const ShowLabel = (name: string) => {

        const value = getValues(name)
        if (value.length > 0) {
            setLabelStatus((prevState: any) => {
                const copyObj = {...prevState}
                copyObj[`${name}`] = true
                return {...copyObj}
            })
        } else {
            setLabelStatus((prevState: any) => {
                const copyObj = {...prevState}
                copyObj[`${name}`] = false
                return {...copyObj}
            })
        }
    }

    const onChange = (value: string | null) => setCaptcha(value)

    useEffect(() => {
        setCaptchaKey((oldKey) => oldKey + 1)
    }, [i18n.language, setCaptchaKey])

    return (
        <Wrapper isImpaired={isImpaired}>
            <Title>{t('contacts.writeToUs')}</Title>
            <AddInfo>{t('contacts.addedNotes')}</AddInfo>
            <TabButtons>
                <Tab style={statusTabButton.askQuestion ? BorderBottomStyle : {}}
                     onClick={() => ChangeActive()}>{t('contacts.askQuestion')}</Tab>
                <Tab onClick={() => ChangeActive()}
                     style={statusTabButton.suggestImprovement ? BorderBottomStyle : {}}>{t('contacts.suggestImprovement')}</Tab>
            </TabButtons>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <WrapInput>
                    <Label
                        style={labelStatus.name ? {display: 'block'} : {display: 'none'}}>{t('contacts.namePerson')}</Label>
                    <Input className={errors.name ? 'Border-error' : ''} placeholder={t('contacts.namePerson')}
                           name={'name'} ref={register({required: true})}
                           onChange={() => ShowLabel('name')}/>
                    {errors.name && <ErrorWrapper>{t('contacts.errorInput')}</ErrorWrapper>}
                </WrapInput>
                <WrapInput>
                    <Label style={labelStatus.email ? {display: 'block'} : {display: 'none'}}>Email</Label>
                    <Input className={errors.name ? 'Border-error' : ''} placeholder={'Email *'} name={'email'}
                           ref={register({
                               required: true,
                               pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                           })}
                           onChange={() => ShowLabel('email')}
                    />
                    {errors.email && <ErrorWrapper>{t('contacts.errorEmail')}</ErrorWrapper>}
                </WrapInput>
                {statusTabButton.askQuestion && <WrapInput>
                    <Label
                        style={labelStatus.question ? {display: 'block'} : {display: 'none'}}>{t('contacts.askYourQuestion')}</Label>
                    <Textarea className={errors.name ? 'Border-error' : ''}
                              placeholder={`${t('contacts.askYourQuestion')}`} name={'question'}
                              ref={register({required: true})}
                              onChange={() => ShowLabel('question')}/>
                    {errors.question && <ErrorWrapper>{t('contacts.errorInput')}</ErrorWrapper>}
                </WrapInput>}
                {statusTabButton.suggestImprovement &&
                <WrapInput>
                    <Label
                        style={labelStatus.proposal ? {display: 'block'} : {display: 'none'}}>{t('contacts.proposal')}</Label>
                    <Textarea className={errors.name ? 'Border-error' : ''} placeholder={`${t('contacts.proposal')}`}
                              name={'proposal'}
                              ref={register({required: true})}
                              onChange={() => ShowLabel('proposal')}/>
                    {errors.proposal && <ErrorWrapper>{t('contacts.errorInput')}</ErrorWrapper>}
                </WrapInput>}
                <div style={{'margin': '12px 0 18px'}}>
                    <ReCAPTCHA
                        key={captchaKey}
                        hl={i18n.language}
                        sitekey='6LfmN5kaAAAAAJbmzFVEzuXqK6IWk347y-NjUuHj'
                        onChange={(token) => onChange(token)}
                    />
                </div>
                <WrapButton>
                    <Button width={'fit-content'} label={t('report.submit')}
                            showSpinner={loading}
                            disabled={promiseInProgress}/>
                    {error &&
                    <Error><Icon style={{backgroundImage: 'url(../../SVGs/error.svg)'}}/>{t('contacts.fail')}</Error>}
                    {success &&
                    <Success><Icon style={{backgroundImage: 'url(../../SVGs/success.svg)'}}/>{t('contacts.success')}
                    </Success>}
                </WrapButton>
            </Form>

        </Wrapper>
    )
}

export const FormWriteUs = withTranslation()(withApiService(FormWriteUsComponent))
