import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function ViolenceContainer(){

  return (<div>
    <TitleIndicatorSection title='violence.title' addInfo='violence.addInfo' imageName='violence'/>
    <IndicatorSubcategories keyName='violence' keyValue={8}/>
  </div>)
}
