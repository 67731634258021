import React from 'react'
import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
  to {
    transform: rotate(360deg) scale(0.5);
  }
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin: 5px auto;
`

const SpinnerContent = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`

const Ring = styled(SpinnerContent)`
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent;
  animation: 2s ${rotate} infinite alternate;
  
  &:nth-child(1){
    border-left-color: #979fd0;
    border-right-color: #979fd0;
  }
  
  &:nth-child(2){
    border-top-color: #979fd0;
    border-bottom-color: #979fd0;
    animation-delay: 1s;
  }
`

const Dot = styled(SpinnerContent)`
  width: 1rem;
  height: 1rem;
  background: #979fd0;
`

export function Spinner() {
  return (
    <SpinnerContainer>
      <Ring />
      <Ring />
      <Dot />
    </SpinnerContainer>
  )
}
