import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'

import {useComponentVisible} from '../../hooks'
import {ControlledRadioButton} from '../controls'

const LinkWrapper = styled.div`
  position: relative;
`

const LinkTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  font-family: sans-serif;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../SVGs/down-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: .7em auto, 100%;
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  padding: 6px 36px 6px 16px;
  border: 2px solid rgba(215, 215, 228, 0.5);
  box-sizing: border-box;
  border-radius: 100px;

  &:hover {
    color: black;
  }
`

const MoreLinks = styled.div`
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 2;
  padding: 18px;
  background: linear-gradient(0deg, rgba(215, 215, 228, 0.25), rgba(215, 215, 228, 0.25)), #FFFFFF;
  border: 1px solid #D7D7E4;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(95, 102, 160, 0.16);
  border-radius: 8px;
  width: 231px;
`

const HeaderLink = styled.div`
  display: block;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    text-decoration-line: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

type SelectProps = {
    years: string[] | number[],
    selectedYear: string | number,
    onSelected: (year: number) => void
}

export function YearsSelect({years, selectedYear, onSelected}: SelectProps) {
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)
    const [val, setIsActive] = useState(years[years.length - 1])
    const handleOpenChanged = () => setIsComponentVisible(!isComponentVisible)

    const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setIsActive(+event.target.value)
        onSelected(+event.target.value)
        setIsComponentVisible(false)
    }

    useEffect(() => {
        if (val !== selectedYear) {
            setIsActive(selectedYear)
        }
    }, [selectedYear, val])

    return (
        <LinkWrapper ref={ref}>

            <LinkTitle onClick={handleOpenChanged} style={val ? {'color': '#6895A1'} : {}}>{val}</LinkTitle>
            {isComponentVisible && (
                <MoreLinks>
                    {(years! as any).map((item: any) => (
                            <HeaderLink key={item}>
                                <ControlledRadioButton label={item} name={item} value={item}
                                                       onSelected={handleSelect} checked={val.toString() === item.toString()}/>
                            </HeaderLink>
                        )
                    )}
                </MoreLinks>
            )}
        </LinkWrapper>
    )
}
