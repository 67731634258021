const config = {
    BASE_URL: 'http://192.168.51.79:9040',
    PROD_URL: 'http://192.168.51.79:8383'
}

if(process.env.REACT_APP_ENV==='production') {
    config.BASE_URL = 'https://gender-api.belstat.gov.by'
    config.PROD_URL = 'https://gender.belstat.gov.by'
}


export default  config
