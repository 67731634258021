import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function EconomicsContainer(){

  return (<div>
    <TitleIndicatorSection title='economics.title' addInfo='economics.addInfo' imageName='economics'/>
    <IndicatorSubcategories keyName='economics' keyValue={4} />
  </div>)
}
