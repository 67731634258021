import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

import {FooterLine, SubmitReport} from '../content'
import {TelegramShareButton, FacebookShareButton, TwitterShareButton} from 'react-share'
import config from '../../enviroment'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`

const FooterContent = styled.div`
  width: 100%;
  max-width: 1184px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`
const LeftSide = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (max-width: 1023px) {
    padding-left: 64px;
    width: 100%;
    max-width: 980px;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`

const BelstatInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`

const BelstatAddress = styled.div`
  margin-top: 8px;

  @media (max-width: 767px) {
    width: 190px
  }
`

const BelstatMail = styled.a`
  color: #14537E;
  text-decoration: none;
  cursor: pointer;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 415px;
  @media (max-width: 1023px) {
    padding-right: 64px;
    margin-top: 36px;
  }

  @media (max-width: 767px) {
    display: block;
    margin: 8px 24px;
    padding-right: 0;
  }
`

const SocialLogo = styled.div`
  width: 34px;
  height: 34px;
  margin-left: 20px;
  position: relative;
  top: 0;
  cursor: pointer;

  &:hover {
    top: -5px;
  }

  transition: top .2s;

  @media (max-width: 767px) {
    margin: 0 16px 0 0;
  }
`

const Facebook = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/facebook.svg");
`

const Twitter = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/twitter.svg");
`

const Telegram = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/telegram.svg");
`

const LinksInfo = styled.div`
  flex: 1;
  text-align: end;

  @media (max-width: 767px) {
    text-align: start;
    width: 200px;
    margin-bottom: 16px;
  }

`

const StyledButton = styled.button`
  font-size: 14px;
  padding: 10px 15px;
  line-height: 20px;
  border: 2px solid rgba(215, 215, 228, .5);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: inherit;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background: rgba(215, 215, 228, 0.3);
    opacity: 0.5;
  }

  &:active {
    background: rgba(215, 215, 228, 0.5);
    opacity: 0.5;
  }

  &:focus {
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    outline: none;
    border-radius: 6px;
  }

  @media (max-width: 1023px) {
    margin-left: 64px;
    margin-top: 36px;
  }
  @media (max-width: 767px) {
    margin: 24px;
    width: 100%;
  }
`

const WrapSocialIcon = styled.div`
  display: flex;
  @media (max-width: 1023px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: flex;
  }
`

const WrapSocialIcon768px = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: flex;
  }

  @media (max-width: 767px) {
    display: none;
  }

`

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #292C45;
  opacity: 0.5;
  z-index: 3;
`


export function Footer() {
    const {t} = useTranslation()

    const [isVisible, setVisible] = useState(false)
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    const ReportClick = () => setVisible(!isVisible)

    const urlHome = `${config.PROD_URL}/home`

    return (
        <FooterWrapper>
            <FooterLine/>
            <FooterContent>
                <LeftSide>
                    <BelstatInfo>{t('footer.belstat')}</BelstatInfo>
                    <BelstatAddress>
                        <div>{t('footer.address')}</div>
                        <BelstatMail href="mailto: gender@belstat.gov.by">
                            gender@belstat.gov.by
                        </BelstatMail>
                    </BelstatAddress>
                </LeftSide>
                <StyledButton onClick={ReportClick}>{t('footer.report')}</StyledButton>
                <RightSide>
                    {!isImpaired && <WrapSocialIcon768px>
                        <FacebookShareButton style={{outline: 'none'}}
                                             url={urlHome}
                                             quote={t('about.WebPortal')}
                        >
                            <Facebook/>
                        </FacebookShareButton>
                        <TwitterShareButton style={{outline: 'none'}}
                                            url={urlHome}
                                            title={t('about.WebPortal')}
                        >
                            <Twitter/>
                        </TwitterShareButton>

                        <TelegramShareButton style={{outline: 'none'}}
                                             url={urlHome}
                                             title={t('about.WebPortal')}
                        >
                            <Telegram/>
                        </TelegramShareButton>
                    </WrapSocialIcon768px>}
                    {!isImpaired && <><LinksInfo>{t('footer.socialInfo')}</LinksInfo>
                     <WrapSocialIcon>
                        <FacebookShareButton style={{outline: 'none'}}
                                             url={urlHome}
                                             quote={t('about.WebPortal')}
                        >
                            <Facebook/>
                        </FacebookShareButton>
                        <TwitterShareButton style={{outline: 'none'}}
                                            url={urlHome}
                                            title={t('about.WebPortal')}
                        >
                            <Twitter/>
                        </TwitterShareButton>

                        <TelegramShareButton style={{outline: 'none'}}
                                             url={urlHome}
                                             title={t('about.WebPortal')}
                        >
                            <Telegram/>
                        </TelegramShareButton>

                    </WrapSocialIcon></>}
                </RightSide>
            </FooterContent>
            {isVisible && <Background onClick={(event) => {
                event.preventDefault()
                ReportClick()
            }}/>}
            {isVisible && <SubmitReport reportClick={ReportClick}/>}
        </FooterWrapper>
    )
}
