import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {LinkModel} from './'
import {useComponentVisible} from '../../hooks'

const LinkWrapper = styled.div`
  position: relative;
`

const LinkTitle = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #427B89;
  font-family: sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: initial;
  background-image: url("../../SVGs/down-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0 top 50%, 0 0;
  background-size: .7em auto, 100%;
  padding-right: 20px;
  margin-top: 26px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: black;
  }

  &:active {
    color: #427B89;
  }
`

const MoreLinks = styled.div`
  display: flex;
  flex-direction: column;

`

const HeaderLink = styled(Link)`
  text-decoration-line: none;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  margin-top: 12px;

  &:hover {
    text-decoration-line: underline;
  }
`

type LinkSelectProps = {
    title: string,
    links: LinkModel[],
    closeMenu: any,
    pathname: any
}

function LinkSelectBurgerMenuComponent(props: LinkSelectProps) {
    const {t} = useTranslation()
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)

    const handleOpenChanged = () => setIsComponentVisible(!isComponentVisible)

    const {title, links, closeMenu, pathname} = props
    const [val, setIsActive] = useState(false)
    useEffect(() => {
        const val = !!links.find((item: any) => pathname === item.key.toLowerCase())
        setIsComponentVisible(val)
        setIsActive(val)
    }, [pathname, links, setIsComponentVisible])
    return (
        <LinkWrapper ref={ref}>
            <LinkTitle onClick={handleOpenChanged} style={val ? {'color': '#6895A1'} : {}}>{title}</LinkTitle>
            {isComponentVisible && (
                <MoreLinks>
                    {links.map((child: LinkModel, index: number) => (
                        <HeaderLink key={index} to={child.to!} onClick={closeMenu}
                                    style={pathname === child.key.toLowerCase() ? {
                                        'textDecorationLine': 'underline',
                                        'color': '#292C45',
                                        'opacity': '0.7'
                                    } : {}}
                        >
                            {t(`header.${child.key}`)}
                        </HeaderLink>)
                    )}
                </MoreLinks>
            )}
        </LinkWrapper>
    )
}

export const LinkSelectBurgerMenu = withTranslation()(LinkSelectBurgerMenuComponent)
