import {Redirect, Route, Switch} from 'react-router-dom'
import React, {useEffect} from 'react'
import {ToastContainer} from 'react-toastify'
import {useDispatch} from 'react-redux'

import {WithHeaderAndFooter} from './components'
import {
    HomeContainer,
    HealthContainer,
    EducationContainer,
    WelfareContainer,
    SocialContainer,
    CommunicationContainer,
    ViolenceContainer,
    NewsContainer,
    AboutContainer,
    ContactsContainer,
    EconomicsContainer,
    SearchResultContainer,
    DemographyContainer, LinksContainer,
} from './containers'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import IndicatorContainer from './containers/indicator.container'
import SingleNewsContainer from './containers/singleNews.container'
import NotFoundContainer from './containers/404page'
import {useQuery} from './hooks'
import {changeIsImpaired, loadIndicatorTypeAndRegular} from './store/actions/actions'

function App() {
    const query = useQuery()
    const dispatch = useDispatch()

    const processImpaired = () => {
        if (localStorage.getItem('version')) {
            dispatch(changeIsImpaired(true))
            const avtor = document.getElementsByClassName('avtor')  as any
            const elements = document.querySelector('.special-quit > button')  as any
            const audio = document.getElementsByClassName('special-audio') as any
            avtor[0].style.display = 'none'
            if (audio && audio.length > 0) {
                audio[0].style.display = 'none'
            }
            elements.addEventListener('click', () => {
                dispatch(changeIsImpaired(false))
                localStorage.removeItem('version')
            })
        }
    }

    useEffect(
        () => {
            processImpaired()
            const regExp = query.get('reg')
            const type = query.get('type')
            if (regExp && type) {
                const reg = regExp.replace(/\//g, '|')
                dispatch(loadIndicatorTypeAndRegular({type, reg}))
            }
        },
        []
    )

    return (
        <>
            <WithHeaderAndFooter>
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/home/"/>}/>
                    <Route exact path="/home" component={HomeContainer}/>
                    <Route exact path="/news" component={NewsContainer}/>
                    <Route exact path="/news/:id" component={SingleNewsContainer}/>
                    <Route exact path="/about" component={AboutContainer}/>
                    <Route exact path="/contacts" component={ContactsContainer}/>
                    <Route exact path="/demography/:id" component={IndicatorContainer}/>
                    <Route exact path="/demography" component={DemographyContainer}/>
                    <Route exact path="/health" component={HealthContainer}/>
                    <Route exact path="/health/:id" component={IndicatorContainer}/>
                    <Route exact path="/education" component={EducationContainer}/>
                    <Route exact path="/education/:id" component={IndicatorContainer}/>
                    <Route exact path="/economics" component={EconomicsContainer}/>
                    <Route exact path="/economics/:id" component={IndicatorContainer}/>
                    <Route exact path="/welfare" component={WelfareContainer}/>
                    <Route exact path="/welfare/:id" component={IndicatorContainer}/>
                    <Route exact path="/social" component={SocialContainer}/>
                    <Route exact path="/social/:id" component={IndicatorContainer}/>
                    <Route exact path="/communication" component={CommunicationContainer}/>
                    <Route exact path="/communication/:id" component={IndicatorContainer}/>
                    <Route exact path="/violence" component={ViolenceContainer}/>
                    <Route exact path="/violence/:id" component={IndicatorContainer}/>
                    <Route exact path="/search" component={SearchResultContainer}/>
                    <Route exact path="/404" component={NotFoundContainer}/>
                    <Route exact path="/links/:id" component={LinksContainer}/>
                    <Route render={() => <Redirect to="/404"/>}/>
                </Switch>
            </WithHeaderAndFooter>
            <ToastContainer>
            </ToastContainer>
        </>
    )
}

export default App
