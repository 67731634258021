import {MutableRefObject} from 'react'

export const X_AXIS_KEYS = ['both', 'female', 'male']
export const COLORS: any = ['#FF5050', '#41719C', '#FFB650']

export const decimalPermissionFormat = (data : any) => {
    const regExp = /[0-9]*\.0$/
    for (let i = 0; i < data.length; i++) {
        const formatPermission = !!Object.values(data[i]).find((item: any) => regExp.test(item))
        if (formatPermission) return formatPermission
    }
    return false
}

export const decimalPieChar2PermissionFormat = (data : string[][]) => {
    const regExp = /[0-9]*\.0$/
    for (let i = 0; i < data.length; i++) {
        const formatPermission = !!data[i].find((item: any) => regExp.test(item))
        if (formatPermission) return formatPermission
    }
    return false
}

export const getImage = (value: number, data: any, prop: string) => {
    if (value ===  Number(data[prop]))
        return '(=)'
    if (value <  Number(data[prop]))
        return '(➘)'
    if (value >  Number(data[prop]))
        return '(➚)'
    return ''
}

export const getSymbol = (item: any, data: any) => {
    if (item.index === 0) {
        return ''
    }
    switch (item.id) {
        case 'both':
            return getImage(item.value, data, 'prevBoth')
        case 'female':
            return getImage(item.value, data, 'prevFemale')
        case 'male':
            return getImage(item.value, data, 'prevMale')
        default:
            return ''
    }
}

export const checkCharts = (chartRef: MutableRefObject<any>) => {
    const chartValueNode = chartRef.current.querySelectorAll('svg g.c3-axis.c3-axis-y .tick')
    const chartValue: { value: any; translate: any }[] = []
    chartValueNode.forEach((item: { lastChild: { firstChild: { innerHTML: any } }; getAttribute: (arg0: string) => any }) => {
        chartValue.push({
            value: item.lastChild.firstChild.innerHTML,
            translate: item.getAttribute('transform').match(/[0-9]+/g)
        })
    })
    return chartValue
}
