import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'

const Title = styled.h1`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 34px;
  }
`

const Header = styled.div`
  background: #427B89;
  padding: 64px 0 36px;

  @media (max-width: 1023px) {
    padding: 64px 76px 36px;
  }

  @media (max-width: 767px) {
    padding: 56px 32px 32px;
  }
`
const AddInfo = styled.div`
  margin-top: 12px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const Logo = styled.div`
  background: center / contain no-repeat;
  width: 62px;
  height: 66px;
`

const WrapLogoTitle = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

type ProrType = {
    title: string,
    addInfo: string,
    imageName?: string,
    children?: React.ReactNode
}

const WrapLogo = styled.div`
  padding: 0 3px;
  background: #FFFFFF;
  display: inline-block;
  margin-right: 24px;
  border-radius: 5px;
  width: fit-content;
  margin-bottom: 8px;
  height: fit-content;
`

function TitleIndicatorSectionComponent({title, addInfo, imageName, children}: ProrType) {
    const {t} = useTranslation()

    return (
        <Header>
            <Wrapper>
                <WrapLogoTitle>
                    {imageName &&
                    <WrapLogo><Logo style={{'backgroundImage': `url(../../SVGs/${imageName}.svg)`}}/></WrapLogo>}
                    <Title>{t(title)}</Title>
                </WrapLogoTitle>
                {addInfo !== '' && <AddInfo>{t(addInfo)}</AddInfo>}
                {children}
            </Wrapper>
        </Header>

    )
}

export const TitleIndicatorSection = withTranslation()(TitleIndicatorSectionComponent)
