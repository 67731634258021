import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

import {NewsCard} from './'
import {Spinner, ErrorWrapper} from '../common'
import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {NewsModel, StateModel} from '../../models'
import {useSelector} from 'react-redux'

const Wrapper = styled.section`
  width: 100%;
  margin-top: 80px;
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 56px;
  }

  @media (max-width: 767px) {
    margin-top: 48px;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1184px;

  @media (max-width: 1239px) {
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    padding: 0 64px;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }
`

const Hat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1158px;
  margin: 0 auto;

  @media (max-width: 767px) {
    margin: 0 8px;
  }
`

const AllNews = styled(Link)`
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #3A3084;;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: #3A3084;
    font-weight: 600;
    text-decoration-line: underline;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const NewsCards = styled.div<{ isImpaired: boolean }>`
  grid-template-columns: ${(props: { isImpaired: boolean }) => props.isImpaired ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
  display: grid;
  grid-gap: 24px;
  margin-top: 35px;
  @media (max-width: 1023px) {
    grid-template-rows: none;
    grid-template-columns: 1fr
  }

  @media (max-width: 767px) {
    grid-gap: 12px;
  }
`

const WrapAllNews = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

const WrapAll = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

type NewsProps = {
    apiService: ApiService
}

function NewsComponent({apiService}: NewsProps) {
    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`


    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState<null | NewsModel[]>(null)
    useEffect(() => {
        setLoading(true)
        setError(null)
        apiService.getAllNews(language).then((res) =>
            setData(res.data.length < 3 ? res.data : res.data.filter((e: NewsModel, i: number) => i < 3))
        )
            .catch((err) => setError(err.message)).finally(() => setLoading(false))
    }, [apiService, language])

    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Content>
                <Hat>
                    <Title>{t('news.news')}</Title>
                    <WrapAllNews> <AllNews to={'/news'}>{t('news.allNews')}</AllNews> </WrapAllNews>
                    <WrapAll> <AllNews to={'/news'}>{t('news.all')}</AllNews> </WrapAll>
                </Hat>
                {loading && <Spinner/>}
                {error && <ErrorWrapper>{error}</ErrorWrapper>}
                {data &&
                <NewsCards isImpaired={isImpaired}>{
                    (data! as NewsModel[]).map((news, index) => <NewsCard key={index} card={news}/>)
                }</NewsCards>
                }
            </Content>
        </Wrapper>
    )
}

export const News = withApiService(NewsComponent)
