import React from 'react'

import {SearchResult, TitleIndicatorSection} from '../components'
import SearchLineTitle from '../components/content/SearchLineTitle'
import {useQuery} from '../hooks'

export function SearchResultContainer() {
    let query = useQuery().get('query')
    return (
        <div>
            <TitleIndicatorSection title='search.search' addInfo=''>
                <SearchLineTitle searchValue={query}/>
            </TitleIndicatorSection>
            <SearchResult searchValue={query}/>
        </div>
    )
}

