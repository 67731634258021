import {
    CHANGE_DATE_POSITION,
    CHANGE_IS_IMPAIRED,
    CHANGE_REGULAR_EXPRESSION, CHANGE_TYPE_INDICATOR,
    LOAD_INDICATOR_INITIAL_DATA, LOAD_INDICATOR_TYPE_AND_REGULAR
} from '../actions/actions'
import {IndicatorDataModel, StateModel, IndicatorDataDimensionModel} from '../../models'

const initialState: StateModel = {
    indicatorInitialData: null,
    indicatorActiveData: null,
    indicatorRegularExpression: null,
    indicatorGraph: null,
    typeOfView: null,
    isImpaired: false,
    datePosition: -1,
}

const filterActiveData = (data: IndicatorDataModel, indicatorRegularExpression: string) => {
    const {dataset} = data
    const initialRegular = indicatorRegularExpression.replace(/\./g, '.')
    const rexExp = `^\\d{4}:${initialRegular}$`
    const reg = new RegExp(rexExp)
    const newDataset = Object.fromEntries(Object.entries(dataset).filter(e => reg.test(e[0])))
    return {
        ...data,
        dataset: newDataset
    }
}

const createInitialRegular = (dimensions: IndicatorDataDimensionModel[] | null | undefined, isMap = false) => {
    if (!dimensions) {
        return '(T|F|M)'
    }
    const gender = isMap ? dimensions[1].items[0]?.id : dimensions[1].items.map((item: any) => item.id).join('|')
    const regTypes = dimensions.slice(2).map(e => isMap && e.code === 'REGION' ? `:(${e.items.map((item: any) => item?.id).join('|')})` : `:(${e.items[0]?.id})`).join('')
    return `(${gender})${regTypes}`
}

function createInitialGraph(indicator: IndicatorDataModel, typeOfView: string) {
    const nameArray = indicator.structure.dimensions
        .filter(dim => dim.code !== 'PERIOD')
        .flatMap(dim => dim.items.map(item => `${dim.code}-${item.id}`))
    const amountOfYears = typeOfView === 'columnChart' ? 5 :
        typeOfView === 'lineChart' || typeOfView === 'plotWithAreas' ? 10 : 0
    const years = indicator.structure.dimensions[0].items
        .map(e => +e.id)
        .sort((a, b) => a - b)
        .slice(-amountOfYears)
        .join('|')
    const yearsRegExp = new RegExp(`^(${years}):`)
    const graghItem = nameArray.reduce(
        (acc: any, e: string) => {
            acc[e] = false
            return acc
        }, {})
    const gragh = nameArray.reduce((acc: any, e: string) => {
        acc[e] = {...graghItem}
        return acc
    }, {})
    const dimCodeArray = indicator.structure.dimensions.map(e => e.code)
    Object.keys(indicator.dataset)
        .filter(e => indicator.dataset[e])
        .filter(e => yearsRegExp.test(e))
        .forEach(e => {
        const keys = e.split(':')
        for (let i = 1; i < indicator.structure.dimensions.length; i++) {
            for (let j = i + 1; j < indicator.structure.dimensions.length; j++) {
                gragh[`${dimCodeArray[i]}-${keys[i]}`][`${dimCodeArray[j]}-${keys[j]}`] = true
                gragh[`${dimCodeArray[j]}-${keys[j]}`][`${dimCodeArray[i]}-${keys[i]}`] = true
            }
        }
    })
    return gragh
}

function rootReducer(state: StateModel = initialState, action: any) {
    switch (action.type) {
        case LOAD_INDICATOR_INITIAL_DATA: {
            const isMap = action.data?.indicatorView === 'map' &&
                action.data?.structure?.dimensions?.map((e: any) => e.code).includes('REGION')
            const regular = (!state.indicatorInitialData && state.indicatorRegularExpression) ?
                state.indicatorRegularExpression : createInitialRegular(action.data?.structure?.dimensions, isMap)
            const firstView = action.data?.indicatorView === 'map' &&
            !action.data?.structure?.dimensions?.map((e: any) => e.code).includes('REGION') ? 'lineChart'
                : action.data?.indicatorView
            const view = (!state.indicatorInitialData && state.typeOfView) ?
                state.typeOfView : firstView
            const graph = createInitialGraph(action.data, state.typeOfView || action.data.indicatorView)
            return {
                ...state,
                typeOfView: view,
                datePosition: -1,
                indicatorRegularExpression: regular,
                indicatorInitialData: action.data,
                indicatorActiveData: filterActiveData(action.data, regular),
                indicatorGraph: graph
            }
        }

        case CHANGE_REGULAR_EXPRESSION:
            return {
                ...state,
                indicatorRegularExpression: action.reg,
                indicatorActiveData: filterActiveData((state as any).indicatorInitialData, action.reg)
            }

        case CHANGE_TYPE_INDICATOR: {
            const currentDim = state.indicatorInitialData?.structure?.dimensions
            const newRegular = createInitialRegular(currentDim, action.typeOfView === 'map')
            const graph = createInitialGraph(state.indicatorInitialData!, action.typeOfView)
            return {
                ...state,
                typeOfView: action.typeOfView,
                indicatorRegularExpression: newRegular,
                indicatorActiveData: filterActiveData((state as any).indicatorInitialData, newRegular),
                indicatorGraph: graph
            }
        }

        case LOAD_INDICATOR_TYPE_AND_REGULAR: {
            return {
                ...state,
                typeOfView: action.data.type,
                indicatorRegularExpression: action.data.reg
            }
        }

        case CHANGE_IS_IMPAIRED: {
            return {
                ...state,
                isImpaired: action.isImpaired
            }
        }

        case CHANGE_DATE_POSITION: {
            return {
                ...state,
                datePosition: action.position
            }
        }
        default:
            return state
    }
}

export default rootReducer
