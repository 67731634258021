import React, {useEffect, useState} from 'react'
import {useTranslation, withTranslation} from 'react-i18next'
import styled from 'styled-components'
import {Link, useHistory} from 'react-router-dom'

import {LinkSelectBurgerMenu} from './'

const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 24px;
`

const HeaderLink = styled(Link)`
  text-decoration: none;
  outline: none;
  margin-top: 26px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  text-transform: uppercase;

  color: #427B89;

  &:hover {
    color: black;
  }

  &:first-child {
    padding-top: 0;
  }
`

enum LinkType {
    withChildren = 'withChildren',
    home = 'home',
    search = 'search',
    normal = 'normal'
}

type LinkModel = {
    type: LinkType,
    key: string,
    to?: string,
    children?: LinkModel[]
}

type Props = {
    closeMenu: any
}

function LinksBarBurgerMenuComponent({closeMenu}: Props) {
    const {t} = useTranslation()

    const links = [
        {
            type: LinkType.normal,
            key: 'Main',
            to: '/home',
        },
        {
            type: LinkType.withChildren,
            key: 'Stat',
            to: '',
            children: [
                {
                    type: LinkType.normal,
                    key: 'Demography',
                    to: '/demography'
                },
                {
                    type: LinkType.normal,
                    key: 'Health',
                    to: '/health'
                },
                {
                    type: LinkType.normal,
                    key: 'Education',
                    to: '/education'
                },
                {
                    type: LinkType.normal,
                    key: 'Economics',
                    to: '/economics'
                },
                {
                    type: LinkType.normal,
                    key: 'Welfare',
                    to: '/welfare'
                },
                {
                    type: LinkType.normal,
                    key: 'Social',
                    to: '/social'
                },
                {
                    type: LinkType.normal,
                    key: 'Communication',
                    to: '/communication'
                },
                {
                    type: LinkType.normal,
                    key: 'Violence',
                    to: '/violence'
                },
            ]
        },
        {
            type: LinkType.normal,
            key: 'News',
            to: '/news'
        },
        {
            type: LinkType.withChildren,
            key: 'UsefulLinks',
            to: '',
            children: [
                {
                    type: LinkType.normal,
                    key: 'informationResources',
                    to: '/links/1'
                },
                {
                    type: LinkType.normal,
                    key: 'internationalOrganizations',
                    to: '/links/2'
                },
                {
                    type: LinkType.normal,
                    key: 'genderStatistics',
                    to: '/links/3'
                },
                {
                    type: LinkType.normal,
                    key: 'statisticalPublications',
                    to: '/links/4'
                }
            ]
        },
        {
            type: LinkType.withChildren,
            key: 'About',
            to: '',
            children: [{
                type: LinkType.normal,
                key: 'About',
                to: '/about'
            },
                {
                    type: LinkType.normal,
                    key: 'Contacts',
                    to: '/contacts'
                }]
        },
    ]

    const history = useHistory()
    const [pathname, setPathname] = useState('')
    useEffect(() => {
        const pathnameArray = history.location.pathname.split('/')
        setPathname(pathnameArray[1])
    }, [history.location.pathname])


    return (
        <LinksBlock>
            {links.map((link: LinkModel, index: number) => {
                    switch (link.type) {
                        case LinkType.withChildren:
                            return (<LinkSelectBurgerMenu closeMenu={closeMenu}
                                                          key={index}
                                                          title={t(`header.${link.key}`)}
                                                          pathname={pathname}
                                                          links={link.children!}/>)
                        case LinkType.normal:
                            return (<HeaderLink
                                onClick={closeMenu}
                                to={link.to!}
                                key={index}
                                style={pathname === link.key.toLowerCase() ? {'color': '#6895A1'} : {}}
                            >

                                {t(`header.${link.key}`)}
                            </HeaderLink>)
                        default:
                            return (<HeaderLink
                                onClick={closeMenu}
                                to={link.to!}
                                key={index}
                                style={pathname === link.key.toLowerCase() ? {'color': '#6895A1'} : {}}
                            >
                                {t(`header.${link.key}`)}
                            </HeaderLink>)

                    }
                }
            )}
        </LinksBlock>
    )
}

export const LinksBarBurgerMenu = withTranslation()(LinksBarBurgerMenuComponent)
