import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

const Wrap = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-bottom: 80px;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    padding-bottom: 64px;
    width: calc(100% - 128px);
  }

  @media (max-width: 767px) {
    padding-bottom: 24px;
    width: calc(100% - 48px);
  }
`

const WrapFullPage = styled.div`
  @media (max-width: 1023px) {
    padding: 0 0 96px;
  }

  @media (max-width: 767px) {
    padding: 0 0 64px;
  }
`

const Background = styled.div`
  background: #427B89;
  height: 32px;
  width: 100%;
`

const Title = styled.h2`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: 0.05em;
  color: #292C45;
  padding: 32px 0 12px;
  margin: 0;

  @media (max-width: 1023px) {
    padding: 64px 0 12px;
  }

  @media (max-width: 767px) {
    padding: 48px 0 12px;
    font-size: 32px;
    line-height: 34px;
  }
`

const Description = styled.p`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  max-width: 700px;
  margin: 0 0 24px 0;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px 0;
  }
`
const LinkHome = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #3A3084;
  text-decoration-line: none;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 148px;
  width: 100%;

  @media (max-width: 1023px) {
    margin-top: 128px;
    justify-content: center;
  }

  @media (max-width: 767px) {
    margin-top: 96px;
    justify-content: center;
  }
`

const ErrorImage = styled.img`
  height: 220px;
  width: 628px;

  @media (max-width: 767px) {
    width: 272px;
    height: 95px;
  }
`

function NotFoundContainer() {
    const {t} = useTranslation()
    return (
        <WrapFullPage>
            <Background/>
            <Wrap>
                <div>
                    <Title>{t('notFound.notFound')}</Title>
                    <Description>{t('notFound.description')}</Description>
                    <LinkHome to='/home'>{t('notFound.link')}</LinkHome>
                </div>
                <ImageWrapper>
                    <ErrorImage
                        src={'../../SVGs/404.svg'}
                        alt="404 image"
                    />
                </ImageWrapper>
            </Wrap>

        </WrapFullPage>
    )
}

export default withTranslation()(NotFoundContainer)
