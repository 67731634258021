import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Linkify from 'react-linkify'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;

  @media (max-width: 1239px) {
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    margin: 0 64px;
    width: calc(100% - 128px);
  }

  @media (max-width: 767px) {
    margin: 0 24px;
    width: calc(100% - 48px);
  }
`

const InnerWrap = styled.div`
  width: 70%;
`

const Block = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  max-width: 774px;
  margin-top: 36px;
  word-break: break-word;

  @media (max-width: 1023px) {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    max-width: 1023px;

    &:first-child {
      margin-top: 48px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    max-width: 767px;

    &:first-child {
      margin-top: 36px;
    }
  }
`

const Source = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #292C45;
  margin-top: 24px;
  margin-bottom: 64px;
  word-break: break-word;

  @media (max-width: 1023px) {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 48px;
  }
`

function SingleNews({data, language}: any) {
    const [blocks, setBlocks] = useState<null | string[]>(null)

    useEffect(()=>{

        const arr = data.text[language] ? data.text[language].split('\n') : null
        setBlocks(arr)
    }, [data, language])


    return (<>
            {data !== null &&
            <Wrapper>
                <InnerWrap>
                    {blocks && blocks.map((e: string, i: number)=><Block key={i}>{e}</Block>)
                    }
                    <Source>
                        <Linkify>{(data! as any).source[language]}</Linkify>
                    </Source>
                </InnerWrap>
            </Wrapper>}
        </>
    )
}

export default SingleNews
