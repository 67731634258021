import styled from 'styled-components'

export const StyledSelect = styled.select`
  font-size: 16px;
  font-family: sans-serif;
  padding-right: 1.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: initial;
  background-image: url("../../SVGs/down-arrow-white.svg");
  background-repeat: no-repeat, repeat;
  background-position: right .25em top 65%, 0 0;
  background-size: .7em auto, 100%;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
  outline: none;
  
  & option{
    color: black;
  }
  
  &:hover{
    color: black;
  }
`
