import React from 'react'
import {PassportTable} from '../components'
import {IndicatorPassportModel} from '../models'

type typeProps = {
    data: IndicatorPassportModel,
    indicatorName: string
}

function PassportContainer({data, indicatorName}: typeProps) {
    return (
        <PassportTable indicatorName={indicatorName} dataTable={data}/>
    )
}

export default PassportContainer
