import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {usePromiseTracker} from 'react-promise-tracker'
import ReCAPTCHA from 'react-google-recaptcha'
import {useSelector} from 'react-redux'

import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {Button} from '../controls'
import '../../App.css'
import {StateModel} from '../../models'
import {useHistory} from 'react-router-dom'

const Wrapper = styled.section<{ isImpaired: boolean }>`
  width: 100%;
  max-width: 1184px;
  margin: 27px auto 96px;
  display: flex;
  flex-direction: column;
  border: ${(props: { isImpaired: boolean }) => props.isImpaired ? '1px solid black' : 'none'};

  @media (max-width: 1239px) {
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    margin: 27px 64px 64px;
    width: calc(100% - 128px);
  }

  @media (max-width: 767px) {
    margin: 11px 24px 64px;
    width: calc(100% - 48px);
  }
`

const Title = styled.h2`
  padding: 0;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  margin: 0 0 24px;

  @media (max-width: 1023px) {
    margin: 0 0 32px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 24px;
  }
`

const Input = styled.input`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  background: #F6F6F9;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  border: none;

  @media (max-width: 767px) {
    padding: 15px 19px;
    font-size: 14px;
    line-height: 20px;
    width: calc(100% - 28px);
  }
`

const Textarea = styled.textarea`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  background: #F6F6F9;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  border: none;
  height: 180px;
  margin-top: 12px;

  @media (max-width: 767px) {
    padding: 15px 19px;
    font-size: 14px;
    line-height: 20px;
    width: calc(100% - 28px) !important;
    resize: none;
  }

  &::-webkit-resizer {
    border-width: 16px;
    border-style: solid;
    border-radius: 0 0 4px 0;
    border-color: transparent #D7D7E4 #D7D7E4 transparent;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const WrapInput = styled.div`
  width: 580px;

  @media (max-width: 1023px) {
    width: 500px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`
const WrapButton = styled.div`
  display: flex;
`

const Error = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;
  margin-left: 24px;
  align-self: center;
  display: flex;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

const Success = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #36B27E;
  margin-left: 24px;
  align-self: center;
  display: flex;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

const ErrorWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 30px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;

  @media (max-width: 767px) {
    display: none;
  }
`

const Icon = styled.div`
  margin-right: 8px;
  background-image: url("../../SVGs/warning.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const CaptchaContainer = styled.div`
  margin: 12px 0 18px;

  @media (max-width: 767px) {
    margin: 12px 0;
    transform: scale(0.8); //fix it to size compact when get key to captcha
  }
`

type NewsProps = {
    apiService: ApiService
}

type FormAnswerComment = {
    name: string,
    comment: string
}


function SubmitCommentComponent({apiService}: NewsProps) {

    const history = useHistory().location.pathname.split('/')
    const id = Number(history[2])

    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const [captchaValue, setCaptcha] = useState<null | string>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const {register, handleSubmit, errors, reset} = useForm()
    const {t} = useTranslation()
    const {promiseInProgress} = usePromiseTracker()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    const onSubmit = (formData: FormAnswerComment) => {
        if (captchaValue) {
            setLoading(true)

            const obj = {
                newsId: id, userName: formData.name, type: 'COMMENT',
                content: formData.comment, userEmail: null, failureUrl: null
            }
            return apiService.postFrontendFeedback(obj, language).then(() => {
                reset({})
                setSuccess(t('contacts.success'))
            }).catch(() => {
                setError(t('contacts.fail'))
            }).finally(() => setLoading(false))
        }
    }

    const onChange = (value: string | null) => setCaptcha(value)

    return (
        <Wrapper isImpaired={isImpaired}>
            <Title>{t('news.submitComment')}</Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <WrapInput>
                    <Input name={'name'} type={'text'} placeholder={`${t('news.inputPlaceholder')}`}
                           className={errors.name ? 'Border-error' : ''} ref={register({required: true})}/>
                    {errors.name && <ErrorWrapper>{t('news.errorInput')}</ErrorWrapper>}
                </WrapInput>
                <WrapInput>
                    <Textarea className={errors.comment ? 'Border-error' : ''}
                              placeholder={`${t('news.textareaPlaceholder')}`} name={'comment'}
                              ref={register({required: true})}/>
                    {errors.comment && <ErrorWrapper>{t('news.errorInput')}</ErrorWrapper>}
                </WrapInput>
                <CaptchaContainer>
                    <ReCAPTCHA
                        hl={i18n.language}
                        sitekey='6LfmN5kaAAAAAJbmzFVEzuXqK6IWk347y-NjUuHj'
                        onChange={(token) => onChange(token)}
                    />
                </CaptchaContainer>
                <WrapButton>
                    <Button width={'fit-content'} label={t('news.submit')} showSpinner={loading}
                            disabled={promiseInProgress}/>
                    {error &&
                    <Error><Icon style={{backgroundImage: 'url(../../SVGs/error.svg)'}}/>{t('contacts.fail')}</Error>}
                    {success &&
                    <Success><Icon style={{backgroundImage: 'url(../../SVGs/success.svg)'}}/>{t('contacts.success')}
                    </Success>}
                </WrapButton>
            </Form>
        </Wrapper>
    )
}

/*ref={recaptchaRef}*/
// onChange={onChange}

export const SubmitComment = withTranslation()(withApiService(SubmitCommentComponent))
