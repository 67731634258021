import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function CommunicationContainer(){

  return (<div>
    <TitleIndicatorSection title='communication.title' addInfo='communication.addInfo' imageName='communication'/>
    <IndicatorSubcategories keyName='communication' keyValue={7}/>
  </div>)
}
