import React from 'react'
import styled, {css} from 'styled-components'
import {useSelector} from 'react-redux'

import {FormWriteUs, TitleIndicatorSection} from '../components'
import InfoContact from '../components/content/InfoContact'
import {StateModel} from '../models'

const Wrap = styled.div`
  margin: 0 auto;
`

const MainInformation = styled.div<{ isImpaired: boolean }>`
  max-width: 1160px;
  position: relative;
  margin: 0 auto;
  ${(props: { isImpaired: boolean }) =>
          (props.isImpaired &&
                  css`display: flex;
                    flex-direction: column;
                  `)};

  @media(max-width: 1239px){
    max-width: 980px;
  }
  
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    margin: 48px 76px 96px;
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`

const WrapLine = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    height: 2px;
    left: 92px;
    top: 2640px;
    background: #D7D7E4;
    opacity: 0.5;
    margin-bottom: 64px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

export function ContactsContainer() {
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    return (
        <Wrap>
            <TitleIndicatorSection title='contacts.contacts' addInfo=''/>
            <MainInformation isImpaired={isImpaired}>
                <InfoContact/>
                <WrapLine/>
                <FormWriteUs/>
            </MainInformation>
        </Wrap>
    )
}

