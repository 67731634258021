import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

const Wrapper = styled.div`
  width: 100%;
  max-width: 774px;
  //opacity: 0.5;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  display: flex;
  overflow: hidden;
  padding: 18px 25px;
  margin-top: 24px;

  @media (max-width: 767px) {
    padding: 12px;
  }

`

const Icon = styled.div`
  background-image: url("../../SVGs/search.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 21px;
  width: 21px;

  @media (max-width: 767px) {
    height: 17px;
    width: 17px;
  }
`

const Label = styled.label`
  margin-right: 25px;
`

const Input = styled.input`
  border: none;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  outline: none;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`

type Props = {
    searchValue: string | null
}

function SearchLineTitle({searchValue}: Props) {
    const ref = useRef(null)
    const history = useHistory()

    function focusTextInput() {
        (ref! as any).current.focus()
    }

    useEffect(() => {
        (ref.current! as any).value = searchValue
    }, [searchValue])

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if(ref.current) {
                history.push(`/search?query=${(ref.current! as any).value}`)
            }
        }
    }

    useEffect(() => {
        focusTextInput()
    }, [])
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Label htmlFor='search'><Icon/></Label>
            <Input ref={ref}
                   onKeyDown={handleKeyDown}
                   placeholder={t('header.Search')}
                   id='search2'
            />
        </Wrapper>
    )
}

export default withTranslation()(SearchLineTitle)
