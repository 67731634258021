import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'

import c3 from 'c3'
import 'c3/c3.css'
import './BarChart.css'

import {useTranslation, withTranslation} from 'react-i18next'
import {ControlledCheckbox} from '../../controls'
import {
    checkCharts,
    COLORS,
    decimalPermissionFormat,
    getSymbol,
    X_AXIS_KEYS
} from '../formatHelper'
import ChartYaxis from '../ChartYaxis'
import {format} from 'd3'

const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px 32px;
  border: 1px solid #d3d3d3;
  position: relative;

  @media (max-width: 1023px) {
    border: none;
  }

  @media (max-width: 767px) {
    padding: 16px 24px;
  }
  
`

const LabelChart = styled.div<{ labelVisible: boolean }>`

  & svg {
    overflow: visible !important;
  }

  & path {
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-grid line {
    stroke-dasharray: 0;
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-grid line:hover {
    stroke-dasharray: 10 !important;
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-axis .tick line {
    stroke-width: 0.5px;
  }

  & path, & line {
    fill: none;
    stroke: #000;
  }

  display: ${(props: { labelVisible: boolean }) => props.labelVisible ? 'block' : 'none'};
`

const SimpleChart = styled.div<{ labelVisible: boolean }>`
  //padding-left: 5px;

  & svg {
    overflow: visible !important;
  }

  & path {
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-grid line {
    stroke-dasharray: 0;
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-grid line:hover {
    stroke-dasharray: 10 !important;
    stroke: rgba(215, 215, 228, 0.5) !important;
  }

  & .c3-axis .tick line {
    stroke-width: 0.5px;
  }

  & path, & line {
    fill: none;
    stroke: #000;
  }

  display: ${(props: { labelVisible: boolean }) => props.labelVisible ? 'none' : 'block'};
`

const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: baseline;
  }
`

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: default;

  @media (max-width: 767px) {
    margin-top: 14px;
  }
`

const LegendPoint = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;

  @media (max-width: 767px) {
    display: none;
  }
`

const LegendGender = styled.p`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0;
  margin-right: 15px;

  &:first-child ${LegendPoint} {
    background-color: #FFB650;
  }

  &:nth-child(2) ${LegendPoint} {
    background-color: #FF5050;
  }

  &:last-child ${LegendPoint} {
    background-color: #41719C;
  }


  @media (max-width: 767px) {
    &:first-child {
      color: #FFB650;
    }

    &:nth-child(2) {
      color: #FF5050;
    }

    &:last-child {
      color: #41719C;
    }
`

const Scrollable = styled.div`
  @media (max-width: 1239px) {
    max-width: 520px
  }

  @media (max-width: 1023px) {
    max-width: none;
  }
  overflow-x: auto;
  overflow-y: hidden;

  max-width: 673px;
  width: 100%;



  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }
`

const ChartContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
`

type Props = {
    dataJson: {
        name: string
        both?: number
        male?: number
        female?: number
        prevBoth?: number
        prevMale?: number
        prevFemale?: number
    }[]
}


const renderChart = (dataJson: any, bindTo: string, showLabel: boolean, permission: any) => {
    return c3.generate({
        bindto: bindTo,
        data: {
            json: dataJson as any,
            keys: {
                x: 'ind',
                value: X_AXIS_KEYS,
            },
            type: 'bar',
            labels: showLabel && {
                format: (v: any) => {
                    if (!v) return ''
                    if (permission) return format('.1f')(v)
                    return v
                }
            },
            names: {
                both: 'Оба пола',
                female: 'Женщины',
                male: 'Мужчины'
            },
            colors: {
                value: COLORS,
            },
        },
        size: {
            height: 438,
            width: 673
        },
        padding: {
            top: 60
        },
        axis: {
            y: {
                show: false,
            },
            x: {
                tick: {
                    format: function (ind) {
                        return dataJson[ind as number]?.name
                    }
                }
            }
        },
        legend: {
            show: false,
        },
        tooltip: {
            contents: (data) => {
                let tooltip = ''

                data.sort((a, b) => {
                    if (a.value > b.value) return -1
                    if (a.value < b.value) return 1
                    return 0
                })

                for (let item of data) {

                    const symbol = getSymbol(item, dataJson[data[0].index])

                    if (item.value || item.value === 0) {
                        tooltip += `
                            <div class="${item.id}"> 
                                <span class="bull">&bull;</span>
                                ${permission ? format('.1f')(item.value) : item.value}  ${symbol}
							</div>
                        `
                    }
                }

                return `<div class="tooltip-container">
								<p class="bold"> ${(dataJson[data[0].index] as any).name} год</p>
                ${tooltip}
              </div>`
            }
        },
        color: {
            pattern: ['#FFB650', '#FF5050', '#41719C']
        },
        grid: {
            y: {
                show: true
            },
            x: {
                show: true
            }
        }
    })
}


const renderCharts = (dataJson: any, permission: any) => {
    renderChart(dataJson, '#chart', false, permission)
    renderChart(dataJson, '#chartLabels', true, permission)
}

function BarChartComponent({dataJson}: Props) {
    const chartRef = useRef() as MutableRefObject<any>
    const scrollRef = useRef() as MutableRefObject<any>
    const [charts, setCharts] = useState<any>([])
    const [labelChartVisible, setLabelChartVisible] = useState(false)
    const changeVisible = () => {
        setLabelChartVisible(e => !e)
    }
    const {t} = useTranslation()

    useEffect(() => {
        const permission = decimalPermissionFormat(dataJson)
        renderCharts(dataJson, permission)
        setCharts(checkCharts(chartRef))

    }, [dataJson])

    useEffect(() => {
        if(scrollRef.current && scrollRef.current.scrollWidth) {
            // window.addEventListener('resize', () => {
            //     if (scrollRef.current.scrollWidth) {
            //         scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
            //     }
            // })
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        }
    }, [scrollRef])

    return (
        <Wrapper>
            <ActionBarWrapper>
                <ControlledCheckbox
                    label={t('charts.showValue')}
                    isDisable={false}
                    value={false}
                    name='change'
                    onChange={changeVisible}
                />
                {
                    dataJson.length > 0 &&
                    <Legend>
                        <LegendGender>
                            <LegendPoint/>{t('charts.bothGender')}
                        </LegendGender>
                        <LegendGender>
                            <LegendPoint/>{t('charts.female')}
                        </LegendGender>
                        <LegendGender>
                            <LegendPoint/>{t('charts.male')}
                        </LegendGender>
                    </Legend>
                }
            </ActionBarWrapper>
            <ChartContainer>
                <ChartYaxis padding={56} axisValue={charts}/>
                <Scrollable ref={scrollRef}>
                    <SimpleChart ref={chartRef} labelVisible={labelChartVisible} id="chart"/>
                    <LabelChart labelVisible={labelChartVisible} id="chartLabels" className={'barChart'}/>
                </Scrollable>
            </ChartContainer>
            
        </Wrapper>
    )

}

export const BarChart = withTranslation()(BarChartComponent)

