import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function EducationContainer() {

    return (<div>
        <TitleIndicatorSection title='education.title' addInfo='education.addInfo' imageName='education'/>
        <IndicatorSubcategories keyValue={3} keyName='education'/>
    </div>)
}
