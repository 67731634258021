import React, {useContext} from 'react'

import {ChartContext} from '../../contexts'
import {ChartService} from '../../services'

interface ComponentWithChart{
    chartService: ChartService
}

export function withChartService<T extends ComponentWithChart>(WrappedComponent: React.ComponentType<T>) {
    return function (props: any) {
        const chartService = useContext(ChartContext)
        return <WrappedComponent chartService={chartService} {...props} />
    }
}
