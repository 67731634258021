import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ErrorWrapper} from '../common'
import {Button, Checkbox} from '../controls'
import {withToastService, withApiService} from '../wrapper'
import {ApiService, ToastService} from '../../services'
import {PublishedSurveyAnswerModel, PublishedSurveyModel, PublishedSurveyOptionModel} from '../../models/Survey.model'

const CheckboxContainer = styled.div`
  margin: 22px 0 !important;
  @media (max-width: 767px) {
    margin: 16px 0 !important;
  }
`

const ButtonWrapper = styled.div`
  padding: 9px 0;
  margin-right: 24px;

  @media (max-width: 767px) {
    margin-right: 16px;
  }`

const Icon = styled.div`
  margin-right: 8px;
  background-image: url("../../SVGs/warning.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const WrapErrorWithButton = styled.div`
  display: flex;
`

const StyledTextArea = styled.textarea<{ focusOn: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid #818181;
  font-family: Sero Pro, serif;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  padding: 18px 24px;

  &:focus {
    outline: none;
  }

  ${(props: { focusOn: boolean }) => props.focusOn && (
          `
            &:focus {
            border: 2px solid #EE7B5D;
            border-radius: 4px;
            outline: none;
          }`
  )}
  &::-webkit-resizer {
    border-width: 16px;
    border-style: solid;
    border-radius: 0 0 4px 0;
    border-color: transparent #D7D7E4 #D7D7E4 transparent;
  }
`

export type FormMultiAnswer = {
    option: any,
    ownAnswer: string
}

type FormPollProps = {
    data: PublishedSurveyModel,
    apiService: ApiService,
    toastService: ToastService,
    onAnswer: (answer: PublishedSurveyModel) => void
}

const isCustomAnswerInOptions = (formOption: { [key: string]: string }, dataOptions: PublishedSurveyOptionModel[]) => {
    const optionIds = Object.keys(formOption).filter(key => formOption[key]).map(key => +key.slice(3))
    const optionIdCustomAnswer = (dataOptions || []).find((e: PublishedSurveyOptionModel) => e.isCustomAnswer)?.optionId
    return optionIdCustomAnswer && optionIds.includes(optionIdCustomAnswer)
}

function FormPollMultiAnswerComponent({data, apiService, toastService, onAnswer}: FormPollProps) {
    const {register, handleSubmit, errors, reset, getValues} = useForm()
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const customFieldRef = useRef<HTMLTextAreaElement | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [isCustomActive, setIsCustomActive] = useState(false)

    const isOwnValid = (ownAnswer: string) => {
        const options = getValues().option
        if (!isCustomAnswerInOptions(options, data.questions[0].options)) {
            return true
        }
        return ownAnswer.length > 0
    }

    const onSubmit = (formData: FormMultiAnswer) => {
        setLoading(true)
        setError(false)
        const {option, ownAnswer} = formData
        const sendObj: PublishedSurveyAnswerModel = {
            surveyId: data.surveyId,
            questionId: data.questions[0]?.questionId,
            answer: Object.keys(option).filter(key => option[key]).map(key => +key.slice(3))
        }

        if (isCustomAnswerInOptions(option, data.questions[0].options)) {
            sendObj.answerText = ownAnswer
        }

        return apiService.postPoll(sendObj, i18n.language)
            .then((res) => {
                toastService.showDark(t('poll.result'))
                onAnswer(res.data)
                reset(formData)
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {data.questions[0]?.options.map((option: PublishedSurveyOptionModel) => (
                <React.Fragment key={option.optionId}>
                    <CheckboxContainer onClick={() => {
                        const options = getValues().option
                        const checked = isCustomAnswerInOptions(options, data.questions[0].options)
                        if (checked) customFieldRef.current?.focus()
                        setIsCustomActive(!!checked)
                    }}>
                        <Checkbox
                            label={(option as any)?.optionText?.[language] || ''}
                            value={false}
                            name={`option[id_${option.optionId}]`}
                            register={register}
                        />
                    </CheckboxContainer>
                    {
                        option?.isCustomAnswer &&
                        <>
                            <StyledTextArea
                                name={'ownAnswer'}
                                readOnly={!isCustomActive}
                                focusOn={isCustomActive}
                                placeholder={t('poll.customAnswer')}
                                ref={(e) => {
                                    register(e, {
                                        validate: isOwnValid
                                    })
                                    customFieldRef.current = e
                                }}
                            />
                            {
                                errors.ownAnswer && <ErrorWrapper>{t('poll.errorInput')}</ErrorWrapper>
                            }
                        </>
                    }
                </React.Fragment>
            ))}
            <WrapErrorWithButton>
                <ButtonWrapper>
                    <Button label={t('poll.reply')} showSpinner={loading} disabled={loading}/>
                </ButtonWrapper>
                {errors.choice && <ErrorWrapper><Icon/>{t('poll.error')}</ErrorWrapper>}
                {error && <ErrorWrapper><Icon/>{t('poll.errorSave')}</ErrorWrapper>}
            </WrapErrorWithButton>
        </form>
    )
}

export const FormPollMultiAnswer = withToastService(withApiService(FormPollMultiAnswerComponent))
