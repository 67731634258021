import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

import {Spinner, ErrorWrapper} from '../common'
import {withApiService} from '../wrapper'
import {FormPoll, FormPollMultiAnswer} from '../forms'
import {useData} from '../../hooks'
import {ApiService} from '../../services'
import {PublishedSurveyModel} from '../../models/Survey.model'
import {PollResult} from './PollResult'

const Wrapper = styled.section`
  width: 100%;
  margin-top: 64px;
  display: flex;
  background: #F6F6F9;

  @media (max-width: 1023px) {
    justify-content: space-between;
  }
`

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1023px) {
    padding: 0 64px;
    width: auto;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
    width: auto;
  }
`

const RightSide = styled.div`
  width: 50%;
  background-image: url("../../SVGs/poll.svg");
  background-repeat: repeat;

  @media (max-width: 1023px) {
    width: 80px;
    background-image: url("../../SVGs/background-poll-768px.svg");
    background-repeat: no-repeat;
    margin: 27px 16px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 592px;
  margin-bottom: 40px;
  @media (max-width: 1239px) {
    padding-left: 32px;
  }
`

const Hat = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const Title = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  padding-top: 49px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const Line = styled.div`
  width: 96px;
  height: 2px;
  background: rgba(71, 154, 230, 0.3);
  margin-top: 12px;
`
const Question = styled.div`
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-top: 16px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const FormWrapper = styled.div`
`

type PollProps = {
    formTypeDeleteMe: string,
    apiService: ApiService
}

function PollComponent({apiService}: PollProps) {
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const getPollData =
        useCallback(
            () => apiService.getPoll(i18n.language),
            [apiService, i18n.language]
        )
    const state = useData(getPollData)
    const [answer, setAnswer] = useState<PublishedSurveyModel | null>(null)

    return (
        <Wrapper>
            <LeftSide>
                <Content>
                    <Hat>
                        <Title>{t('poll.poll')}</Title>
                        <Line/>
                    </Hat>
                    {state.loading && <Spinner/>}
                    {state.error && <ErrorWrapper>{state.error}</ErrorWrapper>}
                    {state.data && !answer && (state.data! as PublishedSurveyModel).questions?.[0] && (
                        <FormWrapper>
                            <Question>{(state.data! as any).questions[0]?.questionText?.[language] || ''}</Question>
                            {(state.data! as any).questions[0].optionType === 'RADIO' &&
                            <FormPoll data={state.data}
                                      onAnswer={(answer: PublishedSurveyModel) => setAnswer(answer)}/>}
                            {
                                (state.data! as any).questions[0].optionType === 'CHECK' &&
                                <FormPollMultiAnswer
                                    data={state.data}
                                    onAnswer={(answer: PublishedSurveyModel) => setAnswer(answer)}
                                />
                            }
                        </FormWrapper>
                    )}
                    {state.data && answer &&
                    <React.Fragment>
                        <Question>{(state.data! as any).questions?.[0]?.questionText?.[language] || ''}</Question>
                        <PollResult data={answer}/>
                    </React.Fragment>
                    }
                </Content>
            </LeftSide>
            <RightSide/>
        </Wrapper>
    )
}

export const Poll = withApiService(PollComponent)
