import React from 'react'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'
import {ReactSVG} from 'react-svg'

const AddedInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 24px 0 0;
  width: 284px;

  @media (max-width: 1023px) {
    width: calc(100% - 128px);
    padding: 48px 64px;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    width: calc(100% - 48px);
    align-items: center;
    padding: 52px 24px 64px;
    margin: 0;
    flex-direction: column;
  }
`

const BlockAddedInfo = styled.div`
  display: flex;
  margin-top: 24px;

  @media (max-width: 1023px) {
    margin: 24px 70px 24px 0;
    width: 268px;
  }

  @media (max-width: 767px) {
    margin: 24px 70px 24px 0;
    width: 244px;
  }
`

const Formula = styled.span<{ color: string }>`
  font-weight: 600;
  color: ${(props: { color: string }) => props.color};
  font-size: 16px;
`

const Text = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292C45;
  margin-left: 12px;
`

const Year = styled.span`
  font-weight: 600;
  color: #292C45;
  font-size: 16px;
`

const genderStyle = {
    'GENERAL': {
        color: '#FFB650',
        svgSrc: '../../SVGs/both-gender.svg'
    },
    'MALE': {
        color: '#41719c',
        svgSrc: '../../SVGs/male.svg'
    },
    'FEMALE': {
        color: '#FF6B6B',
        svgSrc: '../../SVGs/female.svg'
    }
}

type Props = {
    conclusions: any
}

function styleText(conclusion: any, type: string) {
    return conclusion.map((e: any, index: number) => {
        switch (e.type) {
            case 'TEXT':
                return e.value
            case 'DATA':
                return <Year key={index}>{e.value}</Year>
            case 'FORMULA':
                return <Formula key={index}
                                color={(genderStyle as any)[type].color}>
                    {Number(e.value).toFixed(1).replace('.', ',')}
                </Formula>
            default:
                return e.value
        }
    })
}

function BlockAnalysisComponent({conclusions}: Props) {

    return (
        <AddedInfo>
            {
                ['GENERAL', 'FEMALE', 'MALE']
                    .filter(key => conclusions[key])
                    .map(
                        (key: string, index: number) => <BlockAddedInfo key={index}>
                            <ReactSVG src={(genderStyle as any)[key]?.svgSrc}/>
                            <Text>
                                {styleText(conclusions[key], key)}
                            </Text>
                        </BlockAddedInfo>
                    )
            }
        </AddedInfo>
    )
}

export const BlockAnalysis = withTranslation()(BlockAnalysisComponent)
