import React from 'react'
import styled from 'styled-components'

import {TitleIndicatorSection} from '../components'
import AboutPortal from '../components/content/AboutPortal'

const AboutWrapper = styled.div`
  margin-bottom: 96px;

  @media (max-width: 767px) {
    margin-bottom: 64px;
  }
`

export function AboutContainer() {
    return (
        <AboutWrapper>
            <TitleIndicatorSection title='about.title' addInfo=''/>
            <AboutPortal/>
        </AboutWrapper>
    )
}
