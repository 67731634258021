import React from 'react'
import styled from 'styled-components'

import {useComponentVisible} from '../../hooks'

const SelectWrapper = styled.div`
  position: relative;
`

const SelectTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  font-family: sans-serif;
  padding-right: calc(1.8em + 32px);
  background-color: initial;
  background-image: url("../../SVGs/down-arrow-white.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 2.5em top 50%, 0 0;
  background-size: .7em auto, 100%;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 20px;

  &:hover {
    color: black;
  }
`

const DropDown = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(41, 44, 69, 0.12);
  border-radius: 8px;
  z-index: 2;
  padding: 14px 6px;
`

const SelectOption = styled.div`
  font-family: Sero Pro, serif;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  cursor: pointer;

  padding: 4px 18px;

  &:hover {
    background: #F3F3F7;
    border-radius: 2px;
  }
`

type Props = {
    selected: string,
    options: { code: string; name: string; }[],
    onSelected: (language: string) => void
}

export function LanguageSelect({selected, options, onSelected}: Props) {
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)

    const handleOpenChanged = () => setIsComponentVisible(!isComponentVisible)
    const language = options.find(e => e.code === selected)

    return (
        <SelectWrapper ref={ref}>
            <SelectTitle onClick={handleOpenChanged}>{language?.name}</SelectTitle>
            {isComponentVisible && (
                <DropDown>
                    {
                        options.map(
                            (e, i) => <SelectOption
                                key={i}
                                onClick={() => {
                                    onSelected(e.code)
                                    setIsComponentVisible(false)
                                }}>
                                {e.name}
                            </SelectOption>
                        )
                    }
                </DropDown>
            )}
        </SelectWrapper>
    )
}

