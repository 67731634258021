import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {NavigationBar768px} from './NavigationBar768px'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  display: flex;
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const NameBlock = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  margin-top: 64px;

  @media (max-width: 1023px) {
    margin-top: 56px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    margin-top: 48px;
  }
`

const Block = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  max-width: 774px;
  margin-top: 36px;

  ${NameBlock} + & {
    margin-top: 24px;
  }
  
  @media (max-width: 1023px) {
    max-width: 1160px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;

    ${NameBlock} + & {
      margin-top: 20px;
    }
    
    &:first-child {
      margin-top: 48px;
    }

    @media(max-width: 1239px){
      max-width: 980px;
    }
  }

  @media (max-width: 767px) {
    max-width: 1160px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;

    ${NameBlock} + & {
      margin-top: 16px;
    }

    &:first-child {
      margin-top: 36px;
    }
  }

  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 64px;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 24px;
  }
`

const RightColumn = styled.div`
  width: 30%;
  background: #F7F7FA;
  border-radius: 12px;
  margin: 36px 0;
  height: fit-content;
  padding-bottom: 28px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const TitleMenuColumn = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  margin: 36px 24px 18px;
`

function AboutPortal() {
    const {t} = useTranslation()
    return (
        <Wrapper>
            <LeftColumn>
                <Block>{t('about.firstBlock')}</Block>
                <Block>{t('about.secondBlock')}</Block>
                <NameBlock>{t('about.genderEquality')}</NameBlock>
                <Block>{t('about.firstBlockGenderEquality')}</Block>
                <Block>{t('about.secondBlockGenderEquality')}</Block>
                <NameBlock>{t('about.GenderStatistics')}</NameBlock>
                <Block>{t('about.firstBlockGenderStatistics')}</Block>
                <Block>{t('about.secondBlockGenderStatistics')}</Block>
                <NameBlock>{t('about.WebPortal')}</NameBlock>
                <Block>{t('about.firstBlockWebPortal')}</Block>
                <Block>{t('about.secondBlockWebPortal')}</Block>
            </LeftColumn>
            <RightColumn>
                <TitleMenuColumn>{t('header.Stat')}</TitleMenuColumn>
                <NavigationBar768px style={{
                    'gap': '8px',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'margin': '0 24px'
                }}/>
            </RightColumn>
        </Wrapper>
    )
}

export default withTranslation()(AboutPortal)
