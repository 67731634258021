import {IndicatorDataModel} from '../../models'

export const LOAD_INDICATOR_INITIAL_DATA = 'Load indicator initial data'
export const LOAD_INDICATOR_TYPE_AND_REGULAR = 'Load indicator type and regular'
export const CHANGE_REGULAR_EXPRESSION = 'Change regular expression'
export const CHANGE_TYPE_INDICATOR = 'Change type indicator'
export const CHANGE_IS_IMPAIRED = 'Change impaired'
export const CHANGE_DATE_POSITION = 'Change Date position'

export function addIndicatorInitialData(data: IndicatorDataModel | null) {
    return {type: LOAD_INDICATOR_INITIAL_DATA, data}
}

export function changeRegularExpression(reg: string) {
    return {type: CHANGE_REGULAR_EXPRESSION, reg}
}

export function changeTypeIndicator(typeOfView: string) {
    return {type: CHANGE_TYPE_INDICATOR, typeOfView}
}

export function loadIndicatorTypeAndRegular(data: { type: string, reg: string }) {
    return {type: LOAD_INDICATOR_TYPE_AND_REGULAR, data}
}

export function changeIsImpaired(isImpaired: boolean) {
    return {type: CHANGE_IS_IMPAIRED, isImpaired}
}


export function changeDatePosition(position: number) {
    return {type: CHANGE_DATE_POSITION, position}
}
