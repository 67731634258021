import React from 'react'
import styled from 'styled-components'
import Highlighter from 'react-highlight-words'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {SearchItemModel} from '../../models/Search.model'

type Props = {
    searchValue: string
    searchItem: SearchItemModel
}

const Title = styled.h2`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  margin: 0 0 24px 0;
  word-break: break-word;
  cursor: pointer;

  @media (max-width: 1023px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }
`

const links = [
    {
        id: 1,
        to: '/demography'
    },
    {
        id: 2,
        to: '/health'
    },
    {
        id: 3,
        to: '/education'
    },
    {
        id: 4,
        to: '/economics'
    },
    {
        id: 5,
        to: '/welfare'
    },
    {
        id: 6,
        to: '/social'
    },
    {
        id: 7,
        to: '/communication'
    },
    {
        id: 8,
        to: '/violence'
    },
]

export function SearchResultItem({searchItem, searchValue}: Props) {

    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const history = useHistory()
    return (
            <Title onClick={() => history.push(`${links.find(e => e.id === searchItem?.categoryId)?.to}/${searchItem.indicatorId}`)}>
                <Highlighter
                    highlightStyle={{'backgroundColor': '#FDF1D2'}}
                    searchWords={[searchValue]}
                    autoEscape={true}
                    textToHighlight={(searchItem.name as any)[language]}
                />
            </Title>
    )
}
