import React from 'react'

import {Header, Footer} from '../common'

export function WithHeaderAndFooter(props: { children: React.ReactNode }) {
    const {children} = props
    return (
        <div>
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}
