import axios from 'axios'
import config from '../enviroment'
import {DownloadedIndicator, IndicatorDataModel, NewsModel, TableDataToDownload} from '../models'
import {FeedbackPost} from '../models'
import {PublishedSurveyAnswerModel, PublishedSurveyModel, SearchItemModel} from '../models'
import {PublishedLinkModel} from '../models'

const instance = axios.create({
    baseURL: config.BASE_URL
})

export class ApiService {

    getAllNews = (language: string) =>
        instance.get<NewsModel[]>('content/frontend/news',
            {
                params: {
                    'Accept-Language': language
                }
            })

    getPoll = (language: string) =>
        instance.get<PublishedSurveyModel>('/content/frontend/survey',
            {
                params: {
                    'Accept-Language': language
                }
            })

    postPoll = (answer: PublishedSurveyAnswerModel, language: string) =>
        instance.post<PublishedSurveyModel>(
            '/content/frontend/survey',
            answer,
            {
                params: {
                    'Accept-Language': language
                }
            })

    getSubcategories = (categoryId: number, language: string) =>
        instance.get<IndicatorDataModel>(`/data/frontend/indicators/${categoryId}`,
            {
                params: {
                    'Accept-Language': language
                }
            })

    getIndicatorData = (indicatorId: string, language: string) =>
        instance.get<IndicatorDataModel>(`/data/frontend/datasets/${indicatorId}`,
            {
                params: {
                    'Accept-Language': language
                }
            })


    downloadIndicatorData = (indicatorId: string, type: string, language: string) =>
        instance.get<DownloadedIndicator>(`/data/frontend/datasets/${indicatorId}/data`,
            {
                params: {
                    'type': type,
                    'Accept-Language': language
                }
            })

    downloadIndicatorTableData = (sendData: TableDataToDownload, indicatorId: string, type: string, language: string) =>
        instance.post<DownloadedIndicator>('/data/frontend/exportData',
            sendData,
            {
                params: {
                    'dsCode': indicatorId,
                    'type': type,
                    'Accept-Language': language
                }
            }
        )

    downloadFile = (data: string, mimeType: string, fileName: string, fileFormat: string) => {
        const url = `data:${mimeType};base64,${data}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileFormat ? `${fileName}.${fileFormat}` : fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    getSingleNews = (newsId: number, language: string) => instance.get(`content/frontend/news/${newsId}`,
        {
            params: {
                'Accept-Language': language
            }
        })

    getSearchResult = (searchValue: string, language: string) => instance.get<SearchItemModel[]>('data/frontend/indicatorsSearch',
        {
            params: {
                filter: searchValue,
                'Accept-Language': language
            }
        })

    postFrontendFeedback = (body: FeedbackPost, language: string) => instance.post('content/frontend/feedback', body,
        {
            params: {
                'Accept-Language': language
            }
        })

    getLinkById = (id: string, language: string) => instance.get<PublishedLinkModel>(`content/frontend/links/${id}`,
        {
            params: {
                'Accept-Language': language
            }
        })
}
