import styled from 'styled-components'

export const FooterLine = styled.div`
  width: 87%;
  height: 2px;
  left: 92px;
  top: 2640px;
  background: #D7D7E4;
  opacity: 0.5;
  margin-bottom: 37px;
`
