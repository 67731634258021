import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

import {NewsModel} from '../../models'
import {useTranslation} from 'react-i18next'
import moment from 'moment'

const CardWrapper = styled(Link)`
  border: 1px solid rgba(41, 44, 69, .2);
  box-sizing: border-box;
  border-radius: 12px;
  height: 242px;
  display: flex;
  flex-direction: column;
  color: #292C45;
  text-decoration: none;
  position: relative;
  top: 0;

  &:hover {
    color: black;
    border: 1px solid rgba(41, 44, 69, 1);
    top: -10px;
  }

  transition: all .2s;

  @media (max-width: 1023px) {
    height: fit-content;
  }
`

const CardContent = styled.div`
  width: 100%;
  height: 184px;
  padding: 32px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;

  @media (max-width: 1023px) {
    height: auto;
    padding: 32px 32px 24px;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 24px;
    font-size: 14px;
    line-height: 20px;
  }
`

const Line = styled.div`
  width: 144px;
  height: 2px;
  background: rgba(71, 154, 230, 0.3);
  margin-left: 32px;
`

const Date = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  margin: 12px 32px 24px;
`

type NewsCardProps = {
    card: NewsModel
}

export function NewsCard({card}: NewsCardProps) {
    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`

    const getDate = (date: string | Date) => {
        const momentDate = moment(date)
        if (momentDate.isValid()) {
            return momentDate.format('DD.MM.YYYY')
        }
        return ''
    }

    return (
        <CardWrapper to={`/news/${card.newsId}`}>
            <CardContent>{(card! as any).title[language]}</CardContent>
            <Line/>
            <Date>{getDate(card.publicationDate)}</Date>
        </CardWrapper>
    )
}
