import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {LinkModel} from './'
import {useComponentVisible} from '../../hooks'

const LinkWrapper = styled.div`
  position: relative;
`

const LinkTitle = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #427B89;
  font-family: sans-serif;
  padding-right: calc(1.8em + 32px);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: initial;
  background-image: url("../../SVGs/down-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 2.5em top 50%, 0 0;
  background-size: .7em auto, 100%;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 32px;
  border-right: 1px solid #427B89;

  &:hover {
    color: black;
  }
`

const MoreLinks = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  border: 1px solid lightgray;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgb(41 44 69 / 12%);
  border-radius: 8px;
  z-index: 2;
  padding: 24px;
`

const HeaderLink = styled.div`
  display: block;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #292C45;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    text-decoration-line: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const LinkA = styled.a`
  display: block;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #292C45;
  cursor: pointer;
  margin-bottom: 8px;
  text-decoration-line: none;

  &:hover {
    text-decoration-line: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

type LinkSelectProps = {
    title: string,
    links: LinkModel[],
    pathname: any
}

function LinkSelectComponent(props: LinkSelectProps) {
    const {t} = useTranslation()
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false)
    const history = useHistory()

    const goTo = (path: string) => {
        history.push(path)
        setIsComponentVisible(false)
    }

    const handleOpenChanged = () => setIsComponentVisible(!isComponentVisible)

    const {title, links, pathname} = props

    const [val, setIsActive] = useState(false)
    useEffect(() => {
        const val = !!links.find((item: any) => pathname === item.key.toLowerCase())
        setIsComponentVisible(false)
        setIsActive(val)
    }, [pathname, links, setIsComponentVisible])
    return (
        <LinkWrapper ref={ref}>
            <LinkTitle onClick={handleOpenChanged} style={val ? {'color': '#6895A1'} : {}}>{title}</LinkTitle>
            {isComponentVisible && (
                <MoreLinks>
                    {links.map((child: LinkModel, index: number) => (
                            <React.Fragment key={index}>
                                {child.to && <HeaderLink onClick={() => goTo(child.to!)}
                                                         style={pathname === child.key.toLowerCase() ? {
                                                             'textDecorationLine': 'underline',
                                                             'color': '#292C45',
                                                             'opacity': '0.7'
                                                         } : {}}>
                                    {t(`header.${child.key}`)}
                                </HeaderLink>}
                                {child.href &&
                                <LinkA
                                    href={child.href!} target="_blank">
                                    {t(`header.${child.key}`)}
                                </LinkA>}
                            </React.Fragment>
                        )
                    )}
                </MoreLinks>
            )}
        </LinkWrapper>
    )
}

export const LinkSelect = withTranslation()(LinkSelectComponent)
