import React, {useEffect, useState} from 'react'
import {useTranslation, withTranslation} from 'react-i18next'
import styled, {css} from 'styled-components'
import {Link, useLocation} from 'react-router-dom'
import {ReactSVG} from 'react-svg'

import {LinkSelect} from './'
import SearchLine from '../content/SearchLine'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'

const LinksBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  letter-spacing: -0.5px;


  ${(props: { isImpaired: boolean }) =>
          props.isImpaired &&
          css`align-items: stretch;
          `};
`

const HeaderLink = styled(Link)`
  font-family: sans-serif;
  display: block;
  color: #427B89;
  text-decoration: none;
  padding: 0 32px;
  border-right: 1px solid #427B89;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 30px;
  outline: none;

  &:hover {
    color: black;
  }

  &:nth-of-type(1) {
    padding-left: 0;
  }

  @media(max-width: 1239px){
    padding: 0 15px;
  }
`

const Icon = styled(ReactSVG)`
  width: 24px;
  height: 24px;

  &:hover path {
    fill: black;
  }
`

const Search = styled.div`
  padding: 0 32px;
  outline: none;
  cursor: pointer;
`

enum LinkType {
    withChildren = 'withChildren',
    home = 'home',
    search = 'search',
    normal = 'normal'
}

export type LinkModel = {
    type: LinkType,
    key: string,
    to?: string,
    children?: LinkModel[],
    href?: string
}

type Props = {
    isComponentVisible: any,
    setIsComponentVisible: any
}

function LinksBarComponent({isComponentVisible, setIsComponentVisible}: Props) {
    const {t} = useTranslation()
    const history = useLocation()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const [pathname, setPathname] = useState('')
    useEffect(() => {
        const pathnameArray = history.pathname.split('/')
        setPathname(pathnameArray[1])
    }, [history])


    const links = [
        {
            type: LinkType.home,
            key: '',
            to: '/home',
        },
        {
            type: LinkType.withChildren,
            key: 'Stat',
            to: '',
            children: [
                {
                    type: LinkType.normal,
                    key: 'Demography',
                    to: '/demography'
                },
                {
                    type: LinkType.normal,
                    key: 'Health',
                    to: '/health'
                },
                {
                    type: LinkType.normal,
                    key: 'Education',
                    to: '/education'
                },
                {
                    type: LinkType.normal,
                    key: 'Economics',
                    to: '/economics'
                },
                {
                    type: LinkType.normal,
                    key: 'Welfare',
                    to: '/welfare'
                },
                {
                    type: LinkType.normal,
                    key: 'Social',
                    to: '/social'
                },
                {
                    type: LinkType.normal,
                    key: 'Communication',
                    to: '/communication'
                },
                {
                    type: LinkType.normal,
                    key: 'Violence',
                    to: '/violence'
                },
            ]
        },
        {
            type: LinkType.normal,
            key: 'News',
            to: '/news'
        },
        {
            type: LinkType.withChildren,
            key: 'UsefulLinks',
            to: '',
            children: [
                {
                    type: LinkType.normal,
                    key: 'informationResources',
                    to: '/links/1'
                },
                {
                    type: LinkType.normal,
                    key: 'internationalOrganizations',
                    to: '/links/2'
                },
                {
                    type: LinkType.normal,
                    key: 'genderStatistics',
                    to: '/links/3'
                },
                {
                    type: LinkType.normal,
                    key: 'statisticalPublications',
                    to: '/links/4'
                }
            ]
        },
        {
            type: LinkType.withChildren,
            key: 'About',
            to: '',
            children: [{
                type: LinkType.normal,
                key: 'About',
                to: '/about'
            },
                {
                    type: LinkType.normal,
                    key: 'Contacts',
                    to: '/contacts'
                }]
        },
        {
            type: LinkType.search,
            key: '',
        }
    ]

    return (
        <LinksBlock isImpaired={isImpaired}>
            {links.map((link: LinkModel, index: number) => {
                    switch (link.type) {
                        case LinkType.home:
                            return (<HeaderLink
                                    to={link.to!}
                                    key={index}
                                >
                                    <Icon src="../../SVGs/home.svg"/>
                                </HeaderLink>
                            )
                        case LinkType.withChildren:
                            return (<LinkSelect
                                pathname={pathname}
                                key={index}
                                title={t(`header.${link.key}`)}
                                links={link.children!}/>)
                        case LinkType.normal:
                            return (<HeaderLink
                                to={link.to!}
                                key={index}
                                style={pathname === link.key.toLowerCase() ? {'color': '#6895A1'} : {}}
                            >
                                {t(`header.${link.key}`)}
                            </HeaderLink>)
                        case LinkType.search:
                            return (<Search onClick={setIsComponentVisible} key={index}>
                                <Icon src="../../SVGs/search.svg"/>
                            </Search>)
                        default:
                            return (<HeaderLink
                                to={link.to!}
                                key={index}
                                style={pathname === link.key.toLowerCase() ? {'color': '#6895A1'} : {}}
                            >
                                {t(`header.${link.key}`)}
                            </HeaderLink>)

                    }
                }
            )}
            {isComponentVisible && <SearchLine closeSearch={setIsComponentVisible}/>}
        </LinksBlock>
    )
}

export const LinksBar = withTranslation()(LinksBarComponent)
