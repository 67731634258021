import React, {useState} from 'react'
import styled from 'styled-components'

const RadioContainer = styled.div`
  display: block;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`


const Radio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  width: 16px;
  height: 100%;
  z-index: 1;
`

const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  padding-left: 30px;
  
  &.active {
    text-decoration: underline;
  }

  &:hover {
    color: black;
    text-decoration: underline;
  }

  ${Radio}:checked ~ & {
    color: black;
    text-decoration: underline;
  }
`


const CheckMark = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #3A3084;
  
  

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3A3084;
    

    ${Radio}:checked ~ & {
      display: block;
    }
  }
`

type RadioButtonProps = {
  name?: string,
  value?: number | string,
  label?: string,
  register?: any,
  className?: string,
}

export function RadioButton({name, value, label, register, className}: RadioButtonProps){


  return       (
     <RadioContainer className={className}>
         <Radio type="radio" id={`${value}${name}`} name={name} value={value}  ref={register}/>
         <Label htmlFor={`${value}${name}`}>{label}</Label>
         <CheckMark/>
     </RadioContainer>
  )
}
