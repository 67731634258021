import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {ReactSVG} from 'react-svg'

import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {Spinner} from './Spinner'
import {useTranslation} from 'react-i18next'


const Title = styled.h6`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #292C45;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 0;
  cursor: pointer;
  
  &:hover, &.activate {
    text-decoration: underline;
  }
  

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const Subcategory = styled(Link)`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-decoration-line: none;
  color: #292C45;
  cursor: pointer;
  display: block;
  margin: 8px 0 15px;
  width: fit-content;

  &:hover {
    color: #292C45;
    opacity: 0.7;
    text-decoration-line: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`

const Wrapper = styled.div`
  padding: 63px 0 48px;
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    padding: 66px 64px;
    width: auto;
  }

  @media (max-width: 767px) {
    padding: 48px 32px 66px;
  }
`

const WrapBlock = styled.div`
  margin-bottom: 24px;
`

const Icon = styled(ReactSVG)`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: bottom;
`

type typeProps = {
    apiService: ApiService,
    keyValue: number,
    keyName: string
}

function IndicatorSubcategoriesComponent({apiService, keyValue, keyName}: typeProps) {
    const {i18n} = useTranslation()

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [isOneLevel, setIsOneLevel] = useState(false)

    useEffect(() => {
            setLoading(true)
            apiService.getSubcategories(keyValue, i18n.language)
                .then((data) => {
                    setData(data.data as any)
                    checkIsOneLevel(data.data)
                })
                .catch((err) => setError(err))
                .finally(() => setLoading(false))
        }, [i18n.language, apiService, keyValue]
    )

    const checkIsOneLevel = (data: any) => {
        setIsOneLevel(!!data.children.every((item: any) => item.children.length === 0))
    }


    const [showFullCategories, setShowFullCategories] = useState([false])

    const setNewStatus = (i: number) => {
        if (showFullCategories[i]) {
            setShowFullCategories([])
        } else {
            const newArray = []
            newArray[i] = true
            setShowFullCategories(newArray)
        }
    }

    return (<div>
            {loading && <Spinner/>}
            {error && <p>{error}</p>}
            {data && !isOneLevel && <Wrapper>
                { (data! as any).children.map((item: any, i: number) =>
                    <WrapBlock key={i}>
                        <Title className={showFullCategories[i] ? 'activate': ''} onClick={() => setNewStatus(i)}>
                            <Icon
                                src={showFullCategories[i] ? '../../SVGs/minus.svg' : '../../SVGs/plus.svg'}/>
                            {item.name[`lang_${i18n.language}`]}
                        </Title>
                        {showFullCategories[i] && item.children.map((val: any, index: number) =>
                            <Subcategory to={`/${keyName}/${val.indicatorId}`}
                                         key={index}>{val.name[`lang_${i18n.language}`]}</Subcategory>
                        )
                        }
                    </WrapBlock>
                )
                }
            </Wrapper>}
            {data && isOneLevel && <Wrapper>
                {(data! as any).children.map((item: any, i: number) =>
                    <Subcategory key={i}
                                 to={`/${keyName}/${item.indicatorId}`}>{item.name[`lang_${i18n.language}`]}</Subcategory>
                )
                }
            </Wrapper>}
        </div>
    )
}

export const IndicatorSubcategories = withApiService(IndicatorSubcategoriesComponent)
