import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {StateModel} from '../../models'
import {useQuery} from '../../hooks'
import {changeRegularExpression} from '../../store/actions/actions'

const WrapperOprions = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Icon = styled.div`
  margin-left: 7.33px;
  background-image: url("../../SVGs/close.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  text-transform: uppercase;
  width: 9.33px;
  height: 9.33px;
  display: inline-block;
`

const Option = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background: #F3F3F7;
  border-radius: 4px;
  padding: 6px 16px;
  margin: 0 6px 8px;
`

function SelectedOptionsComponent() {
    const {i18n} = useTranslation()

    const data = useSelector((state: StateModel) =>
        state.indicatorInitialData)

    const reg = useSelector((state: StateModel) =>
        state.indicatorRegularExpression)

    const [disArray, setDisArray] = useState([])
    const [gender, setGender] = useState(null)
    const [others, setOthers] = useState(null)
    const [isMap, setIsMap] = useState(false)

    const [selectedGender, setSelectedGender] = useState(null)
    const [selectedOthers, setSelectedOthers] = useState(null)

    const query = useQuery()
    const dispatch = useDispatch()
    const chartType = query.get('type')

    useEffect(() => {
        if (reg) {
            const regArray = reg.replace(/\|/g, '/').split(':') as any
            setDisArray(regArray)
            if (chartType !== 'map') {
                setIsMap(chartType === 'map')
                setSelectedOthers(regArray.slice(1))
                const checkBoxArray = regArray[0].replace('(', '').replace(')', '').split('/')
                setSelectedGender(checkBoxArray.length === 1 && checkBoxArray[0] === '' ? [] : checkBoxArray)
            } else {
                const indexRegion = others && (others as any).findIndex((item: any) => item.code === 'REGION')
                const selectedOthersArray = regArray.slice(1).map((item: any, i: number) => {
                    if (indexRegion === i) {
                        const newOptionArray = item.replace('(', '').replace(')', '').split('/')
                        return newOptionArray.length === 1 && newOptionArray[0] === '' ? [] : newOptionArray
                    } else {
                        return item.replace('(', '').replace(')', '')
                    }
                })
                setIsMap(chartType === 'map')
                setSelectedOthers(selectedOthersArray)
                setSelectedGender(regArray[0].replace('(', '').replace(')', ''))

            }
        }
    }, [chartType, reg, others])

    useEffect(() => {
        if (data && reg) {
            const neededData = data?.structure.dimensions
                .filter((item: any) => item.code !== 'PERIOD')
            const gender = (neededData as any).find((item: any) => item.code === 'SEX')
            setGender(gender as any)
            const others = (neededData as any).filter((item: any) => item.code !== 'SEX')
            setOthers(others)
        }
    }, [data, reg])

    const findName = (item: any, i: number) => {
        if ((others as any).length > 0 && item) {
            return (others as any)[i].items
                .find((val: any) => val.id === item
                    .replace('(', '')
                    .replace(')', ''))?.name[`lang_${i18n.language}`]
        }
    }

    const deleteOption = (val: string) => {
        const newRegArray = [...disArray] as any
        newRegArray[0] = `(${(selectedGender as any).filter((item: any) => item !== val).join('/')})`
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
    }

    const deleteOptionOthers = (val: string, index: number) => {
        const newRegArray = [...disArray] as any
        newRegArray[index + 1] = `(${(selectedOthers as any)[index].filter((item: any) => item !== val).join('/')})`
        dispatch(changeRegularExpression(newRegArray.join(':').replace(/\//g, '|')))
    }

    return (
        <WrapperOprions>
            {!isMap &&
            <React.Fragment>
                {selectedGender && (selectedGender as any).length > 0 && (selectedGender as any).length > 0 && (selectedGender as any).map((item: any, i: number) => (
                    item && (gender as any) && (
                        <Option key={i}>{(gender as any).items.find((val: any) =>
                            val.id === item.replace('(', '').replace(')', '')
                        )?.name[`lang_${i18n.language}`]} <Icon onClick={() => deleteOption(item)}/> </Option>)
                ))
                }

                {selectedOthers && others && (selectedOthers as any).map((item: string, i: number) =>
                    <Option key={i}> {findName(item, i)}</Option>)}
            </React.Fragment>}
            {isMap &&
            <React.Fragment>
                {selectedGender && gender &&
                <Option>{(gender as any).items.find((val: any) =>
                    val.id === selectedGender
                )?.name[`lang_${i18n.language}`]}</Option>}
                {others && selectedOthers && (selectedOthers as any).map((item: string[] | string, i: number) =>
                    <React.Fragment key={i}>
                        {typeof item !== 'string' && item.length > 0 && (item as string[]).map((val: any, index: number) => (
                            val && <Option key={index}> {findName(val, i)}<Icon
                                onClick={() => deleteOptionOthers(val, i)}/></Option>))}

                        {typeof item === 'string' && <Option
                            key={i}>{(others as any)[i].items.find((val: any) => val.id === item)?.name[`lang_${i18n.language}`]}</Option>}
                    </React.Fragment>
                )}
            </React.Fragment>}
        </WrapperOprions>
    )
}

export const SelectedOptions = withTranslation()(SelectedOptionsComponent)
