import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'

const Wrapper = styled.section`
  width: 100%;
  margin-top: 130px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;

  @media (max-width: 1023px) {
    margin-top: 96px;
    margin-bottom: 43px;
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    margin: 64px 0 44px;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1184px;

  @media(max-width: 1239px){
    max-width: 980px;
  }
  
  @media (max-width: 1023px) {
    padding: 0 64px;
    width: fit-content;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  max-width: 650px;
`

const ImageBar = styled.div`
  display: flex;
  margin: 36px 0 53px 0;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    margin: 36px 0 0;
  }
`

const Image = styled.img`
  margin-right: 48px;
  cursor: pointer;
  position: relative;
  top: 0;

  &:hover {
    top: -5px
  }

  transition: top .2s;

  @media (max-width: 1023px) {
    height: 48px;
  }

  @media (max-width: 767px) {
    height: 35px;
    margin-bottom: 20px;
    margin-right: 0;
  }
`

export function Partners() {
    const {t} = useTranslation()

    return (
        <Wrapper>
            <Content>
                <Text>{t('partners.bla_bla_bla')}</Text>
                <ImageBar>
                    <a href="https://www.unwomen.org" target="_blank" rel="noreferrer">
                        <Image src={'../SVGs/UN_Women.svg'} alt="UN Women logo"/>
                    </a>
                    <a href="https://www.unicef.org/" target="_blank" rel="noreferrer">
                        <Image src={'../SVGs/Unicef.svg'} alt="Unicef logo"/>
                    </a>
                </ImageBar>
            </Content>
        </Wrapper>
    )
}
