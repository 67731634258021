import React from 'react'
import {TitleIndicatorSection, IndicatorSubcategories} from '../components'

export function DemographyContainer() {

    return (<div>
        <TitleIndicatorSection title='demography.title' addInfo='demography.addInfo' imageName='demography'/>
        <IndicatorSubcategories keyValue={1} keyName='demography'/>
    </div>)
}

