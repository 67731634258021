import React from 'react'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'

import {TitleIndicatorSection} from '../components'
import LinksContent from '../components/content/LinksContent'

const Wrapper = styled.div`
  margin-bottom: 96px;

  @media (max-width: 767px) {
    margin-bottom: 64px;
  }
`

const links = {
    '1': 'links.informationResources',
    '2': 'links.InternationalOrganizations',
    '3': 'links.statistics',
    '4': 'links.StatisticalPublications'
}

export function LinksContainer() {

    const {id} = useParams<{ id: string }>()

    return (
        <Wrapper>
            <TitleIndicatorSection title={(links as any)[id]} addInfo=''/>
            <LinksContent id={id}/>
        </Wrapper>
    )
}
