import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Linkify from 'react-linkify'

import {IndicatorPassportModel} from '../../models'
import {useTranslation, withTranslation} from 'react-i18next'

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1023px) {
    border: none;
    border-radius: 0;
  }
`
const Row = styled.div`
  background: #FFFFFF;
  width: 100%;
  display: grid;
  align-self: baseline;
  grid-template-columns:  1fr 2fr;

  &:nth-child(even) {
    background-color: #F6F6F9;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`
const LeftColumn = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  padding: 10px 24px;
  font-weight: 600;
  border-right: 1px solid #d3d3d3;

  @media (max-width: 1023px) {
    border-right: none;
    padding: 10px 64px 3px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    padding: 10px 24px 3px;
  }
`

const RightColumn = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  padding: 10px 32px;

  @media (max-width: 1023px) {
    padding: 3px 64px 13px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    padding: 3px 24px 13px;
  }
`

const BlockBackground = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
    border: none;
    width: 100%;
    height: 32px;
    background: #427B89;
    margin-bottom: 48px;
  }
`
type Props = {
    dataTable: IndicatorPassportModel,
    indicatorName: string
}

function PassportTableComponent({dataTable, indicatorName}: Props) {
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`

    const formatDate = (date: string) => {
        if (!date) {
            return '-'
        }
        const newDate = moment(date, 'YYYY-MM-DD')
        if (!newDate.isValid()) {
            return '-'
        }
        return newDate.format('DD.MM.YYYY')
    }


    return (
        <Wrapper>
            <Row>
                <LeftColumn>{t('passport.indicatorName')}</LeftColumn>
                <RightColumn>{indicatorName}</RightColumn>
            </Row>
            <Row>
                <LeftColumn>{t('passport.dataSource')}</LeftColumn>
                <RightColumn>

                    {
                        dataTable.dataSource ?
                            (dataTable.dataSource as any)[language].split('\n').map(
                                (e: string, i: number) =>
                                    <div key={i}> {
                                        dataTable?.dataSourceLink ? (
                                            <a href={dataTable?.dataSourceLink} target="_blank" rel="noreferrer">
                                                {e}
                                            </a>) : e
                                    }
                                    </div>
                            )
                            : '-'
                    }
                </RightColumn>
            </Row>
            <Row>
                <LeftColumn>{t('passport.calcUnits')}</LeftColumn>
                <RightColumn>{dataTable.calcUnits ? (dataTable.calcUnits as any)[language].split('\n').map((e: string, i: number) =>
                    <div key={i}>{e}</div>) : '-'}</RightColumn>
            </Row>
            <Row>
                <LeftColumn>{t('passport.calcMethod')}</LeftColumn>
                <RightColumn>{dataTable.calcMethod ? (dataTable.calcMethod as any)[language].split('\n').map((e: string, i: number) =>
                    <div key={i}>{e}</div>) : '-'}</RightColumn>
            </Row>
            <Row>
                <LeftColumn>{t('passport.dateOpen')}</LeftColumn>
                <RightColumn>{dataTable?.dateOpen ? dataTable?.dateOpen : '-'}</RightColumn>
            </Row>
            {dataTable?.dateClose && <Row>
                <LeftColumn>{t('passport.dateClose')}</LeftColumn>
                <RightColumn>{dataTable?.dateClose ? dataTable?.dateClose : '-'}</RightColumn>
            </Row>}
            <Row>
                <LeftColumn>{t('passport.providingTerm')}</LeftColumn>
                <RightColumn>
                    {dataTable.providingTerm ? (dataTable.providingTerm as any)[language].split('\n').map((e: string, i: number) =>
                        <div key={i}>{e}</div>) : '-'}
                </RightColumn>
            </Row>
            {/*<Row>*/}
            {/*    <LeftColumn>{t('passport.disaggregationLevels')}</LeftColumn>*/}
            {/*    <RightColumn>*/}
            {/*        dataTable.disaggregationLevels*/}
            {/*        {dataTable.disaggregationLevels ? (dataTable.disaggregationLevels as any)[language].split('\n').map((e: string, i: number) =>*/}
            {/*            <div key={i}>{e}</div>) : '-'}*/}
            {/*    </RightColumn>*/}
            {/*</Row>*/}
            <Row>
                <LeftColumn>{t('passport.publicationDate')}</LeftColumn>
                <RightColumn>{formatDate(dataTable.publicationDate)}</RightColumn>
            </Row>
            <Row>
                <LeftColumn>{t('passport.contact')}</LeftColumn>
                <RightColumn>{dataTable.contact ? (dataTable.contact as any)[language].split('\n').map((e: string, i: number) =>
                    <div key={i}>{e}</div>) : '-'}</RightColumn>
            </Row>
            {dataTable.comments && (dataTable.comments as any)[language] && <Row>
                <LeftColumn>{t('passport.comments')}</LeftColumn>
                <RightColumn><Linkify>{dataTable.comments ? (dataTable.comments as any)[language].split('\n').map((e: string, i: number) =>
                    <div key={i}>{e}</div>) : '-'}</Linkify></RightColumn>
            </Row>}
            <BlockBackground/>
        </Wrapper>
    )
}

export const PassportTable = withTranslation()(PassportTableComponent)
