import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {AxiosResponse} from 'axios'

import {FooterLine} from '../content'
import {withApiService, withChartService, withToastService} from '../wrapper'
import {ApiService, ChartService, ToastService} from '../../services'
import {DownloadedIndicator, StateModel, TableDataToDownload} from '../../models'
import {useSelector} from 'react-redux'
import {exportComponentAsPDF, Params} from 'react-component-export-image'

const Title = styled.div`
  font-family: Sero Pro, serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: #292C45;
  width: fit-content;
`

const Block = styled.div`
  padding: 36px 32px;
  position: fixed;
  background: #FFFFFF;
  box-shadow: 0 4px 24px rgba(41, 44, 69, 0.12);
  height: 100vh;
  top: 0;
  z-index: 1000;
  width: 100vw;
  right: 0;
  left: 0;


`

const Wrapper = styled.div`
  width: calc(100% - 70px);
  display: flex;
  justify-content: space-between;
`

const DownloadOptions = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`

const DownloadLink = styled.a`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1B5EAC;
  display: flex;
  cursor: pointer;
  margin-bottom: 12px;
  
  &:hover {
    text-decoration: underline;
  }

  &:first-child {
    margin-top: 17px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  width: 90vw;
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: 100% 100%;
  margin-right: 8px;
`

const IconClose = styled.div`
  margin-left: 7.33px;
  background-image: url("../../SVGs/close.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 14px;
  height: 14px;
  align-self: center;
`

type Props = {
    hideMenu: () => void,
    indicatorId: string,
    apiService: ApiService,
    toastService: ToastService,
    chartService: ChartService,
    handlePdf: any,
    fileName: string | undefined | Params

}

function BlockDownland767pxComponent({
                                         fileName,
                                         handlePdf,
                                         hideMenu,
                                         indicatorId,
                                         apiService,
                                         toastService,
                                         chartService
                                     }: Props) {
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const activeData = useSelector((state: StateModel) => state.indicatorActiveData)

    const inDeveloping = () => {
        alert('в разработке...')
    }

    const transpose = (matrix: any) => {
        let [row] = matrix
        return row.map((value: any, column: number) => matrix.map((row: any) => row[column]))
    }

    const downloadFile = (type: string, mimeFormat: string) => {
        apiService.downloadIndicatorData(indicatorId, type, i18n.language)
            .then((res: AxiosResponse<DownloadedIndicator>) => {
                const {data} = res.data
                const indicatorCode = activeData?.attrs?.indicatorCode?.replace(/\.+$/, '')
                const fileName = `data${indicatorCode}`
                apiService.downloadFile(data, mimeFormat, fileName, type.toLocaleLowerCase())
            })
            .catch(() => toastService.showDark(t('indicators.error')))
    }

    const downloadTable = () => {
        if (activeData) {
            const tableData = chartService.normalizeData(activeData)
            const titleData = chartService.getTitleForTable(activeData, language)

            const titleRes = [t('indicators.year'), ...titleData.map(title => title.name)]
            const dataRes = Object.keys(tableData)
                .map(year => {
                    const dataYear = titleData.map(title => title.key)
                        .map(titleKey => tableData[year][titleKey] || '')
                    return [year, ...dataYear]
                })

            const sendData: TableDataToDownload = {
                tableHeader:
                    `${(activeData?.name as any)?.[language] || ''} (${(activeData?.attrs?.calcUnits as any)?.[language] || ''})`,
                title: titleRes,
                table: transpose(dataRes)
            }

            apiService.downloadIndicatorTableData(sendData, indicatorId, 'XLSX', i18n.language)
                .then((res: AxiosResponse<DownloadedIndicator>) => {
                    const {data} = res.data
                    const indicatorCode = activeData?.attrs?.indicatorCode?.replace(/\.+$/, '')
                    const fileName = `data${indicatorCode}`
                    apiService.downloadFile(
                        data,
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        fileName,
                        'xlsx'
                    )
                })
                .catch(() => toastService.showDark(t('indicators.error')))
        }
    }

    return (
        <Block>
            <Wrapper>
                <Title>
                    {t('downloadDataBlock.downloadData')}
                </Title>
                <IconClose onClick={hideMenu}/>
            </Wrapper>
            <DownloadOptions>
                <ColumnWrap>{t('downloadDataBlock.completeDataset')} :
                    <DownloadLink
                        onClick={() => downloadFile('CSV', 'text/csv')}
                    ><Icon
                        style={{'backgroundImage': 'url(../../SVGs/csv.svg)'}}/>CSV</DownloadLink>
                    <DownloadLink onClick={() => downloadFile('XML', 'text/xml')}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/xml.svg)'}}/>XML</DownloadLink>
                    <DownloadLink onClick={() => downloadFile(
                        'XLSX',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    )}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/xlsx.svg)'}}/>XLSX</DownloadLink>
                    <DownloadLink onClick={() => downloadFile('XLS', 'application/vnd.ms-excel')}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/xls.svg)'}}/>XLS
                    </DownloadLink>
                    <DownloadLink onClick={() => downloadFile('JSON', 'application/json')}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/json.svg)'}}/>JSON
                    </DownloadLink></ColumnWrap>
                <FooterLine style={{paddingBottom: '0'}}/>
                <ColumnWrap>
                    <DownloadLink onClick={inDeveloping}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/pdf.svg)'}}/>
                        {t('downloadDataBlock.APIsetupInstructions')}
                    </DownloadLink>
                    <DownloadLink onClick={() => exportComponentAsPDF(handlePdf(), {
                        fileName: fileName as any,
                        pdfOptions: fileName === 'table' ? {
                            w: 200,
                            h: 417,
                            unit: 'px'
                        } : fileName === 'passport' ?
                            {
                                orientation: 'p',
                                h: 325, w: 130,
                                unit: 'px'
                            } : fileName === 'map' ? {
                                w: 200,
                                h: 317,
                                unit: 'px'
                            } : {
                                w: 300,
                                h: 475.5,
                                unit: 'px'
                            }
                    })}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/pdf.svg)'}}/>
                        {t('downloadDataBlock.chartTablePDF')}
                    </DownloadLink>
                    <DownloadLink onClick={downloadTable}><Icon
                        style={{'backgroundImage': 'url(../../SVGs/xlsx.svg)'}}/>
                        {t('downloadDataBlock.tableXLSX')}
                    </DownloadLink>
                </ColumnWrap>
            </DownloadOptions>
        </Block>
    )
}

export const BlockDownland767px =
    withTranslation()(withToastService(withChartService(withApiService(BlockDownland767pxComponent))))
