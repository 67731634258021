import React from 'react'
import styled, {css} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {NavigationBar, NavigationBar768px} from './'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'

const Wrapper = styled.section`
  width: 100%;
  color: #FFFFFF;
  background-color: #427B89;
  display: flex;
  justify-content: center;
  padding: 65px 0 50px 0;

  @media (max-width: 1023px) {
    padding: 64px 0 0 0;
  }

  @media (max-width: 767px) {
    padding: 56px 0 0 0;
  }
`

const GreetingsContent = styled.div`
  width: 100%;
  max-width: 1160px;
  @media(max-width: 1239px){
    max-width: 980px;
  }
`

const TextWrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    margin-left: 76px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 64px);
    margin: 0 32px;
  }
`

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: 0.05em;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 34px;
    width: 100%;
  }
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  margin-top: 10px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
`

const Details = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-top: 23px;
  color: #FFFFFF;
  text-decoration: none;

  &:hover {
    font-weight: 600;
  }
`

const WrapNavigationBar = styled.div`
  display: block;
  @media (max-width: 1023px) {
    display: none;
  }
`

const WrapNavigationBar768px = styled.div`
  ${(props: { isImpaired: boolean }) =>
          (props.isImpaired &&
                  css`display: block;
    `) || css`display: none;`};
  
  
  @media (max-width: 1023px) {
    display: block;
  }
`

export function Greeting() {
    const {t} = useTranslation()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    return (
        <Wrapper>
            <GreetingsContent>
                <TextWrapper>
                    <Title>{t('greeting.title')}</Title>
                    <Text>{t('greeting.text')}</Text>
                    <Details to={'/about'}>{t('greeting.details')}</Details>
                </TextWrapper>
                {!isImpaired && <WrapNavigationBar>
                    <NavigationBar/>
                </WrapNavigationBar>}
                <WrapNavigationBar768px isImpaired={isImpaired}>
                    <NavigationBar768px/>
                </WrapNavigationBar768px>
            </GreetingsContent>
        </Wrapper>
    )
}
