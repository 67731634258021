import React from 'react'
import styled, {css} from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {FacebookShareButton, TelegramShareButton, TwitterShareButton} from 'react-share'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'

const SocialLogo = styled.div`
  width: 28px;
  height: 28px;
  margin-left: 20px;
  position: relative;
  top: 0;
  cursor: pointer;

  &:hover {
    top: -5px;
  }

  transition: top .2s;

  @media (max-width: 767px) {
    width: 24px;
    height: 24px;
    &:hover {
      top: 0;
    }
  }
`

const Facebook = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/facebook.svg");
`

const Twitter = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/twitter.svg");
`

const Telegram = styled(SocialLogo)`
  background: center / contain no-repeat url("../../SVGs/telegram.svg");
`

const LinksInfo = styled.div`
  flex: 1;
  text-align: end;
  width: 80px;
  font-family: Sero Pro,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
`

const Wrapper = styled.div`
  ${(props: { isImpaired: boolean }) =>
          (props.isImpaired &&
                  css`display: none;
    `) || css`display: flex;`};
  
  justify-content: flex-end;
  align-items: center;
`

type Props = {
    name: string,
    title: string,
    urlIndicator: string
}

function ShareSocialNetworkComponent({name, urlIndicator, title}: Props) {
    const {t} = useTranslation()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    return (
        <Wrapper isImpaired={isImpaired}>
            <LinksInfo>{t(`${name}`)}</LinksInfo>
            <FacebookShareButton style={{outline: 'none'}}
                                 url={urlIndicator}
                                 quote={title}
            >
                <Facebook/>
            </FacebookShareButton>
            <TwitterShareButton style={{outline: 'none'}}
                                url={urlIndicator}
                                title={title}
            >
                <Twitter/>
            </TwitterShareButton>

            <TelegramShareButton style={{outline: 'none'}}
                                 url={urlIndicator}
                                 title={title}
            >
                <Telegram/>
            </TelegramShareButton>
        </Wrapper>
    )
}

export const ShareSocialNetwork = withTranslation()(ShareSocialNetworkComponent)
