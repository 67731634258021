import React from 'react'
import Pagination from 'rc-pagination'
import './Pagination.css'

// function onChange(current: number, pageSize: number) {
//     console.log('onChange:current=', current)
//     console.log('onChange:pageSize=', pageSize)
// }

type Props = {
    data: {
        totalItems: number,
        perPage: number
    }
    onChange: (current: number, pageSize: number) => void
}


function PaginationComponent({data, onChange}: Props) {
    return (
        <Pagination className="ant-pagination"
                    showSizeChanger
                    defaultCurrent={1} total={(data! as any).totalItems || 100}
                    defaultPageSize={(data! as any).perPage || 10}
                    onChange={onChange}
                    showTitle={false}
        />
    )
}

export default PaginationComponent
