import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {AxiosResponse} from 'axios'
import {exportComponentAsPDF, Params} from 'react-component-export-image'

import {withApiService, withChartService, withToastService} from '../wrapper'
import {ApiService, ChartService, ToastService} from '../../services'
import {DownloadedIndicator, StateModel, TableDataToDownload} from '../../models'
import {useSelector} from 'react-redux'

const Title = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  font-weight: 600;
`

const Block = styled.div`
  background: #F6F6F9;
  padding: 36px 0;
  margin-bottom: 96px;

  @media (max-width: 1023px) {
    padding: 64px;
    margin-bottom: 64px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  @media (max-width: 1239px) {
    max-width: 980px;
  }
`

const DownloadOptions = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`

const DownloadLink = styled.a`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1B5EAC;
  display: flex;
  cursor: pointer;
  margin-left: 21px;
  
  &:hover {
    text-decoration: underline;
  }
`
const RowWrap = styled.div`
  display: flex;
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background-position: center center;
  background-size: 100% 100%;
  margin-right: 8px;
`
const IconPrint = styled.div`
  height: 20px;
  width: 20px;
  background-position: center center;
  background-size: 100% 100%;
  background-image: url('../../SVGs/print.svg');
  margin-right: 7px;

`

const ButtonPrint = styled.button`
  background: #FFFFFF;
  border: 2px solid rgba(215, 215, 228, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 32px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
  display: flex;
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
  cursor: pointer;

  &:hover {
    background: rgba(215, 215, 228, 0.3);
    opacity: 0.5;
  }

  &:active {
    background: rgba(215, 215, 228, 0.5);
    opacity: 0.5;
  }

  &:focus {
    border: 2px solid #EE7B5D;
    box-sizing: border-box;
    outline: none;
    border-radius: 6px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

type Props = {
    apiService: ApiService,
    indicatorId: string,
    toastService: ToastService,
    chartService: ChartService,
    onPrint: () => void,
    handlePdf: any,
    fileName: string | undefined | Params
}

function BlockDownlandComponent({
                                    apiService,
                                    indicatorId,
                                    toastService,
                                    chartService,
                                    onPrint,
                                    handlePdf,
                                    fileName
                                }: Props) {
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const activeData = useSelector((state: StateModel) => state.indicatorActiveData)

    const handlePrint = () => {
        onPrint()
    }

    const inDeveloping = () => {
        alert('в разработке...')
    }

    const transpose = (matrix: any) => {
        let [row] = matrix
        return row.map((value: any, column: number) => matrix.map((row: any) => row[column]))
    }

    const downloadFile = (type: string, mimeFormat: string) => {
        apiService.downloadIndicatorData(indicatorId, type, i18n.language)
            .then((res: AxiosResponse<DownloadedIndicator>) => {
                console.log(res, activeData)
                const {data} = res.data
                const indicatorCode = activeData?.attrs?.indicatorCode?.replace(/\.+$/, '')
                const fileName = `data${indicatorCode}`
                apiService.downloadFile(data, mimeFormat, fileName, type.toLocaleLowerCase())
            })
            .catch(() => toastService.showDark(t('indicators.error')))
    }

    const downloadTable = () => {
        if (activeData) {
            const tableData = chartService.normalizeData(activeData)
            const titleData = chartService.getTitleForTable(activeData, language)

            const titleRes = [t('indicators.year'), ...titleData.map(title => title.name)]
            const dataRes = Object.keys(tableData)
                .map(year => {
                    const dataYear = titleData.map(title => title.key)
                        .map(titleKey => tableData[year][titleKey] || '')
                    return [year, ...dataYear]
                })

            const sendData: TableDataToDownload = {
                tableHeader:
                    `${(activeData?.name as any)?.[language] || ''} (${(activeData?.attrs?.calcUnits as any)?.[language] || ''})`,
                title: titleRes,
                table: transpose(dataRes)
            }

            apiService.downloadIndicatorTableData(sendData, indicatorId, 'XLSX', i18n.language)
                .then((res: AxiosResponse<DownloadedIndicator>) => {
                    const {data} = res.data
                    const indicatorCode = activeData?.attrs?.indicatorCode?.replace(/\.+$/, '')
                    const fileName = `data${indicatorCode}`
                    apiService.downloadFile(
                        data,
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        fileName,
                        'xlsx'
                    )
                })
                .catch(() => toastService.showDark(t('indicators.error')))
        }
    }

    return (
        <Block>
            <Wrapper>
                <Title>
                    {t('downloadDataBlock.downloadData')}
                </Title>
                <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <DownloadOptions>
                        <RowWrap style={{'marginBottom': '17px'}}>{t('downloadDataBlock.completeDataset')} :
                            <DownloadLink
                                onClick={() => downloadFile('CSV', 'text/csv')}
                                style={{'marginLeft': '25px'}}
                            >
                                <Icon style={{'backgroundImage': 'url(../../SVGs/csv.svg)'}}/>CSV</DownloadLink>
                            <DownloadLink onClick={() => downloadFile('XML', 'text/xml')}>
                                <Icon style={{'backgroundImage': 'url(../../SVGs/xml.svg)'}}/>XML</DownloadLink>
                            <DownloadLink onClick={() => downloadFile(
                                'XLSX',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            )}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/xlsx.svg)'}}/>XLSX</DownloadLink>
                            <DownloadLink onClick={() => downloadFile('XLS', 'application/vnd.ms-excel')}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/xls.svg)'}}/>XLS
                            </DownloadLink>
                            <DownloadLink onClick={() => downloadFile('JSON', 'application/json')}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/json.svg)'}}/>JSON
                            </DownloadLink></RowWrap>
                        <RowWrap>
                            <DownloadLink onClick={inDeveloping} style={{'marginLeft': '0'}}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/pdf.svg)'}}/>
                                {t('downloadDataBlock.APIsetupInstructions')}
                            </DownloadLink>
                            <DownloadLink onClick={() => exportComponentAsPDF(handlePdf(), {
                                // fileName: fileName as any,
                                fileName: `${fileName}${activeData?.attrs?.indicatorCode?.replace(/\.+$/, '')}.pdf`,
                                pdfOptions: fileName === 'table' ? {
                                    w: 325,
                                    h: 417,
                                    unit: 'px'
                                } : fileName === 'passport' ?
                                    {
                                        orientation: 'p',
                                        h: 325, w: 400,
                                        unit: 'px'
                                    } : fileName === 'map' ? {
                                        h: 263,
                                        w: 317,
                                        unit: 'px'
                                    } : {
                                        h: 300,
                                        w: 475.5,
                                        unit: 'px'
                                    }
                            })}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/pdf.svg)'}}/>
                                {t('downloadDataBlock.chartTablePDF')}
                            </DownloadLink>
                            <DownloadLink onClick={downloadTable}><Icon
                                style={{'backgroundImage': 'url(../../SVGs/xlsx.svg)'}}/>
                                {t('downloadDataBlock.tableXLSX')}
                            </DownloadLink>
                        </RowWrap>
                    </DownloadOptions>
                    <ButtonPrint onClick={handlePrint}><IconPrint/>{t('downloadDataBlock.print')}</ButtonPrint>
                </div>
            </Wrapper>
        </Block>

    )
}

export const BlockDownland = withTranslation()
(withToastService(withChartService(withApiService(BlockDownlandComponent))))
