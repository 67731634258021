import React, {useState} from 'react'
import styled from 'styled-components'

type CheckboxProps = {
    name?: string,
    value?: any,
    label: string,
    register?: any,
    className?: string,
    isDisable?: boolean,
    margin?: string
}

const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked + label {
    text-decoration: underline;
  }

  &:hover + label {
    text-decoration: underline;
  }

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  & + label::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 11px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url("../../SVGs/unchecked-checkbox.svg");
    cursor: pointer;
  }

  &:checked + label::before {
    /*border-color: #0b76ef;*/
    /*background-color: #0b76ef;*/
    background-size: 100% 100%;
    background-image: url('../../SVGs/checked-checkbox.svg');
  }

  &:disabled + label::before {
    background-image: url("../../SVGs/disabled-checkbox.svg");
    border-radius: 2px;
    background-size: 100% 100%;
  }
`

export function Checkbox({name, value, label, register, isDisable, margin}: CheckboxProps) {
    const [val, setValue] = useState(value)

    return (
        <div style={{'margin': margin}}>
            <Input type="checkbox" id={name} name={name} checked={val} onChange={() => setValue(!val)}
                   disabled={isDisable} ref={register}/>
            <label className={val ? 'active' : ''} htmlFor={name}>{label}</label>
        </div>
    )
}
