import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en'
import ru from './locales/ru'
import be from './locales/be'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: en,
            ru: ru,
            be: be
        },
        fallbackLng: 'ru',
        debug: false,
        whitelist: ['ru', 'en', 'be'],

        interpolation: {
            escapeValue: false
        }
    })
    .then(r => '')
    .catch(err => {
            throw new Error(err)
        }
    )

export default i18n
