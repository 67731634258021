import React, {useContext} from 'react'

import {ToastContext} from '../../contexts'
import {ToastService} from '../../services'

interface ComponentWithToast {
    toastService: ToastService
}

export function withToastService<T extends ComponentWithToast>(WrappedComponent: React.ComponentType<T>) {
    return function (props: any) {
        const toastService = useContext(ToastContext)
        return <WrappedComponent toastService={toastService} {...props} />
    }
}
