import React, {ChangeEvent} from 'react'
import styled from 'styled-components'

type Props = {
    years: string[],
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    currentYear: number
}

const YearsContainer = styled.div`
  padding-top: 30px;
`

const DatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div {
    position: relative;
    width: 40px;
    display: flex;
    justify-content: center;
  }
`

const VerticalLine = styled.div`
  position: absolute;
  border-right: 1px solid lightgray;
  height: 150%;
  top: -200%;
  left: 50%;
`

const HorizontalLine = styled.span`
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid lightgray;
`

const InputContainer = styled.div`
  width: calc(100%);
  height: 45px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
`

const Input = styled.input`
  &[type='range'] {
    position: relative;
    width: calc(100% + 10px);
    appearance: none;
    outline: none;
    z-index: 1;
    background: none;
  }


  &[type=range]::-webkit-slider-thumb {
    appearance: none;
    height: 35px;
    width: 48px;
    cursor: pointer;
    background: url("../../SVGs/thumb.svg") center center;
  }

  &[type=range]::-moz-range-thumb {
    border: none;
    height: 35px;
    width: 48px;
    cursor: pointer;
    background: url("../../SVGs/thumb.svg") center center;
  }

  /*hide the outline behind the border*/
  &[type=range]:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
  }

  /* All the same stuff for IE */
  &[type=range]::-ms-thumb {
    appearance: none;
    height: 35px;
    width: 48px;
    cursor: pointer;
    background: url("../../SVGs/thumb.svg") center center;
  }
`

export function ChartSlider({years, onChange, currentYear}: Props) {
    return (
        <YearsContainer>
            <InputContainer>
                <Input type="range" min={0} value={currentYear} max={years.length - 1} step={1} onChange={onChange}/>
                <HorizontalLine/>
            </InputContainer>
            <DatesContainer>
                {years.map((e: string) => {
                    return (
                        <div key={e}>
                            {e}
                            <VerticalLine/>
                        </div>
                    )
                })}
            </DatesContainer>
        </YearsContainer>
    )
}



