import React from 'react'

import {Greeting, Partners, Poll, News} from '../components'

export function HomeContainer(){
  return (
      <div>
          <Greeting/>
          <News />
          <Poll/>
          {/*<Poll formTypeDeleteMe="2" />*/}
          {/*<Poll formTypeDeleteMe="3" />*/}
          <Partners />
      </div>
  )
}

