import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'

import {SearchResultItem} from './'
import {Spinner, ErrorWrapper} from '../common'
import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {useData} from '../../hooks'
import {SearchItemModel} from '../../models/Search.model'

const Wrapper = styled.section`
  width: 100%;
  max-width: 1184px;
  margin: 36px auto 96px;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    margin: 36px 64px 64px;
    width: calc(100% - 128px);
  }

  @media (max-width: 767px) {
    margin: 24px 24px 64px;
    width: calc(100% - 48px);
  }
`

const Content = styled.div`
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 767px) {
    margin-bottom: 36px;
  }
`

const SearchResultItemContainer = styled.div`
  margin-bottom: 64px;

  @media (max-width: 1023px) {
    margin-bottom: 56px;
  }

  @media (max-width: 767px) {
    margin-bottom: 48px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const SearchInfo = styled.p`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  margin: 0;
  opacity: 0.5;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const DataContainer = styled.div`
  margin-top: 24px;
`

type SearchProps = {
    apiService: ApiService,
    searchValue: string
}

function SearchResultComponent({apiService, searchValue}: SearchProps) {

    const {t, i18n} = useTranslation()
    const memoizedCallback = useCallback(
        () => {
            return apiService.getSearchResult(searchValue, i18n.language)
        },
        [searchValue, apiService, i18n]
    )

    const state = useData(memoizedCallback)


    return (
        <div>
            {state.loading && <Spinner/>}
            {
                state.error && <ErrorWrapper>{state.error}</ErrorWrapper>
            }
            {
                state.data && (state.data! as any).length === 0 &&
                <Wrapper>
                    <SearchInfo>
                        {t('search.notFound')}
                    </SearchInfo>
                </Wrapper>
            }
            {
                state.data && (state.data! as any)?.length > 0 &&
                <Wrapper>
                    <Content>
                        <SearchInfo>
                            {t('search.found')}
                            {(state?.data! as any).length}
                        </SearchInfo>
                        <DataContainer>
                            {
                                (state?.data! as any).map(
                                    (searchItem: SearchItemModel, index: number) =>
                                        <SearchResultItemContainer key={index}>
                                            <SearchResultItem searchValue={searchValue} searchItem={searchItem}/>
                                        </SearchResultItemContainer>
                                )
                            }
                        </DataContainer>
                    </Content>
                </Wrapper>
            }
        </div>
    )
}

export const SearchResult = withApiService(withTranslation()(SearchResultComponent))
