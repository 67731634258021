import React from 'react'
import styled from 'styled-components'

import {useTranslation} from 'react-i18next'

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #d3d3d3;
  width: 100%;
  max-width: 852px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1023px) {
    max-width: 100%;
    border: none;
    border-radius: 0;
  }
`

const WrapScrollbar = styled.div`
  overflow: auto;

  //& {
  //  scrollbar-width: thin;
  //  scrollbar-color: #A8A8A8 #F1F1F1;
  //}

  /* Works on Chrome, Edge, and Safari */

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 6px;
    border: 3px solid #A8A8A8;
  }
`

const Row = styled.div`
  width: 100%;
  display: grid;
  align-self: baseline;
  grid-template-columns:  repeat(11, 1fr);
  background-color: white;

  &:nth-child(odd) {
    background-color: #F6F6F9;
    width: fit-content;
  }

  &:first-child {
    background-color: white;
    border-bottom: 2px solid #959595;
  }
`

const Cell = styled.div`
  border-right: 1px solid #d3d3d3;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  text-align: center;
  padding: 10px 0;
  width: 148px;

  &:first-child {
    font-weight: 700;
  }
`

const CellHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #d3d3d3;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  text-align: center;
  padding: 10px 0;
  width: 148px;
  font-weight: 700;
`

const BlockBackground = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
    border: none;
    width: 100%;
    height: 32px;
    background: #427B89;
  }
`

type Title = {
    key: string,
    name: string
}

type Props = {
    data: {
        yearData: any,
        titleDim: Title[] | null
    }
}

export function IndicatorTable({data}: Props) {

    const {t} = useTranslation()

    return (
        <Wrapper>
            <WrapScrollbar>
                <Row>
                    <CellHeader>
                        {t('indicators.year')}
                    </CellHeader>
                    {
                        (data! as any).titleDim.map(
                            (e: Title) => {
                                return (
                                    <CellHeader
                                        key={e.key}
                                    >
                                        {e.name.split(/(?:, )/).map((item, index, array) => (
                                            <span key={index}>{item.trim()}{index < array.length - 1 ? ',' : ''}</span>))}
                                    </CellHeader>
                                )
                            })
                    }
                </Row>
                {
                    Object.keys((data! as any).yearData).map((year: string) => (
                        <Row key={year}>
                            <Cell>{year}</Cell>
                            {
                                (data! as any).titleDim.map((title: any) => {
                                    return (
                                        <Cell key={title.key}>
                                            {
                                                ((data! as any).yearData as any)[year][title.key] || ''
                                            }
                                        </Cell>
                                    )
                                })
                            }
                        </Row>
                    ))
                }
            </WrapScrollbar>
            <BlockBackground/>
        </Wrapper>
    )
}
