import React, {MutableRefObject, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import Chart from 'chart.js'
import {decimalPermissionFormat} from './formatHelper'
import {format} from 'd3'
import {useTranslation} from 'react-i18next'

const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px 32px 24px;
  border: #D4D5DA 1px solid;

  @media (max-width: 1023px) {
    border: none;
    padding: 24px 55px;
  }
  @media (max-width: 767px) {
    padding: 20px 16px;
  }

`

const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: default;
`
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;

  &:first-child {
    margin-right: 24px;
  }

  @media (max-width: 767px) {
    &:first-child {
      margin-right: 12px;
    }
  }

  @media (max-width: 767px) {
    &:first-child {
      color: #FF5050;
    }

    &:last-child {
      color: #41719C;
    }
  }
`


const ChartjsWrapper = styled.div`
  width: 50%;
  height: auto;
  margin: 28px 24px 10px;
  position: relative;

  @media (max-width: 1023px) {
    width: 70%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 36px 0 32px;
  }
`

const Value = styled.p`
  width: fit-content;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;

  @media print {
    & {
      position: absolute;
      left: 140px;
      bottom: 0;
    }
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 24px;
  }
`

const Mark = styled.span`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #FF5050;
  @media (max-width: 767px) {
    display: none;
  }
`
const MaleMark = styled(Mark)`
  background-color: #41719C;
`

const FemaleMark = styled(Mark)`
  background-color: #FF5050;
`

type Props = {
    data: (string | number)[][],
    children: React.ReactNode
}

const createChart = (data: (string | number)[][], permission: any, t: any) => {
    let ctx = document.getElementById('chartjs-gauge')

    let chart = new Chart((ctx as HTMLCanvasElement), {
        type: 'doughnut',
        data: {
            labels: ['female', 'male'],
            datasets: [{
                label: 'Gauge',
                data: [0, 0],
                backgroundColor: ['#FF5050', '#41719C']
            }]
        },
        options: {
            circumference: Math.PI,
            rotation: Math.PI,
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem, data) {
                        // @ts-ignore
                        return `${t('charts.' + data.labels[tooltipItem.index])}: ${permission ? format('.1f')(data?.datasets[0]?.data[tooltipItem.index]) : data.datasets[0].data[tooltipItem.index]}`
                    },
                },
            },
        }
    })

    change_gauge(chart, [data[0][1], data[1][1]])
    return chart
}

function change_gauge(chart: any, data: any) {
    let value = document.getElementById('value');
    (value! as any).innerText = `${data[0]}/${data[1]}`
    chart.data.datasets.forEach((dataset: any) => dataset.data = data)
    chart.update()
}

export function PieChart1({data, children}: Props) {
    const {t} = useTranslation()
    const [chart, setChart] = useState<any>(null)

    const scrollRef = useRef() as MutableRefObject<any>

    useEffect(() => {
        const permission = decimalPermissionFormat(data)
        const chart = createChart(data, permission, t)
        setChart(chart)
    }, [])

    useEffect(() => {
        if (chart) {
            change_gauge(chart, [data[0][1], data[1][1]])
        }
    }, [data, chart])

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth
        }
    }, [scrollRef])

    return (
        <Wrapper>
            {
                (data[0][1] !== 0 && data[1][1] !== 0) &&
                <Legend>
                    <LegendItem>
                        <FemaleMark/> {t('charts.female')}
                    </LegendItem>
                    <LegendItem>
                        <MaleMark/> {t('charts.male')}
                    </LegendItem>
                </Legend>
            }
            <ChartjsWrapper>
                <canvas className="chartjs-gauge" id="chartjs-gauge"/>
                <Value id="value">0/0</Value>
            </ChartjsWrapper>
            {
                React.Children.map(children, (childElement, index) => {
                    if (index === 0) { // @ts-ignore
                        return React.cloneElement(childElement, {ref: scrollRef})
                    }
                    return childElement
                })
            }
        </Wrapper>
    )
}
