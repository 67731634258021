import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function HealthContainer(){

  return (<div>
    <TitleIndicatorSection title='health.title' addInfo='health.addInfo' imageName='health'/>
    <IndicatorSubcategories keyName='health' keyValue={2} />
  </div>)
}
