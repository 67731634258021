import {toast} from 'react-toastify'

export class ToastService{
    showDark = (message: string) => {
        toast.dark(message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    }
}
