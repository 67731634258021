import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {NewsCard} from './'
import {Spinner, ErrorWrapper} from '../common'
import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {NewsModel, StateModel} from '../../models'
import PaginationComponent from '../common/pagination/Pagination'
import {useTranslation} from 'react-i18next'

const Wrapper = styled.section`
  width: 100%;
  max-width: 1184px;
  margin: 64px auto 96px;

  @media (max-width: 1239px) {
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    margin: 48px 64px 64px;
    width: calc(100% - 128px);
  }

  @media (max-width: 767px) {
    margin: 36px 24px 64px;
    width: calc(100% - 48px);
  }
`

const Content = styled.div`
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    margin-bottom: 36px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
`

const NewsCards = styled.div<{ isImpaired: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props: { isImpaired: boolean }) => props.isImpaired ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
  grid-gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`


type NewsProps = {
    apiService: ApiService
}

function AllNewsComponent({apiService}: NewsProps) {
    const {i18n} = useTranslation()
    const language = `lang_${i18n.language}`
    const perPage = 9

    const filterData = (array: NewsModel[], indexStart: number, indexEnd: number) => {
        return array?.filter((e: NewsModel, index: number) => index >= indexStart && index <= indexEnd)
    }

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState<null | NewsModel[]>(null)
    const [showData, setShowData] = useState<null | NewsModel[]>(null)
    useEffect(() => {
        setLoading(true)
        setError(null)
        apiService.getAllNews(language).then((res) => {
            setData(res.data)
            setShowData(filterData(res.data, 0, res.data.length - 1 < perPage - 1 ? res.data.length - 1 : perPage - 1))
        })
            .catch((err) => setError(err.message)).finally(() => setLoading(false))
    }, [apiService, language])


    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    const onChange = (current: number, pageSize: number) => {
        const start = current === 1 ? 0 : (current - 1) * perPage
        const end = start + perPage - 1 > data!.length - 1 ? data!.length - 1 : start + perPage - 1
        setShowData(filterData(data!, start, end))
    }

    return (
        <div>
            {loading && <Spinner/>}
            {
                error && <ErrorWrapper>{error}</ErrorWrapper>
            }
            {
                data && showData &&
                <Wrapper>
                    <Content>
                        <NewsCards isImpaired={isImpaired}>
                            {(showData! as any).map((news: NewsModel, index: number) => <NewsCard key={index}
                                                                                                  card={news}/>)}
                        </NewsCards>
                    </Content>
                    <PaginationComponent onChange={onChange} data={{totalItems: data.length, perPage: perPage}}/>
                </Wrapper>
            }
        </div>
    )
}

export const AllNews = withApiService(AllNewsComponent)
