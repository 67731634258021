import React from 'react'
import styled, {css} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'


const Text = styled.span`
  font-size: 18px;
  font-family: Sero Pro, serif;
  line-height: 22px;
  font-weight: 600;
  color: #292C45;
  align-self: center;
  width: 200px;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 18px;
    width: 160px;

  }
`

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  grid-template-rows:repeat(4, 1fr);
  margin: 64px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows:repeat(8, 1fr);
    margin: 34px 24px 48px;
    gap: 0;
  }
`

const Card = styled(Link)`
  background: #FFFFFF;
  box-shadow: 0 6px 20px rgba(41, 44, 69, 0.12);
  border-radius: 12px;
  width: calc(100% - 30px);
  text-decoration-line: none;
  display: flex;
  justify-items: center;
  cursor: pointer;
  &:hover {
    background-color: #BBF1FC;
  }

  transition: transform .2s;

  @media (max-width: 767px) {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Icon = styled.div`
  width: 71px;
  height: 64px;
  background-position: center center;
  background-size: 100% 100%;
  margin: 0 6px;
  ${(props: { isImpaired: boolean }) => (props.isImpaired && css`display: none`) || css`display: inline-block`};

  @media (max-width: 767px) {
    width: 71px;
    height: 64px;
    margin: 0 6px;
  }
`

export function NavigationBar768px({style}: any) {
    const {t} = useTranslation()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    const Links = [
        {
            key: 'Demography',
            to: '/demography'
        },
        {
            key: 'Health',
            to: '/health'
        },
        {
            key: 'Education',
            to: '/education'
        },
        {
            key: 'Economics',
            to: '/economics'
        },
        {
            key: 'Welfare',
            to: '/welfare'
        },
        {
            key: 'Social',
            to: '/social'
        },
        {
            key: 'Communication',
            to: '/communication'
        },
        {
            key: 'Violence',
            to: '/violence'
        },
    ]

    return (<Wrap style={style}>
            {Links.map((item: any) => (
                <Card  key={item.key} to={item.to} style={isImpaired ? {padding:'15px'} : {padding:'0 30px 0 0'}}>
                    <Icon isImpaired={isImpaired}
                          style={{'backgroundImage': `url(../../SVGs/${item.key.toLowerCase()}.svg)`}}/>
                    <Text>{t(`header.${item.key}`)}</Text>
                </Card>
            ))}

        </Wrap>
    )
}
