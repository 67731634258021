import React, {useState} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {usePromiseTracker} from 'react-promise-tracker'
import ReCAPTCHA from 'react-google-recaptcha'
import {useSelector} from 'react-redux'

import {withApiService} from '../wrapper'
import {ApiService} from '../../services'
import {Button} from '../controls'
import '../../App.css'
import {FeedbackPost, StateModel} from '../../models'

const Wrapper = styled.section<{ isImpaired: boolean }>`
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #FFFFFF;
  padding: 36px 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: ${(props: { isImpaired: boolean }) => props.isImpaired ? '1px solid black' : 'none'};

  @media (max-width: 1023px) {
    padding: 28px 36px;
  }

  @media (max-width: 767px) {
    font-family: Sero Pro, serif;
    height: calc(100% - 56px);
    width: calc(100% - 48px);
    padding: 32px 24px 24px;
    z-index: 1000;
  }
`

const Title = styled.h2`
  padding: 0;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.05em;
  color: #292C45;
  margin: 0 0 12px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    width: 150px;
  }
`

const Textarea = styled.textarea`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  background: #F6F6F9;
  border-radius: 4px;
  padding: 18px 24px;
  width: 580px;
  border: none;
  height: 180px;
  margin-top: 12px;

  @media (max-width: 767px) {
    width: calc(100% - 48px);
    font-size: 14px;
    line-height: 20px;
    height: 250px;

  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Icon = styled.div`
  margin-right: 8px;
  background-image: url("../../SVGs/warning.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const IconClose = styled.div`
  margin-left: 7.33px;
  background-image: url("../../SVGs/close.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 14px;
  height: 14px;
  align-self: center;
  position: absolute;
  right: 29px;
  top: 29px;
`

const ErrorWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 30px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;

  @media (max-width: 767px) {
    display: none;
  }
`

type NewsProps = {
    apiService: ApiService,
    reportClick: any
}

const WrapInput = styled.div`
  position: relative;
`
const WrapButton = styled.div`
  display: flex;
`

const Error = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #E10F22;
  margin-left: 24px;
  align-self: center;
  display: flex;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

const Success = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #36B27E;
  margin-left: 24px;
  align-self: center;
  display: flex;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    margin-left: 12px;
  }
`

type FormAnswer = {
    problem: string
}

function SubmitReportComponent({apiService, reportClick}: NewsProps) {
    const [captchaValue, setCaptcha] = useState<null | string>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<null | string>(null)
    const [success, setSuccess] = useState<null | string>(null)
    const {register, handleSubmit, errors, reset} = useForm()

    const {t, i18n} = useTranslation()
    const {promiseInProgress} = usePromiseTracker()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)
    const onSubmit = (formData: FormAnswer) => {
        if (captchaValue) {
            setLoading(true)
            setError('')
            setSuccess('')
            const sendObj: FeedbackPost = {
                newsId: null,
                userName: null,
                userEmail: null,
                type: 'FAILURE',
                content: formData.problem,
                failureUrl: window.location.href
            }
            return apiService.postFrontendFeedback(sendObj, i18n.language).then(() => {
                reset({})
                setSuccess(t('contacts.success'))
            }).catch(() => {
                setError(t('contacts.fail'))
            }).finally(() => setLoading(false))
        }
    }

    const onChange = (value: string | null) => setCaptcha(value)

    return (
        <Wrapper isImpaired={isImpaired}>
            <Title>{t('report.report')}</Title>
            <IconClose onClick={() => reportClick()}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <WrapInput>
                    <Textarea
                        className={errors.problem ? 'Border-error' : ''} placeholder={`${t('report.problem')}`}
                        name={'problem'}
                        ref={register({required: true})}/>
                    {errors.problem && <ErrorWrapper>{t('report.errorInput')}</ErrorWrapper>}
                </WrapInput>
                <div style={{'margin': '12px 0 18px'}}>
                    <ReCAPTCHA
                        hl={i18n.language}
                        sitekey='6LfmN5kaAAAAAJbmzFVEzuXqK6IWk347y-NjUuHj'
                        onChange={(token) => onChange(token)}
                    /></div>
                <WrapButton>
                    <Button width={'fit-content'} background={'#3A3084'} label={t('report.submit')}
                            showSpinner={loading}
                            disabled={promiseInProgress}/>
                    {error &&
                    <Error><Icon style={{backgroundImage: 'url(../../SVGs/error.svg)'}}/>{t('contacts.fail')}</Error>}
                    {success &&
                    <Success><Icon style={{backgroundImage: 'url(../../SVGs/success.svg)'}}/>{t('contacts.success')}
                    </Success>}
                </WrapButton>
            </Form>
        </Wrapper>
    )
}

export const SubmitReport = withTranslation()(withApiService(SubmitReportComponent))
