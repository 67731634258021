import React from 'react'
import {IndicatorSubcategories, TitleIndicatorSection} from '../components'

export function SocialContainer(){

  return (<div>
    <TitleIndicatorSection title='social.title' addInfo='social.addInfo' imageName='social'/>
    <IndicatorSubcategories keyName='social' keyValue={6}/>
  </div>)
}
