import React from 'react'
import {useTranslation, withTranslation} from 'react-i18next'
import styled from 'styled-components'

import {LinkSelectIndicator} from './'
import {useQuery} from '../../hooks'
import {useDispatch} from 'react-redux'
import {changeTypeIndicator} from '../../store/actions/actions'


const Wrap = styled.div`
  background: #427B89;
  width: 100%;
`

const LinksBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-bottom: 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const LinksBlockWidth768 = styled(LinksBlock)`
  display: none;

  @media (max-width: 1023px) {
    margin-left: 76px;
    display: flex;
    width: fit-content;
  }

  @media (max-width: 767px) {
    margin-left: 24px;
    width: fit-content;
  }
`

const HeaderLink = styled.button`
  background: #FFFFFF;
  border-radius: 23px;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #292C45;
  flex: none;
  padding: 8px 16px;
  margin: 0 12px 0 0;
  text-decoration: none;
  cursor: pointer;
  border: 2px #D4D5DA solid;
  outline: none;

  &:hover {
    border: 2px #A9ABB5 solid;
  }

  &:disabled {
    cursor: auto;
    background: #D4D5DA;

    &:hover {
      border: 2px #D4D5DA solid;
    }
  }
`

enum LinkType {
    withChildren = 'withChildren',
    normal = 'normal'
}

type LinkModel = {
    type: LinkType,
    key: string,
    to?: string,
    children?: LinkModel[]
}


function ChangeName() {

    switch (useQuery().get('type')) {
        case 'lineChart':
            return 'lineChart'
        case 'columnChart':
            return 'columnChart'
        case 'plotWithAreas':
            return 'plotWithAreas'
        case 'pieChart1':
            return 'pieChart1'
        case 'pieChart2':
            return 'pieChart2'
        default:
            return 'diagrams'

    }
}

type Props = {
    isShowMap: boolean
}

function LinksOptionsBarComponent({isShowMap}: Props) {

    const query = useQuery()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const activeButton = {
        withChildren: {
            background: '#EE7B5D',
            border: '1px solid #EE7B5D',
            color: '#FFFFFF',
            paddingRight: 'calc(1.8em + 8px)',
            backgroundImage: 'url("../../SVGs/down-arrow-white.svg")',
            backgroundRepeat: 'no-repeat, repeat',
            backgroundPosition: 'right 16px top 50%, 0 0'
        },
        normal: {
            background: '#EE7B5D',
            border: '1px solid #EE7B5D',
            color: '#FFFFFF',
        }
    }


    const links = [
        {
            type: LinkType.withChildren,
            key: ChangeName(),
            children: [
                {
                    type: LinkType.normal,
                    key: 'lineChart',
                },
                {
                    type: LinkType.normal,
                    key: 'columnChart',
                },
                {
                    type: LinkType.normal,
                    key: 'plotWithAreas',
                },
                {
                    type: LinkType.normal,
                    key: 'pieChart1',
                },
                {
                    type: LinkType.normal,
                    key: 'pieChart2',
                },

            ]
        },
        {
            type: LinkType.normal,
            key: 'map',
        },
        {
            type: LinkType.normal,
            key: 'table',

        },
        {
            type: LinkType.normal,
            key: 'passport',

        }
    ]

    const linksWidth768 = [
        {
            type: LinkType.normal,
            key: 'lineChart',
        },
        {
            type: LinkType.normal,
            key: 'columnChart',
        },
        {
            type: LinkType.normal,
            key: 'plotWithAreas',
        },
        {
            type: LinkType.normal,
            key: 'pieChart1',
        },
        {
            type: LinkType.normal,
            key: 'pieChart2',
        },
        {
            type: LinkType.normal,
            key: 'map',
        },
        {
            type: LinkType.normal,
            key: 'table',

        },
        {
            type: LinkType.normal,
            key: 'passport',

        }
    ]

    const changePath = (key: string) => {
        dispatch(changeTypeIndicator(key))
    }


    return (
        <Wrap>
            <LinksBlock>
                {links.map((link: LinkModel, index: number) => {
                        switch (link.type) {
                            case LinkType.withChildren:
                                return (<LinkSelectIndicator
                                    style={query.get('type') === link.key ? activeButton.withChildren : {}}
                                    key={index}
                                    title={t(`dataViewMenu.${link.key}`).toUpperCase()}
                                    links={link.children!}/>)
                            case LinkType.normal:
                                return (<HeaderLink disabled={!isShowMap && link.key === 'map'}
                                                    style={query.get('type') === link.key ? activeButton.normal : {}}
                                                    onClick={() => changePath(link.key)}
                                                    key={index}
                                >
                                    {t(`dataViewMenu.${link.key}`).toUpperCase()}
                                </HeaderLink>)
                            default:
                                return (<HeaderLink
                                    onClick={() => changePath(link.key)}
                                    key={index}
                                >
                                    {t(`dataViewMenu.${link.key}`).toUpperCase()}
                                </HeaderLink>)

                        }
                    }
                )}
            </LinksBlock>
            <LinksBlockWidth768>
                <LinkSelectIndicator links={linksWidth768} title={t(`dataViewMenu.${query.get('type')}`).toUpperCase()}
                                     style={activeButton.withChildren} showMap={isShowMap}/>
            </LinksBlockWidth768>
        </Wrap>
    )
}

export const LinksOptionsBar = withTranslation()(LinksOptionsBarComponent)
