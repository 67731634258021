import React from 'react'
import {Button} from '../controls'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {PublishedSurveyModel, PublishedSurveyOptionModel} from '../../models/Survey.model'

type PollResultProps = {
    data: PublishedSurveyModel
}

const ResultItemContainer = styled.div`
  margin: 22px 0 !important;
  @media (max-width: 767px) {
    margin: 16px 0 !important;
  }
`

const ResultItemTitle = styled.p`
  font-family: Sero Pro, serif;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  margin: 8px 0;
`

const ColoredBlock = styled.div<{ amount: number }>`
  height: 20px;
  width: calc((100% - 50px) / 100 * ${(props: { amount: number }) => props.amount});
  background: #427B89;
  border-radius: 2px;
  margin-right: 8px;
`

const ColoredBlockWrapper = styled.div`
  display: flex;
  font-family: Sero Pro, serif;
  font-size: 14px;
  line-height: 20px;
  color: #292C45;
`

const WrapMessageWithButton = styled.div`
  display: flex;
`

const ButtonWrapper = styled.div`
  padding: 9px 0;
  margin-right: 24px;

  @media (max-width: 767px) {
    margin-right: 16px;
  }
`

const Icon = styled.div`
  margin-right: 8px;
  background-image: url("../../SVGs/success.svg");
  background-repeat: no-repeat, repeat;
  background-size: 100%, 100%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  align-self: center;

  @media (max-width: 767px) {
    display: none;
  }
`

const ResultWrapper = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #36B27E;
  display: flex;
  align-items: center;
`

export function PollResult({data}: PollResultProps) {
    const {t, i18n} = useTranslation()
    const language = `lang_${i18n.language}`

    const sum = data.questions[0]?.options?.reduce((acc, e) => acc + e.counter, 0)

    return (
        <div>
            {data.questions[0]?.options?.map((option: PublishedSurveyOptionModel) => (
                <ResultItemContainer key={option.optionId}>
                    <ResultItemTitle>{(option.optionText as any)[language]}</ResultItemTitle>
                    <ColoredBlockWrapper>
                        <ColoredBlock amount={(option.counter / sum * 100)}/>
                        <span>{`${(option.counter / sum * 100).toFixed(2)}%`}</span>
                    </ColoredBlockWrapper>
                </ResultItemContainer>
            ))}
            <WrapMessageWithButton>
                <ButtonWrapper>
                    <Button label={t('poll.reply')} showSpinner={false} disabled={true}/>
                </ButtonWrapper>
                <ResultWrapper><Icon/>{t('poll.result')}</ResultWrapper>
            </WrapMessageWithButton>
        </div>
    )
}
