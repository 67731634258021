import {IndicatorDataModel} from '../models'

export class ChartService {

    Y_TICK_AXIS_NUM = 6

    normalizeData = (data: IndicatorDataModel) => {
        return Object.keys(data.dataset).reduce(
            (acc: any, e: string) => {
                const year = e.split(':')[0]
                const value = data.dataset[e]
                const newKey = e.replace(/^\d{4}:/, '')
                if (acc[year]) {
                    acc[year][newKey] = value
                } else {
                    acc[year] = {}
                    acc[year][newKey] = value
                }
                return acc
            }, {})
    }

    comparator = (a: string, b: string) => {
        const order = {
            T: 0,
            F: 1,
            M: 2
        }
        if (a[0] === b[0]) {
            return 0
        }
        const aPriority = (order as any)?.[a[0]] || 0
        const bPriority = (order as any)?.[b[0]] || 0
        return aPriority - bPriority
    }

    getTitleForTable = (data: IndicatorDataModel, language: string) => {
        const structure = data.structure.dimensions.slice(1)
            .map(item =>
                item.items
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.id]: (item.name as any)[language]
                    }), {}))
        return Array.from(
            new Set(Object.keys(data.dataset).map(item => item.replace(/^\d{4}:/, ''))))
            .sort(this.comparator)
            .map(item => ({
                key: item,
                name: item.split(':')
                    .map(
                        (id, index) =>
                            (id === 'T' && index !== 0) ? null : (structure as any)[index][id])
                    .filter(Boolean)
                    .join(', ')
            }))
    }


    formChartItemWithPrevYear = (year: string, currentYear: any, previousYear: any) => {
        const currentYearData = Object.keys(currentYear).reduce(
            (acc: any, e: string) => {
                if (/^F/.test(e)) {
                    acc['female'] = currentYear[e]
                }
                if (/^M/.test(e)) {
                    acc['male'] = currentYear[e]
                }
                if (/^T/.test(e)) {
                    acc['both'] = currentYear[e]
                }
                return acc
            }, {name: year}
        )
        const previousYearData = previousYear ? Object.keys(previousYear).reduce(
            (acc: any, e: string) => {
                if (/^F/.test(e)) {
                    acc['prevFemale'] = previousYear[e]
                }
                if (/^M/.test(e)) {
                    acc['prevMale'] = previousYear[e]
                }
                if (/^T/.test(e)) {
                    acc['prevBoth'] = previousYear[e]
                }
                return acc
            }, {}
        ) : {}
        return {...currentYearData, ...previousYearData}
    }


    getChartDataWithPreviousYears = (data: IndicatorDataModel) => {
        const normalData = this.normalizeData(data)

        const yearArray = Object.keys(normalData)

        return yearArray.map(
            (year: string, index: number) => {
                const previousYear = index !== 0 ? normalData[yearArray[index - 1]] : null
                return this.formChartItemWithPrevYear(year, normalData[year], previousYear)
            }
        )
    }

    getChartDataForPie = (data: IndicatorDataModel) => {
        const normalData = this.normalizeData(data)
        return (Object.keys(normalData) as any)
            .reduce(
                (acc: any, year: any) => {
                    acc[0] = [...acc[0], year]
                    Object.keys(normalData[year]).forEach(
                        (item: string) => {
                            if (/^F/.test(item)) {
                                acc[1] = [...acc[1], normalData[year][item]]
                            }
                            if (/^M/.test(item)) {
                                acc[2] = [...acc[2], normalData[year][item]]
                            }
                        }
                    )
                    return acc
                }, [['year'], ['women'], ['men']]
            )
    }

    getChartDataForMap = (data: IndicatorDataModel) => {
        const normalData = this.normalizeData(data)

        const index = data.structure.dimensions.findIndex(e => e.code === 'REGION') - 1

        return Object.fromEntries(Object.entries(normalData).map(e =>
            [
                e[0],
                (Object.keys(e[1] as any) as any)
                    .map((key: string) => ({place: key.split(':')[index], value: (e[1] as any)[key]}))
            ]
        ))
    }

}
