import React from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {ReactSVG} from 'react-svg'

const Wrapper = styled.div`
  margin: 36px 0 96px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 564px;

  @media (max-width: 1023px) {
    margin: 0 0 64px;
  }

  @media (max-width: 767px) {
    margin: 36px 24px 40px;
    width: calc(100% - 48px);
  }
`

const Block = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292C45;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`

const LinkA = styled.a`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  text-decoration-line: none;
  color: #14537E;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  &:hover {
    text-decoration-line: underline;
  }
`

const NameBlock = styled.div`
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #427c8a;
  width: 350px;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`

const Icon = styled(ReactSVG)`
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-right: 10px;
`

function InfoContact() {
    const {t} = useTranslation()
    const styleMargin = {marginBottom: '36px'}
    return (
        <Wrapper>
            <NameBlock>{t('contacts.name')}</NameBlock>
            <Block>{t('contacts.address')}</Block>
            <LinkA href={'tel:+375173785200'}><Icon src={'../../SVGs/handset.svg'}/>+375 17 378-52-00</LinkA>
            <Block><Icon src={'../../SVGs/printer.svg'}/>{t('contacts.fax')} +375 17 367-22-04</Block>
            <LinkA href={'mailto:belstat@belstat.gov.by'} style={styleMargin}><Icon src={'../../SVGs/letter.svg'}/>belstat@belstat.gov.by</LinkA>
            <NameBlock>{t('contacts.mode')}</NameBlock>
            <Block>{t('contacts.mondayFriday')}</Block>
            <Block>{t('contacts.lunchBreak')}</Block>
            <Block style={styleMargin}>{t('contacts.dayOff')}</Block>
            <NameBlock>{t('contacts.technicalSupport')}</NameBlock>
            <LinkA href={'tel:+375173798719'}><Icon src={'../../SVGs/handset.svg'}/>+375 17 379-87-19</LinkA>
            <LinkA href={'mailto:gender@belstat.gov.by'} style={styleMargin}><Icon src={'../../SVGs/letter.svg'}/>gender@belstat.gov.by</LinkA>
            <NameBlock>{t('contacts.informationSupport')}</NameBlock>
            <LinkA href={'tel:+375173793269'}><Icon src={'../../SVGs/handset.svg'}/>+375 17 379-32-69</LinkA>
            <LinkA href={'tel:88011004949'}><Icon src={'../../SVGs/landlinePhone.svg'}/>{t('contacts.phone')}</LinkA>
            <LinkA href={'mailto:gender@belstat.gov.by'}><Icon src={'../../SVGs/letter.svg'}/>gender@belstat.gov.by</LinkA>
        </Wrapper>
    )
}

export default withTranslation()(InfoContact)
