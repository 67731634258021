import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {useTranslation, withTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {StateModel} from '../../models'
import {ReactSVG} from 'react-svg'

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  position: absolute;
  //opacity: 0.5;
  border: 1px solid #427B89;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  max-width: 1160px;
  display: flex;
  overflow: hidden;

  @media(max-width: 1239px){
    max-width: 980px;
  }

  @media (max-width: 1023px) {
    width: calc(100% - 150px);
    bottom: -70px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 50px);
    bottom: -60px;
  }
`

const Icon = styled.div`
  background-image: url("../../SVGs/search.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 21px;
  width: 21px;
`

const IconClose = styled.div`
  background-image: url("../../SVGs/close.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 14px;
  width: 14px;
  margin: 16px 30px;
  cursor: pointer;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Label = styled.label`
  margin: 12px 15px 12px 25px;
`

const Input = styled.input`
  border: none;
  font-family: Sero Pro, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #292C45;
  outline: none;
  width: 100%;
`

type Props = {
    closeSearch: any
}


function SearchLine({closeSearch}: Props) {
    const ref = useRef(null)
    const history = useHistory()
    const isImpaired = useSelector((state: StateModel) => state.isImpaired)

    function focusTextInput() {
        (ref! as any).current.focus()
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (ref.current) {
                history.push(`/search?query=${(ref.current! as any).value}`)
                closeSearch()
            }
        }
    }

    useEffect(() => {
        focusTextInput()
    }, [])
    const {t} = useTranslation()
    return (
        <Wrapper>
            <Label htmlFor='search'>
                {!isImpaired && <Icon/>}
                {isImpaired &&
                <ReactSVG src={'../../SVGs/search.svg'}/>}</Label>
            <Input ref={ref} onKeyDown={handleKeyDown} placeholder={t('header.Search')} id='search'/>
            {!isImpaired && <IconClose onClick={closeSearch}/>}
            {isImpaired &&
            <ReactSVG src={'../../SVGs/close.svg'}
                      style={{margin: '14px', cursor: 'pointer', width: '21px', height: '21px'}}
                      onClick={closeSearch}/>}
        </Wrapper>
    )
}

export default withTranslation()(SearchLine)
