const be = {
    translation: {
        header: {
            Main: 'Галоўная',
            app_name: 'Гендарная статыстыка',
            Stat: 'Гендарная статыстыка',
            belstat: 'Нацыянальны статыстычны камітэт Рэспублікі Беларусь',
            Demography: 'Дэмаграфiя',
            Health: 'Ахова здароўя',
            Education: 'Адукацыя',
            Economics: 'Занятасць i эканомiка',
            Welfare: 'Дабрабыт',
            Social: 'Грамадскае жыцце і прыняцце рашэнняў',
            Communication: 'Сродкi камунiкацыi',
            Violence: 'Правапарушэнні і гвалт',
            News: 'Навіны',
            About: 'Аб партале',
            Contacts: 'Кантакты',
            impaired: 'Версiя для людзей са слабым зрокам',
            UsefulLinks: 'Карысныя спасылкі',
            informationResources: 'Інфармацыйныя рэсурсы Рэспублікі Беларусь',
            internationalOrganizations: 'Мiжнародныя арганiзацыi',
            genderStatistics: 'Гендарная статыстыка іншых краін',
            statisticalPublications: 'Статыстычныя публікацыі',
            Search: 'Пошук'
        },
        greeting: {
            title: 'Гендарная статыстыка',
            text: 'Асноўным інструментам для маніторынгу прагрэсу ў дасягненні гендарнай роўнасці з`яўляецца гендарная статыстыка.',
            details: 'Падрабязней →',
        },
        navigationBar: {
            demography: 'Дэмаграфiя',
            health: 'Ахова здароўя',
            education: 'Адукацыя',
            economics: {
                firstLine: 'Занятасць',
                secondLine: 'i эканомiка',
            },
            welfare: 'Дабрабыт',
            social: {
                firstLine: 'Грамадскае жыцце',
                secondLine: 'і прыняцце рашэнняў',
            },
            communication: {
                firstLine: 'Сродкi',
                secondLine: 'камунiкацыi',
            },
            violence: {
                firstLine: 'Правапарушэнні',
                secondLine: 'і гвалт',
            },
        },
        news: {
            news: 'Навіны',
            all: 'Усе →',
            allNews: 'Усе навіны →',
            backToAllNews: 'Усе навіны',
            source: 'Крыніца',
            submitComment: 'Адправіць каментар',
            successfullySubmit: 'Каментар паспяхова адпраўлены',
            submit: 'Адправіць',
            errorInput: 'Абавязкова да запаўнення',
            error: 'Памылка',
            inputPlaceholder: 'Як Вас клічуць',
            textareaPlaceholder: 'Ваш каментарый *'
        },
        poll: {
            poll: 'Апытанне',
            reply: 'Адказаць',
            error: 'Выберыце варыянт адказу',
            errorInput: 'Запоўніце поле',
            result: 'Дзякуй за ўдзел у апытанні',
            customAnswer: 'Свой варыянт адказу',
            errorSave: 'Памылка',
        },
        partners: {
            partners: 'Партнёры праекта',
            bla_bla_bla: 'Вэб-партал «Гендарная статыстыка» распрацаваны Белстатам пры падтрымцы Структуры Арганізацыі Аб`яднаных Нацый па пытаннях гендарнай роўнасці і пашырэння правоў і магчымасцяў жанчын (ААН-жанчыны) і Дзіцячага Фонду ААН (ЮНІСЕФ)'
        },
        footer: {
            belstat: '© 2020 Нацыянальны статыстычны камітэт Рэспублікі Беларусь',
            address: '220070, Мінск, Партызанскі праспект,12',
            socialInfo: 'Раскажыце пра вэб-партал «Гендарная статыстыка»',
            report: 'Паведаміць пра памылку'
        },
        demography: {
            title: 'Дэмаграфiя',
            addInfo: 'Дынаміка народанасельніцтва, яго колькасныя і якасныя характарыстыкі з´яўляюцца важнай інфармацыяй, з улікам  якой  ажыццяўляецца аналіз становішча ў эканоміцы, на рынку працы, у адукацыі, ахове здароўя і ў іншых сферах жыцця грамадства.',
        },
        health: {
            title: 'Ахова здароўя',
            addInfo: 'Роўнасць і справядлівасць у адносінах здароўя мужчын і жанчын на працягу ўсяго жыцця дасягаецца за кошт забеспячэння іх роўнага доступу да паслуг аховы здароўя.',
        },
        education: {
            title: 'Адукацыя',
            addInfo: 'Адукацыі належыць выключная роля ў станаўленні асобы, сацыялізацыі жанчын і мужчын.',

        },
        economics: {
            title: 'Занятасць i эканомiка',
            addInfo: 'Адной з галоўных умоў эканамічнай бяспекі і дабрабыту жанчын і мужчын з´яўляецца забеспячэнне роўных магчымасцяў у рэалізацыі іх права на працу.',

        },
        welfare: {
            title: 'Дабрабыт',
            addInfo: 'Узровень дабрабыту – важная характарыстыка эканамічных і сацыяльных магчымасцяў чалавека.',
        },

        social: {
            title: 'Грамадскае жыцце і прыняцце рашэнняў',
            addInfo: 'Баланс правоў і магчымасцяў жанчын і мужчын на ўсіх узроўнях палітычнага, эканамічнага і сацыяльнага жыцця з´яўляецца адным з індыкатараў развіцця грамадства.',

        },
        communication: {
            title: 'Сродкi камунiкацыi',
            addInfo: 'Інфармацыйна-камунікацыйныя тэхналогіі неабходны жанчынам і мужчынам для эфектыўнага функцыянавання  ў сучасным лічбавым і віртуальным свеце.',
        },
        violence: {
            title: 'Правапарушэнні і гвалт',
            addInfo: 'Правы чалавека з´яўляюцца неад´емным набыткам усіх людзей, незалежна ад полу, сацыяльнага паходжання ці іншых прыкмет.',

        },
        category: 'Катэгорыя',
        shareIndicator: 'Падзяліцца паказчыкам',
        share: 'Падзяліцца',
        shareNews: 'Падзяліцца навінай',
        downloadDataBlock: {
            downloadData: 'Спампаваць даныя',
            completeDataset: 'Поўны набор даных',
            APIsetupInstructions: 'Інструкцыя па наладцы API',
            chartTablePDF: 'Дыяграма / табліца / пашпарт у PDF',
            tableXLSX: 'Табліца ў XLS',
            print: 'Друк'
        },
        dataViewMenu: {
            lineChart: 'Лінейны графік',
            columnChart: 'Графік слупкаваты',
            plotWithAreas: 'Графік з абласцямі',
            pieChart1: 'Кругавы графік (1)',
            pieChart2: 'Кругавы графік (2)',
            map: 'Карта',
            table: 'Табліца',
            passport: 'Пашпарт',
            diagrams: 'Дыяграмы',
        },
        disaggregationIndicators: {
            title: 'Дэзагрэгацыя паказчыкаў',
            bothGenders: 'Абодва пола',
            femaleGender: 'Жаночы пол',
            maleGender: 'Мужчынскі пол',
            showAllDisaggregation: 'Паказаць усе дэзагрэгацыі',
            collapse: 'Згарнуць',
            apply: 'Ужыць'
        },
        about: {
            title: 'Аб партале',
            firstBlock: 'Рэспубліка Беларусь ратыфікавала і падпісала шэраг міжнародных абавязкаў па забеспячэнні гендарнай роўнасці і пераадоленні дыскрымінацыі па полу. Гэта пацвярджае прыхільнасць нашай краіны мэтам дасягнення роўнасці жанчын і мужчын ва ўсіх сферах жыцця.',
            secondBlock: 'Асноўным інструментам для маніторынгу прагрэсу ў забеспячэнні гендарнай роўнасці з´яўляецца гендарная статыстыка.',
            genderEquality: 'Гендарная роўнасць',
            firstBlockGenderEquality: 'Гэта не толькі адно з асноўных правоў чалавека, але і неабходная падстава для дасягнення міру, росквіту і ўстойлівага развіцця.',
            secondBlockGenderEquality: 'Гэта роўнасць правоў, абавязкаў і магчымасцяў для жанчын і мужчын, дзяўчынак і хлопчыкаў.',
            GenderStatistics: 'Гендарная статыстыка',
            firstBlockGenderStatistics: 'Гэта статыстыка пра становішча  жанчын і мужчын у розных сферах жыцця: у атрыманні адукацыі і паслуг аховы здароўя, ва ўдзеле ў аплачваемай і неаплачваемай працы, ва ўпраўленні і прыняцці рашэнняў, у размеркаванні сямейнах абавязкаў і выхаванні дзяцей, іншых сферах жыццядзейнасці грамадства.',
            secondBlockGenderStatistics: 'Гендарная статыстыка дапамагае выявіць фактычную сітуацыю ў адносінах удзелу жанчын і мужчын у сацыяльных і палітычных працэсах і неабходна для эфектыўнага маніторынгу, паспяховага планавання і ацэнкі ўздзеяння праграм развіцця, якія накіраваны на ліквідацыю гендарных дыспрапорцый і стэрэатыпаў.',
            WebPortal: 'Вэб-партал «Гендарная статыстыка»',
            firstBlockWebPortal: 'Гэта ўніверсальны  інструмент папулярызацыі і распаўсюджвання інфармацыі аб прагрэсе ў дасягненні гендарнай роўнасці ў Рэспубліцы Беларусь. На партале змешчана актуальная афіцыйная статыстычная інфармацая і адміністрацыйныя даныя гендарнай тэматыкі. Ён з´яўляецца адкрытым інфармацыйным рэсурсам для ўсіх катэгорый карыстальнікаў, якія займаюцца пытаннямі гендарной роўнасці.',
            secondBlockWebPortal: 'Партал мае тры моўныя версіі – рускую, беларускую і англійскую. Паказчыкі гендарнай статыстыкі згрупаваны па васьмі тэматычных раздзелах і прадстаўлены ў дынаміцы з 2000 года. У «пашпарце» паказчыка змешчаны асноўныя метадалагічныя тлумачэнні. Карыстальнікі маюць магчымасць выбраць спосаб прадстаўлення інфармацыі ў выглядзе табліц, графікаў і картаграм.',
        },
        report: {
            report: 'Паведаміць пра памылку',
            problem: 'Апісанне памылкі *',
            submit: 'Адправіць'
        },
        notFound: {
            notFound: 'Старонка не знойдзена',
            description: 'На жаль, гэтая старонка недаступная. Магчыма, Вы скарысталіся несапраўднай спасылкай або старонка была выдаленая',
            link: 'На галоўную →'
        },
        contacts: {
            contacts: 'Кантакты',
            name: 'Нацыянальны статыстычны камітэт Рэспублікі Беларусь',
            address: '220070, Мінск, Партызанскі праспект,12',
            fax: 'Факс',
            mode: 'Рэжым работы',
            mondayFriday: 'панядзелак – пятнiца з 9.00 да 18.00',
            lunchBreak: 'перапынак на абед з 13.00 да 14.00',
            dayOff: 'выхадныя: субота, нядзеля',
            technicalSupport: 'Тэхнічная падтрымка',
            informationSupport: 'Інфармацыйная падтрымка',
            phone: '8 801 100-49-49 (званкi бясплатныя па Рэспублiцы Беларусь са стацыянарных тэлефонаў)',
            writeToUs: 'Напісаць нам',
            addedNotes: 'З дапамогай дадзенай формы Вы можаце звязацца з супрацоўнікамі Нацыянальнага статыстычнага камітэта Рэспублікі Беларусь',
            askQuestion: 'Задаць пытанне',
            suggestImprovement: 'Прапанаваць паляпшэнне',
            askYourQuestion: 'Задайце Ваша пытанне',
            submit: 'Адправіць',
            yourName: 'Прозвішча, імя, імя па бацьку *',
            proposal: 'Раскажыце аб вашым сказе па паляпшэнню партала',
            success: 'Ваша паведамленне паспяхова дасланы',
            fail:'Не атрымалася адправіць паведамленне',
            errorEmail:'E-mail некарэктны',
            namePerson: 'Прозвішча, імя, імя па бацьку *',
            errorInput:'Запоўніце поле',
        },
        search: {
            search: 'Пошук',
            found: 'Знойдзена вынікаў: ',
            notFound: 'Nothing was found for your search'
        },
        charts: {
            showValue: 'Паказваць значэнні',
            male: 'Мужчыны',
            female: 'Жанчыны',
            bothGender: 'Абодва пола'
        },
        passport: {
            indicatorName: 'Назва паказчыка',
            dataSource: 'Крыніцы даных',
            calcUnits: 'Адзінкі вымярэння',
            calcMethod: 'Метадалогія разліку',
            dateOpen: 'Глыбіня дынамічнага рада',
            dateClose: 'Дата адмены',
            providingTerm: 'Перыядычнасць',
            disaggregationLevels: 'Узроўні дэзагрэгацыі',
            publicationDate: 'Дата апошняга абнаўлення',
            contact: 'Кантактная інфармацыя',
            comments: 'Каментарыі'
        },
        indicators: {
            year: 'Год',
            error: 'Памылка',
            dataNoLoaded: 'Набор не загружаны',
            dataRecalled: 'Набор адкліканы'
        },
        links: {
            statistics: 'Гендарная статыстыка іншых краін',
            Georgia: 'Грузія, Нацыянальная статыстычная служба',
            Moldova: 'Рэспубліка Малдова, Нацыянальнае бюро статыстыкі',
            Sweden: 'Швецыя, Статыстычнае ўпраўленне',
            informationResources: 'Інфармацыйныя рэсурсы Рэспублікі Беларусь',
            GenderPolicy: 'Нацыянальны савет па гендэрнай палітыцы пры Савеце Міністраў Рэспублікі Беларусь',
            GenderEquality: 'Нацыянальны план дзеянняў па забеспячэнні гендэрнай роўнасці',
            for: 'на',
            years: 'гады',
            MinistryOfLabor: 'Міністэрства працы і сацыяльнай абароны Рэспублікі Беларусь',
            IndicatorReportingPlatform: 'Нацыянальная платформа прадстаўлення справаздачнасці па паказчыках Мэтаў ўстойлівага развіцця',
            statisticsOnChildren: 'Універсальны Вэб-партал статыстычных дадзеных аб дзецях',
            InformationAnalyticalSystem: 'Інфармацыйна-аналітычная сістэма распаўсюджвання афіцыйнай статыстычнай інфармацыі',
            InternationalOrganizations: 'Мiжнародныя арганiзацыi',
            UNWomen: 'Структура Арганізацыі Аб\'яднаных Нацый па пытаннях гендэрнай роўнасці і пашырэння правоў і магчымасцяў жанчын ( «ААН-жанчыны»)',
            UNICEF: 'Дзіцячы фонд Арганізацыі Аб\'яднаных Нацый (ЮНІСЕФ)',
            UNECE: 'Статыстычная база дадзеных ЕЭК ААН',
            WorldGenderStatistics:'Статыстычны партал па пытаннях гендэрнай роўнасці Сусветнага банка',
            ILOSTAT: 'База дадзеных Міжнароднай арганізацыі працы «ILOSTAT»',
            StatisticalPublications: 'Статыстычныя публікацыі',
            WomenAndMen: 'Жанчыны і мужчыны Рэспублікі Беларусь (зборнік)',
            year: 'год',
            ClusterSurvey: 'Шмат індыкатарнае кластарнае абследаванне для ацэнкі становішча дзяцей і жанчын',
            WomenEntrepreneurs: 'Жанчыны-прадпрымальнікі Беларусі, 2018 (брашура)',
            HouseholdDecision: 'Прыняцце рашэнняў у хатняй гаспадарцы, 2019 (прэзентацыя)',
            healthyLifestyle: 'Стаўленне насельніцтва да здаровага ладу жыцця, 2020 (прэзентацыя)'
        }
    },
}

export default be
