import React, {useContext} from 'react'

import {ApiContext} from '../../contexts'
import {ApiService} from '../../services'

interface ComponentWithApi{
  apiService: ApiService
}

export function withApiService<T extends ComponentWithApi>(WrappedComponent: React.ComponentType<T>) {
  return function (props: any) {
    const apiService = useContext(ApiContext)
    return <WrappedComponent apiService={apiService} {...props} />
  }
}
